import {BlueButton} from '@/components/Button/BlueButton';
import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import {useCapture} from '@/posthog/PosthogSetup';
import {WarningIcon} from '@chakra-ui/icons';
import {Box, HStack, Text, VStack} from '@chakra-ui/layout';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {useTranslation} from 'react-i18next';

interface QuantitesAnormalesModalProps {
  alertes: {code_article: string; libelle: string; niveau: 'n_1' | 'n_2'}[];
  onValider: () => void;
}

export const QuantitesAnormalesModal = NiceModal.create<QuantitesAnormalesModalProps>(({
  alertes,
  onValider,
}) => {
  const {t} = useTranslation('common');
  const capture = useCapture();

  const modal = useModal();

  const handleClose = () => {
    capture({
      name: 'panier:quantites_anormales:modifier',
      props: {
        depuis: 'modale_quantites_anormales'
      }
    });
    modal.remove();
  };

  const handleValider = () => {
    onValider();
    modal.remove();
  };

  return (
    <ModaleLayout
      visible={modal.visible}
      hide={handleClose}
      headerIcon={<WarningIcon color='blue.main'/>}
      titre={t('panier.quantites_inhabituelles.titre')}
    >
      <VStack alignItems='stretch'
        borderTop="1px"
        borderColor="gray.light"
        marginTop='0px'>
        <Text marginTop='10px'>{t('panier.quantites_inhabituelles.message', {count: alertes.length})}</Text>
        <Box fontWeight='700'
          marginLeft='10px'>
          {alertes.map(alerte => (
            <li key={alerte.code_article}>{alerte.libelle}</li>
          ))}
        </Box>
      </VStack>
      <VStack alignItems='flex-end'
        mt='30px'>
        <Text letterSpacing='tight'
          w='100%'
          textAlign='left'>{t('panier.quantites_inhabituelles.message_suite')}</Text>
        <HStack
          justify='space-between'>
          <BlueButton
            mt={0}
            onClick={handleClose}
            texte={t('panier.quantites_inhabituelles.modifier')}
          />
          <BlueButton
            mt={0}
            onClick={handleValider}
            texte={t('panier.quantites_inhabituelles.valider')}
          />
        </HStack>
      </VStack>
    </ModaleLayout>
  );
});
