import {Box, HStack, StyleProps, Text, VStack} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import {DisplayLabels} from './DisplayLabels';
import {ListeDrapeaux} from '../components/listeDrapeaux';
import {ReactNode} from 'react';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {VignetteArticleVStack} from '@/domains/mediatheque/VignetteArticleVStack';
import {EncartDLC} from '@/domains/ligneProduit/EncartDLC';
import {Navigable} from '@synako/halitrek';
import {HaliRenderOne, useHalState} from '@synako/haligator';
import {DisponibiliteArticleDesc} from 'api-types/ffconnect-hal';
import ColdIcon from '@/components/svg/ColdIcon';
import {useTranslation} from 'react-i18next';
import {NatureDuPrix} from 'api-types/ffconnect';
import {useClient} from '@/haligator-factory';

export const ArticleBlock: React.FC<{
  code_article:string,
  dispo?:Navigable<DisponibiliteArticleDesc>,
  precommande?: ReactNode,
  libelleFallback?: string,
  typePrix?: NatureDuPrix;
} & StyleProps> = ({
  code_article,
  dispo: navDispo,
  precommande,
  libelleFallback,
  typePrix,
  ...props
}) => {
  const clientNav = useClient();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {buildAssistanceRoute} = useIsAssistanceMode();
  const routeToArticle = buildAssistanceRoute(routes.client.produit, {code_article});
  const itemNav = useClient().follow('go:item_catalogue', {code_article});
  const item = useHalState(itemNav);
  const articleNav = useClient().follow('go:article', {code_article});
  const article = useHalState(articleNav);
  const canClick = !!item.data;

  return (
    <HStack alignItems='start'
      cursor={canClick ? 'pointer' : 'not-allowed'}
      onClick={()=>{if (canClick) navigate(routeToArticle);}}
      mr='2em'
      {...props}
      position='relative'>
      {precommande}
      <Box position="absolute"
        top="55px"
        left="0px">{article.data?.temperature_livraison === 'negatif' && <ColdIcon boxSize='25px' />}</Box>
      <VignetteArticleVStack article={article.navigable} />
      <VStack alignItems='start'>
        <HStack alignContent='baseline'>
          <Text fontSize='xs'
            fontWeight='400'>{code_article}</Text>
          <DisplayLabels SIQO_et_regimes_alimentaires={article.data?.tolede.SIQO_et_regimes_alimentaires}/>
          <ListeDrapeaux listepays={article.data?.tolede.pays_d_origine ?? []}/>
          {typePrix === 'marche' &&
          <HaliRenderOne nav={clientNav}
            render={({data: clientData}) => {
              if (clientData.affichage_produit_marche === 'active') {
                return (
                  <HStack
                    color="blue.main"
                    fontSize="xs"
                    fontWeight="bold"
                    py="2px"
                    px="8px"
                  >
                    <Text>{t('global.produit_marche')}</Text>
                  </HStack>
                );
              }
              return null;
            }}/>
          }
        </HStack>
        {/* Desktop */}
        <Text display={{base: 'none', md: 'block'}}
          fontWeight='600'
          lineHeight='16px'
          maxW='400px'>
          {article.data?.tolede.libelle_commercial ?? libelleFallback}
        </Text>
        <Text display={{base: 'none', md: 'block'}}
          lineHeight='16px'
          maxH='32px'
          maxW='400px'>
          {article.data?.tolede.marque?.[0]}
        </Text>
        {/* Mobile */}
        <Text display={{base: 'block', md: 'none'}}
          fontWeight='600'
          lineHeight='16px'>
          {article.data?.tolede.libelle_commercial ?? libelleFallback}&nbsp;{article.data?.tolede.marque?.[0]}
        </Text>
        <EncartDLC dispo={navDispo}/>
      </VStack>
    </HStack>
  );  
};
