import {useCallback} from 'react';
import {DateRangeCell} from './components/DateRangeCell';
import {BaseCalendarGrid} from '../common/BaseCalendarGrid';
import {
  generateDateGrid,
  isDateInCurrentMonth,
  isDateInRange,
  isDateSelected
} from '../common/calendarUtils';

export interface DateRangeGridProps {
  onDateClick: (date: Date) => void;
  monthOffset: number;
  startDate: Date | null;
  endDate: Date | null;
  referenceDate: Date;
}

export const DateRangeGrid: React.FC<DateRangeGridProps> = ({
  onDateClick, 
  monthOffset, 
  startDate,
  endDate,
  referenceDate
}) => {
  // Utilise la fonction utilitaire pour générer la grille de dates
  const getDateGrid = useCallback(() => {
    return generateDateGrid(referenceDate, monthOffset, true);
  }, [referenceDate, monthOffset]);
  
  return (
    <BaseCalendarGrid>
      {getDateGrid().map((date, idx) => (
        <DateRangeCell
          key={idx}
          date={date}
          isSelected={isDateSelected(date, startDate, endDate)}
          isInRange={isDateInRange(date, startDate, endDate)}
          isCurrentMonth={isDateInCurrentMonth(date, referenceDate, monthOffset)}
          onClick={() => {
            onDateClick(date);
          }}
        />
      ))}
    </BaseCalendarGrid>
  );
};
