import {TrashIcon} from '@/components/svg/TrashIcon';
import {Button, Tooltip} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {LigneDePanierCommands} from 'ff-common/src/hooks/ligneDePanier/use-ligne-de-panier-commands';
import {useCapture} from '@/posthog/PosthogSetup';

export const SupprimerErrorButton: React.FC<{
  // probleme: LigneProblem,
  // ligneDePanier: State<LigneDePanierDesc>,
  commands: LigneDePanierCommands,
  onClose?: () => void
  submittingOrRefreshing?: boolean,
  code_article: string
}> = ({
  commands,
  onClose,
  submittingOrRefreshing,
  code_article
}) => {
  const {t} = useTranslation();
  const capture = useCapture();
  const {somethingSubmitting, supprimer} = commands;

  const handleClick = async () => {
    capture({
      name: 'panier:enlever',
      props: {
        code: code_article,
        depuis: 'apres_validation'
      }
    });
    supprimer.submit({});
    onClose?.();
  };
  return (
    <Tooltip 
      label={t('ligne_produit.indisponible.tooltip_rupture_totale')}
    >
      <Button 
        colorScheme='blue' 
        isDisabled={somethingSubmitting || submittingOrRefreshing}
        onClick={handleClick}
      >
        <TrashIcon />
      </Button>
    </Tooltip>

  );
};
