import {State} from '@synako/halitrek';
import {ArticleDesc, SuggestionsStateDesc} from 'api-types/ffconnect-hal';
import {TemperatureLivraison1} from 'api-types/ffconnect';

// frequency : met en avant les articles avec suggestions de quantité
// alphabetic : tri par libellé commercial
export type SortType = 'alphabetic' | 'frequency';

export interface CadencierArticle {
  code: string;
  temperature_livraison?: TemperatureLivraison1;
  libelle_commercial?: string;
  articleState?: State<ArticleDesc>;
}

export const TriArticlesCadencier = (
  articles: CadencierArticle[],
  sortType: SortType,
  suggestionsState?: State<SuggestionsStateDesc>
): CadencierArticle[] => {
  // Copie pour ne pas modifier le tableau original
  const articlesCopy = [...articles];

  // Fonction de comparaison pour le tri alphabétique
  const compareLibelles = (a: CadencierArticle, b: CadencierArticle): number => {
    // Récupération des libellés
    const libelleA = a.articleState?.data.tolede.libelle_commercial ?? '';
    const libelleB = b.articleState?.data.tolede.libelle_commercial ?? '';
    // Comparaison alphabétique
    return libelleA.localeCompare(libelleB);
  };

  // Fonction de comparaison pour le tri par fréquence (suggestions de quantité)
  const compareSuggestions = (a: CadencierArticle, b: CadencierArticle): number => {
    const suggestions = suggestionsState?.data.suggestions;

    // Si pas de suggestions, on ne fait pas de tri
    if (!suggestions || !Array.isArray(suggestions)) {
      return 0;
    }

    // Vérification si les articles ont des suggestions
    const hasSuggestionA = suggestions.some(s => s.code_article === a.code);
    const hasSuggestionB = suggestions.some(s => s.code_article === b.code);

    // Si un article a une suggestion et l'autre non, on le met en premier
    if (hasSuggestionA !== hasSuggestionB) {
      return hasSuggestionB ? 1 : -1;
    }
    // Si les deux articles ont ou n'ont pas de suggestions,
    // on applique le tri alphabétique
    return compareLibelles(a, b);
  };

  // Application du tri selon le type demandé
  return articlesCopy.sort((a, b) => {
    switch (sortType) {
      case 'frequency':
        return compareSuggestions(a, b);
      case 'alphabetic':
        return compareLibelles(a, b);
      default:
        return 0;
    }
  });
};
