import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useClient} from '@/haligator-factory';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {Input, Link, Text} from '@chakra-ui/react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {useCommand} from '@synako/haligator';
import {useCallback, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {useNavigate} from 'react-router-dom';
import {routes} from 'ff-common';
import {BlueButton} from '@/components/Button/BlueButton';
import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import {useCapture} from '@/posthog/PosthogSetup';

export const ModalCreateListe = NiceModal.create(() => {
  const modal = useModal();
  const [newListName, setNewListName] = useState('');
  const textToast = useSimpleTextToast();
  const {t} = useTranslation();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const navigate = useNavigate();
  const capture = useCapture();

  const onError = useDefaultOnError(textToast);
  const {submit:submitAjouterUneListe} = useCommand(useClient(), 'c:ajouter-une-liste', {onError, onSuccess: (event) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const codeListe = (event.data as any).code_liste;
    textToast([
      <Trans key="toast-text"
        t={t}>
        {t('toast.ajout_nouvelle_liste', {newListName})}
      </Trans>,
      <Link 
        key="toast-button"
        fontWeight='bold'
        textDecorationLine='underline'
        onClick={()=>{navigate(buildAssistanceRoute(routes.client.listes, {code_liste:codeListe}));}}>
        {t('toast.voir_liste')}
      </Link>
    ]);
  }});

  const handleCreateList = () => {
    createListAction();
    setNewListName('');
    modal.remove();
  };

  const createListAction = useCallback(() => {
    capture({
      name: 'liste:creer',
      props: {
        liste: newListName
      }
    });
    submitAjouterUneListe({
      nom_liste: newListName,
      type_liste: 'favoris',
    });
  }, [capture, newListName, submitAjouterUneListe]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewListName(e.target.value);
  };

  return(
    <ModaleLayout
      visible={modal.visible}
      hide={modal.remove}
      titre={t('liste.creer_liste')}
      footer={<BlueButton texte={t('global.creer')}
        onClick={handleCreateList}/>}
    >
      <Text mb={2}>{t('liste.veuillez_entrer_nom_liste')}</Text>
      <form onSubmit={e=>{
        e.preventDefault();
        handleCreateList();
      }}>
        <Input 
          placeholder={t('liste.nom_liste_placeholder')}
          value={newListName}
          onChange={handleInputChange}
          onSubmit={e=>{
            e.preventDefault();
            handleCreateList();
          }}
        />
      </form>
    </ModaleLayout>
  );
});
