import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {CalendarModale} from './CalendarModale';
import {ChangerDateDeLivraisonCommand} from 'api-types/ffconnect';
import {useLivraisonsPossiblesContext, useSelectedLivraison} from 'ff-common';
import {Calendar} from './Calendar/Calendar';
import {useAfficherPanier} from '@/hooks/livraison/use-afficher-panier';
import {Box} from '@chakra-ui/react';
import {useCapture} from '@/posthog/PosthogSetup';

export const ChangerDateDeLivraisonModale = NiceModal.create(({
  submit
}: {
  submit: (payload: ChangerDateDeLivraisonCommand) => Promise<unknown>,
}) => {
  const modal = useModal();
  const afficherPanier = useAfficherPanier();
  const capture = useCapture();
  const {livraisonSelectionnee, setLivraisonSelectionnee} = useLivraisonsPossiblesContext();

  const {
    selectedLivraison, 
    onDateSelect, 
    onCancel,
  } = useSelectedLivraison(
    livraisonSelectionnee,
    setLivraisonSelectionnee,
    modal
  );

  const onValidate = async () => {
    if (selectedLivraison.resource.uri !== livraisonSelectionnee.uri) {
      submit({date_de_livraison: selectedLivraison.data.tolede.date_de_livraison});
    }
    capture({name:'panier:go', props:{depuis:'modale'}});
    afficherPanier(selectedLivraison.data.tolede.date_de_livraison);
  };

  return (
    <CalendarModale modal={modal}
      messageKey={'changement_panier'}
      onCancel={onCancel}>
      <Box borderRadius="8px"
        border='1px solid'
        borderColor='gray.light'
        height='100%'>
        <Calendar
          onCancel={onCancel}
          onDateSelect={onDateSelect}
          onValidate={onValidate}
          selectedLivraison={selectedLivraison}
        />
      </Box>
    </CalendarModale>
  );
});
