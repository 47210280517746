import {HStack,Image, VStack} from '@chakra-ui/react';
import {CartButton} from './CartButton/CartButton';
import {MenuCatalog} from './MenuCatalog/MenuCatalog';
import {Searchbar} from './Searchbar/Searchbar';
import {routes, useLivraisonsPossiblesContext} from 'ff-common';
import {useNavigate, useParams} from 'react-router-dom';
import {HeaderBand} from '../../domains/HeaderBand/HeaderBand';
import {CheckLoaded} from '@/containers/CheckLoaded';
import {useHalState} from '@synako/haligator';
import {useSocieteCommerciale} from '@/haligator-factory';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {NavButton} from './NavButton';
import {CadencierIcon} from '../svg/CadencierIcon';
import {useTranslation} from 'react-i18next';
import {ListIcon} from '../svg/ListIcon';
import {imageOptimisee} from '@/domains/mediatheque/optimisation';
import {PromotionIcon} from '../svg/PromotionIcon';
import {StarIcon} from '@chakra-ui/icons';
import {usePromoMpSearch} from 'ff-common';
import {useCapture} from '@/posthog/PosthogSetup';

export const Navbar:React.FC = () => {
  const hide_mobile = {display:{base:'none', md:'flex'}};

  const navigate = useNavigate();

  const societeCommerciale = useHalState(useSocieteCommerciale());

  // vérification si en mode assistance pour le positionnement sticky de la barre de navigation
  const {id_client_assistance} = useParams();
  const isAssistanceMode = !!id_client_assistance;
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const {t} = useTranslation();

  const tourneeHook = useHalState(useLivraisonsPossiblesContext().livraisonSelectionnee.follow('tournee'));
  const temperature_de_livraison = tourneeHook.data?.tolede.temperature_de_livraison; 
  const {itemResultsPromo, itemResultsMP, promoDispo} = usePromoMpSearch({temperature_de_livraison});

  const promoLien = buildAssistanceRoute(routes.client.catalogue, {}, {nature_du_prix: 'promo', from: 'promo'});
  const MPLien = buildAssistanceRoute(routes.client.catalogue, {}, {est_MP: true, from: 'mp'});

  const buttonText = promoDispo ? t('catalogue.promotions') : t('catalogue.indispensables');
  const Lien = promoDispo ? promoLien : MPLien;

  const capture = useCapture();

  return (
    <VStack gap='0'
      top={isAssistanceMode ? '50px' : '0'}
      position="sticky"
      zIndex='sticky'>
      <HeaderBand />
      <HStack w='100%'
        h={{base:'16', md:'20'}}
        alignItems='center'
        p={{base:'0',md:'3'}}
        px={{base:'0',md:'2',xl:'20'}}
        gap="4"
        bg='white'
        borderBottom={{base:'0', md:'1px solid'}}
        borderColor={{md:'gray.light'}} >
        <HStack alignItems='stretch'
          maxHeight='100%'
          cursor='pointer'
          _hover={{opacity:'30%'}}
          onClick={()=>{navigate(buildAssistanceRoute(routes.client.accueil));}}
          {...hide_mobile}>
          <CheckLoaded hook={societeCommerciale}
            render={({data})=>{
              return <Image src={imageOptimisee(data.url_logo, 96)}
                alt={data.tolede.nom_commercial}
                htmlWidth='464'
                htmlHeight='294'
                maxW='6em'
                objectFit='contain'
                margin='-1'/>;
            }}/>
        </HStack>
        <MenuCatalog props={hide_mobile}/>
        <Searchbar props={{display:{base:'none', md:'flex'}}}/>
        <HStack display={{base:'none', md:'flex'}}
          alignItems='stretch'
          h='100%'
          spacing={0.5}>
          {(itemResultsPromo?.hits ?? itemResultsMP) && (
            <>
              <NavButton ButtonIcon={promoDispo ? PromotionIcon : StarIcon}
                label={buttonText}
                to={Lien}
                color={promoDispo ? 'red.500' : 'blue.main'} />
            </>
          )}
          <NavButton ButtonIcon={ListIcon}
            label={t('global.mes_listes')}
            to={buildAssistanceRoute(routes.client.listes_default)}/>
          <NavButton ButtonIcon={CadencierIcon}
            label={t('global.mon_cadencier')}
            onClick={()=>{
              capture({name: 'cadencier:go', props: {depuis: 'menu'}});
            }}
            to={buildAssistanceRoute(routes.client.cadencier)}/>
        </HStack>
        <CartButton />
      </HStack>
    </VStack>
  );
};
