import {useState} from 'react';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc, LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {useTranslation} from 'react-i18next';
import {SelectUnite} from '@/domains/panier/BuyProduct/SelectUnite';
import {ProduitBuyButton} from '@/domains/ligneProduit/ProduitBuyButton';
import {useSelectionParColis} from '../catalogue/useSelectionParColis';
import {StyleProps} from '@chakra-ui/react';
import {TextToastCommand} from 'ff-common/src/types/TextToastCommand';
import {ContextsComposant} from 'ff-common/src/types/ContextsComposant';
import {useCapture} from '@/posthog/PosthogSetup';

export const useBuyingBlocks = (
  textToast:TextToastCommand,
  context: ContextsComposant,
  itemState?: State<ItemCatalogueDesc>,
  lignePanier?: State<LigneDePanierDesc>,
) => {
  const {t} = useTranslation();
  const [tooltip, setTooltip] = useState<string | undefined>();
  const [bKey, setBKey] = useState<string>();
  const capture = useCapture();

  const [quantiteDisplay, setQuantiteDisplay] = useState(lignePanier?.data.quantite);
  const {selectionParColis, setSelectionUnite} = useSelectionParColis(lignePanier, itemState?.data.tolede.code_article, textToast); 

  const uniteQuantiteBlock = (props?:StyleProps)=> itemState && <SelectUnite
    itemCatalogue={itemState}
    value={selectionParColis ? 'colis' : 'unitaire'}
    onSelect={(unite) => {
      // Réinitialisation du tooltip avant chaque changement
      setTooltip(undefined);
      const ancienneQuantite = quantiteDisplay;
      setSelectionUnite(unite).then(event=>{
        if (!event) return;
        const nouvelleQuantite = event.data.data.ligne_de_panier.quantite;
        // Affiche le message si on passe en colis et que la quantité a changé
        if (unite === 'colis' && nouvelleQuantite !== ancienneQuantite) {
          setTooltip(t('toast.unite_switchee', {
            quantite: event.data.data.ligne_de_panier.quantite_en_colis_entier
          }));
        }
        setQuantiteDisplay(event.data.data.ligne_de_panier.quantite);
        setBKey(event.data.id); // Permet de changer la key en même temps que la quantité
      
        capture({
          name: 'panier:changer_unite',
          props: {code: itemState.data.tolede.code_article, unite}
        });
      });
    }} 
    {...props}
  />;
  const buyBlock = (props?:{somethingSubmitting?:boolean, afficher_bouton_liste?:boolean, texte?:string} & StyleProps) => itemState
  && <ProduitBuyButton
    key={bKey}
    lignePanier={lignePanier}
    itemState={itemState}
    commande_en={selectionParColis ? 'colis' : 'unitaire'}
    quantiteDisplay={quantiteDisplay}
    context={context}
    setQuantiteDisplay={setQuantiteDisplay}
    tooltip={tooltip}
    setTooltip={setTooltip}
    {...props} />;

  return {uniteQuantiteBlock, buyBlock};
};
