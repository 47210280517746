import React from 'react';
import {Button, HStack, Text, Tooltip} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {HistoriqueAchatModal} from './HistoriqueAchatModal';
import {useTranslation} from 'react-i18next';
import {TimeIcon} from '@chakra-ui/icons';
import {format, subDays} from 'date-fns';
import {HaliRenderOne} from '@synako/haligator';

export interface HistoriqueAchatButtonProps {
  itemState: State<ItemCatalogueDesc> | undefined;
  showIconOnly?: boolean;
}

export const HistoriqueAchatButton: React.FC<HistoriqueAchatButtonProps> = ({itemState, showIconOnly}) => {
  const {t} = useTranslation();
  // Date de début de l'historique d'achat : 90 jours avant la date actuelle
  const dateDebut = format(subDays(new Date(), 90), 'yyyy-MM-dd');
  // Date de fin de l'historique d'achat : date actuelle
  const dateFin = format(new Date(), 'yyyy-MM-dd');
  const handleClick = () => {
    NiceModal.show(HistoriqueAchatModal, {
      itemState,
      dateDebut,
      dateFin
    });
  };

  if (!itemState) return null;

  return (
    <HaliRenderOne
      nav={itemState.follow('article_statistiques', {debut: dateDebut, fin: dateFin})}
      render={({data: statsData}) => {
        // Vérifier si l'article a des statistiques avec des livraisons
        const hasHistory = statsData.tolede.stats_par_livraison.length > 0;

        // Si pas d'historique, ne rien afficher
        if (!hasHistory) return null;

        // Version simplifiée avec juste l'icône
        if (showIconOnly) {
          return (
            <Tooltip label={t('historique_livraison.historique_achat_tooltip')}>
              <TimeIcon
                onClick={handleClick}
                backgroundColor='gray.bg'
                p='5px'
                boxSize={6}
                borderRadius='5px'
                fontWeight='300'
                cursor='pointer'
              />
            </Tooltip>
          );
        }

        // Version complète avec icone + texte
        return (
          <Tooltip label={t('historique_livraison.historique_achat_tooltip')}>
            <HStack
              justifyContent='start'
              alignItems='center'
              backgroundColor='white'
              w='100%'
              p='3px'
              pl='0'
              borderRadius='8px'>
              <Button
                size="xs"
                onClick={handleClick}
                leftIcon={<TimeIcon backgroundColor='gray.bg'
                  p='5px'
                  boxSize={6}
                  borderRadius='5px'
                  fontWeight='300'/>}
                variant="ghost"
                textDecoration='underline'
              >
                <Text fontWeight='500'>
                  {t('historique_livraison.historique_achat')}
                </Text>
              </Button>
            </HStack>
          </Tooltip>
        );
      }}
      fallback={() => null}
    />
  );
};
