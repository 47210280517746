import {ArticleDesc, ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import React, {createContext, useContext, useMemo} from 'react';

interface ListItemsContextValue {
  /**
   * Vérifie si un article est déjà dans la liste active
   */
  isItemInList: (code_article: string) => boolean;
  /**
   * Ajoute un article à la liste active
   */
  handleAddToList: ((item_catalogue: State<ItemCatalogueDesc>, setIsAdding: (adding: boolean) => void) => void) | undefined;
}

export const ListItemsContext = createContext<ListItemsContextValue>({
  isItemInList: () => false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleAddToList: () => {},
});

interface ListItemsProviderProps {
  children: React.ReactNode;
  produits_liste?: State<ArticleDesc>[];
  handleAddToList: ((item_catalogue: State<ItemCatalogueDesc>, setIsAdding: (adding: boolean) => void) => void) | undefined;
}

/**
 * Provider pour le contexte des listes saisonnières
 */
export const ListItemsProvider: React.FC<ListItemsProviderProps> = ({
  children,
  produits_liste,
  handleAddToList,
}) => {
  const contextValue = useMemo(() => {
    const isItemInList = (code_article: string) => {
      if (!produits_liste) {
        return false;
      }
      
      return produits_liste.some(p => p.data.tolede.code_article === code_article);
    };
    
    return {
      isItemInList,
      handleAddToList,
    };
  }, [handleAddToList, produits_liste]);
  
  return (
    <ListItemsContext.Provider value={contextValue}>
      {children}
    </ListItemsContext.Provider>
  );
};

/**
 * Hook pour accéder au contexte des articles dans la liste
 */
// eslint-disable-next-line react-refresh/only-export-components
export const useListItems = () => useContext(ListItemsContext);
