import {BlueButton} from '@/components/Button/BlueButton';
import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import {useSocieteCommerciale} from '@/haligator-factory';
import {PanierCommands} from 'ff-common/src/hooks/panier/use-panier-commands';
import {WarningIcon} from '@chakra-ui/icons';
import {Text, VStack} from '@chakra-ui/layout';
import {Checkbox, HStack, Link, Spinner} from '@chakra-ui/react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {UseHalStateHook, useHalState} from '@synako/haligator';
import {PanierValiderOptionsDesc, QuantitesInhabituellesEventDesc} from 'api-types/ffconnect-hal';
import {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {routes} from 'ff-common';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {HttpError, Problem, State} from '@synako/halitrek';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {CommandeProblem, LigneProblem} from 'api-types/ffconnect';
import {useCapture} from '@/posthog/PosthogSetup';
import {QuantitesAnormalesModal} from './QuantitesAnormalesModal';

export const PanierRecapValider: React.FC<{
  validerCommand: PanierCommands['valider'],
  verifierQuantites: PanierCommands['verifierQuantites'],
  validerOptions: UseHalStateHook<PanierValiderOptionsDesc>,
  message_post_validation?:string,
  setCommandeProblems: (problems: CommandeProblem[]) => void,
  setLigneProblems: (problems: LigneProblem[]) => void
}> = ({
  validerCommand, 
  validerOptions,
  message_post_validation,
  setCommandeProblems,
  setLigneProblems,
  verifierQuantites,
}) =>  {
  const [isCGVChecked, setCGVChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const {date_panier_actif} = useParams();
  const {t} = useTranslation('common');
  const textToast = useSimpleTextToast();
  const onError = useDefaultOnError(textToast);
  const capture = useCapture();

  // Handler d'erreur personnalisé
  interface ValidationErrorData {
    type: string;
    status: number;
    url: string;
    title: string;
    stack: string;
    nb_problemes_bloquants: number;
    commande_problems: CommandeProblem[];
    lignes_problems: LigneProblem[];
    name: string;
    instance: string;
  }

  const handleValidationError = (error: Error): void => {
    if (error instanceof Error && error.name === 'Problem(422,unprocessable_command)') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const validationError = (error as any).body as ValidationErrorData;

      setCommandeProblems(validationError.commande_problems);
      setLigneProblems(validationError.lignes_problems);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError(error as any);
    }
  };

  const handleValiderInModal = () => {
    validerCommand.submitAsync({
      cgv_acceptee: isCGVChecked,
    })
      .then((eventValidation) => {
        if (message_post_validation) {
          NiceModal.show(PopupMessagePostValidation, {message: message_post_validation});
        }
        window.scrollTo({
          top: 0,
          left: 0
        });

        capture({name:'panier:go', props:{depuis:'apres_validation'}});
        navigate(buildAssistanceRoute(routes.client.panier, {date_panier_actif}, {derniereAction: 'valide', logique: eventValidation.data.data.logique}));
      })
      .catch((error: unknown) => {
        if (error instanceof Error) {
          handleValidationError(error);
        } else {
          onError(error as HttpError | Problem);
        }
      });
  };

  const onClick = () => {
    verifierQuantites.submitAsync({})
      .then((eventQuantites: State<QuantitesInhabituellesEventDesc>) => {
      // si on a des quantités inhabituelles, on les affiche
        const alertes = eventQuantites.data.data.alertes;
        if (alertes.length > 0) {
          NiceModal.show(QuantitesAnormalesModal, {
            alertes,
            onValider: () => {
              capture({
                name: 'panier:quantites_anormales:valider',
                props: {
                  depuis: 'modale_quantites_anormales'
                }
              });
              handleValiderInModal();
            },
          });
          return null;
        }
        // Sinon on continue avec la validation du panier
        return validerCommand.submitAsync({
          cgv_acceptee: isCGVChecked,
        });
      })
      .then((eventValidation) => {
        // Si on a un eventValidation, c'est qu'on n'a pas eu de quantités anormales
        // et que la validation s'est bien passée
        if (eventValidation) {
          if (message_post_validation) {
            NiceModal.show(PopupMessagePostValidation, {message: message_post_validation});
          }
          window.scrollTo({
            top: 0,
            left: 0
            // behavior: 'smooth', //This cause the window to stop scrolling when the collapse opens

          });

          capture({name:'panier:go', props:{depuis:'apres_validation'}});
          navigate(buildAssistanceRoute(routes.client.panier, {date_panier_actif}, {derniereAction: 'valide', logique: eventValidation.data.data.logique}));
        }
      })
      .catch((error: unknown) => {
        if (error instanceof Error) {
          handleValidationError(error);
        } else {
          // Type assertion for the error to match the expected type
          onError(error as HttpError | Problem);
        }
      });
  };

  const soc = useHalState(useSocieteCommerciale());

  // Vérifie si les CGV doivent être acceptées
  const mustCheckCGV = validerOptions.data!==undefined && !validerOptions.data.cgv_acceptee;
  
  // Désactive le bouton pendant le chargement ou si d'autres conditions sont remplies
  const disable = 
    verifierQuantites.isSubmitting
    || verifierQuantites.isDisabled
    || validerCommand.isSubmitting
    || validerCommand.isDisabled
    || validerOptions.isDisabled
    || validerOptions.loading
    || validerOptions.refreshing
    || !!validerOptions.data?.nb_problemes_bloquants
    || (!(isCGVChecked) && mustCheckCGV);

  return (
    <> 
      <VStack gap='3'
        order={{base: '-1', lg: 'initial'}}
        alignItems='stretch'
        mt={!mustCheckCGV ? '5' : '0'}>
        {mustCheckCGV && <Checkbox alignItems='baseline'
          gap='2'
          my='2'
          isChecked={isCGVChecked}
          onChange={(e) => {setCGVChecked(e.target.checked);}}>
          <Text textAlign='start'
            textColor='black'>
            <Trans 
              t={t}
              components={{
                Link: <Link href={soc.data?.url_cgv}
                  target="_blank"
                  textDecorationLine='underline'
                  cursor='pointer' />
              }}  
            >{t('panier.recap.accepter_conditions')}</Trans>
            <Text as="span"
              textColor='red.warning'> *</Text>
          </Text>
        </Checkbox>}
        <BlueButton
          texte={(validerCommand.isSubmitting || verifierQuantites.isSubmitting) ? <Spinner/> : t('panier.recap.valider_le_panier')}
          onClick={onClick}
          mt='0'
          isDisabled={disable}
        />
      </VStack>
    </>
  );
};

export const PopupMessagePostValidation = NiceModal.create(({message}:{message:string})=>{
  const {t} = useTranslation('common');
  const modal = useModal();
  return <ModaleLayout
    visible={modal.visible}
    hide={modal.remove}
    headerIcon={<WarningIcon/>}
    titre={t('global.attention')}
  >
    <HStack alignItems={'baseline'}
      bg='blue.light'>
      <Text>{message}</Text>
    </HStack>
    <BlueButton w='100%'
      mt='10'
      onClick={modal.remove}
      texte={t('global.compris')}/>
  </ModaleLayout>; 
});
