import {useEffect, useState} from 'react';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack
} from '@chakra-ui/react';
import {ChevronDownIcon, ChevronLeftIcon} from '@chakra-ui/icons';
import {CartIcon} from '@/components/svg/CartIcon';
import {SwitchIcon} from '@/components/svg/SwitchIcon';
import {SearchIcon} from '@/components/svg/SearchIcon';
import {useTranslation} from 'react-i18next';
import {imageOptimisee} from '../mediatheque/optimisation';
import {LOGO_FF_COULEUR_URL} from '../mediatheque/logoFFCouleurUrl';
import {ContactCommercialIcon} from '@/components/svg/ContactCommercialIcon';
import {Link as RouterLink} from 'react-router-dom';
import {UserIcon} from '@/components/svg/UserIcon';
import {TPath, useTypedQuery} from '@synako/typed-path';
import {FiltresItemCatalogueGrandCompte, coerceFiltresItemCatalogueGrandCompte as coerce, routes, useDebouncedCallback} from 'ff-common';
import {useNomenclature} from '@/haligator-factory';
import {HaliRenderOne} from '@synako/haligator';
import {SelectSortBy} from '../catalogue/components/CatalogFilter/CatalogFilter';

// Composant générique pour les boutons de filtre de catégorie
interface CategoryFilterButtonProps {
  label: string;
  isActive: boolean;
  leftIcon?: React.ReactElement;
  onClick: () => void;
}

const CategoryFilterButton: React.FC<CategoryFilterButtonProps> = ({label, isActive, leftIcon, onClick}) => {
  return (
    <Button
      leftIcon={leftIcon}
      variant={isActive ? 'solid' : 'outline'}
      bg={isActive ? 'blue.500' : 'white'}
      color={isActive ? 'white' : 'black'}
      onClick={onClick}
      size="sm"
      borderRadius="md"
    >
      {label}
    </Button>
  );
};

// Composant pour la recherche dans le catalogue grand compte
export const CatalogueGrandCompteSearch: React.FC<{backoffice?: boolean}> = ({backoffice = false}) => {
  const {t} = useTranslation();
  // Récupération des catégories de la nomenclature
  const nomenclatureNav = useNomenclature();
  
  // Stockage des filtres dans l'URL
  const filtersHook = useTypedQuery<TPath<{Query: FiltresItemCatalogueGrandCompte}>>(coerce);

  // État local pour stocker la valeur du champ de recherche
  const [searchValue, setSearchValue] = useState(filtersHook.params.q ?? '');
  
  // Mettre à jour le champ de recherche quand le paramètre q change dans l'URL
  useEffect(() => {
    setSearchValue(filtersHook.params.q ?? '');
  }, [filtersHook.params.q]);

  // Fonction de changement de filtre actif
  const handleFilterClick = (filter: keyof FiltresItemCatalogueGrandCompte, value: string) => {
    filtersHook.change(filter, value);
  };
  
  // Fonction pour naviguer vers une catégorie
  const navigateToCategory = (categoryId: string) => {
    filtersHook.change('cat_web', categoryId);
    //Reset les autres filtres?
  };
  
  // Implémentation du debounce pour la recherche à 400ms
  const debouncedSearchHandler = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchTerm = event.target.value.trim();
      filtersHook.change('q', searchTerm.length > 0 ? searchTerm : undefined);
    },
    400 // Délai de 400ms
  );

  // Fonction pour gérer le changement de valeur du champ de recherche
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    debouncedSearchHandler(event);
  };

  return (
    <Box bg="white">
      {/* Header avec logo, search et user */}
      <HStack 
        w="100%" 
        h={{base: '16', md: '20'}} 
        alignItems="center" 
        p={{base: '0', md: '3'}} 
        px={{base: '0', md: '2', xl: '20'}} 
        gap="4" 
        bg="white"
        borderBottom="1px solid"
        borderColor="gray.light"
      >
        {/* Logo */}
        <Link as={RouterLink}
          to={backoffice 
            ? routes.client.backOfficeCatalogueGrandCompte.path 
            : routes.client.catalogueGrandCompte.path}>
          <Image src={imageOptimisee(LOGO_FF_COULEUR_URL, 300)}
            alt={t('global.alt_logo_france_frais')}
            marginX='auto'
            w="100px"
            mt={{base: '30px', md: '0'}} />
        </Link>
        {/* Dropdown et searchbar */}
        <HStack flex="1"
          spacing='0'
          borderRadius={8}
          border={'2px solid'}
          borderColor="gray.200"
        >
          <Box position="relative">
            <Popover placement="bottom-start">
              <PopoverTrigger>
                <Button
                  variant="outline"
                  rightIcon={<ChevronDownIcon />}
                  borderRight="none"
                  borderTopRightRadius="0"
                  borderBottomRightRadius="0"
                  borderColor="gray.200"
                  height="42px"
                >
                  {filtersHook.params.cat_web ? (
                    <HaliRenderOne
                      nav={nomenclatureNav}
                      render={({data}) => {
                        const selectedCategory = data.web.categories.find(cat => filtersHook.params.cat_web?.includes(cat.id));
                        const selectedCategoryParent = selectedCategory && data.web.categories.find(cat => cat.id === 
                          (selectedCategory.id_parent === '' ? selectedCategory.id : selectedCategory.id_parent));
                        return selectedCategoryParent?.libelle ?? t('grand_compte.categories');
                      }}
                    />
                  ) : (
                    t('grand_compte.categories')
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent width="250px">
                <PopoverBody p={2}
                  bg='white'
                >
                  <HaliRenderOne
                    nav={nomenclatureNav}
                    render={({data}) => (
                      <VStack align="stretch"
                        spacing={1}>
                        {/* Catégories de la nomenclature */}
                        {data.web.categories
                          .filter(categorie => !categorie.id_parent) // Filtrer uniquement les catégories principales
                          .map(categorie => (
                            <Button 
                              key={categorie.id}
                              variant="ghost"
                              size="sm"
                              justifyContent="flex-start"
                              onClick={() => {navigateToCategory(categorie.id);}}
                            >
                              {categorie.libelle}
                            </Button>
                          ))
                        }
                      </VStack>
                    )}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>

          <InputGroup flex="1">
            <InputLeftElement pointerEvents="auto">
              <SearchIcon />
            </InputLeftElement>
            <Input
              value={searchValue}
              placeholder={t('grand_compte.chercher_un_produit')}
              borderTopLeftRadius="0"
              borderBottomLeftRadius="0"
              borderColor="gray.200"
              height="42px"
              onChange={handleSearchChange}
            />
          </InputGroup>
        </HStack>

        {/* Section service client */}
        <HStack display={{base: 'none', md: 'flex'}}>
          <ContactCommercialIcon/>
          <VStack spacing="0"
            alignItems="flex-start">
            <Text color="blue.500"
              fontWeight="bold">{t('grand_compte.sav_question')}</Text>
            <Link color="blue.500"
              textDecorationLine={'underline'}
              fontSize="sm">{t('mes_informations.contacter_commercial')}</Link>
          </VStack>

          <Box position="relative"
            ml='10'
          >
            <Popover placement="bottom-end">
              <PopoverTrigger>
                <HStack 
                  _hover={{bg: 'gray.200'}}
                  bg='gray.100'
                  p='2'
                  cursor="pointer"
                  borderRadius='full'>
                  <Avatar
                    name="M D"
                    bg="green.500"
                    color="white"
                    size="sm"
                  />
                  <ChevronDownIcon />
                </HStack>
              </PopoverTrigger>
              <PopoverContent width="200px">
                <PopoverBody p={2}
                  bg='white'
                >
                  <VStack align="stretch"
                    spacing={1}>
                    <Button variant="ghost"
                      size="sm"
                      justifyContent="flex-start"
                      leftIcon={<CartIcon
                        color={'gray.dark'} />}>
                      {t('menu.passer_commande')}
                    </Button>
                    <Button variant="ghost"
                      size="sm"
                      justifyContent="flex-start"
                      leftIcon={<Icon as={SwitchIcon} />}>
                      {t('menu.changer_de_client')}
                    </Button>
                    <Button variant="ghost"
                      size="sm"
                      justifyContent="flex-start"
                      leftIcon={<UserIcon color={'gray.dark'} />}>
                      {t('menu.deconnecter')}
                    </Button>
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </HStack>
      </HStack>

      {/* Section filtres de catégorie */}
      <HaliRenderOne
        nav={nomenclatureNav}
        render={({data: dataNomenclature}) => {
          const categories = dataNomenclature.web.categories.filter(categorie => categorie.id_parent===filtersHook.params.cat_web?.[0]);
          const isSubCategory = filtersHook.params.cat_web?.[0].includes('-');
          const subCategory = dataNomenclature.web.categories.find(categorie => categorie.id === filtersHook.params.cat_web?.[0]);
          return <HStack 
            spacing="2" 
            p="2" 
            overflowX="auto"
            borderBottom="1px solid"
            borderColor="gray.100"
          >
            {/* Retour */}
            {isSubCategory && subCategory && <CategoryFilterButton
              label={subCategory.libelle}
              isActive={true}
              leftIcon={<ChevronLeftIcon />}
              onClick={() => {handleFilterClick('cat_web', subCategory.id_parent);}}
            />}
            {/* Composant placeholders pour les boutons sous catégories */}
            {categories.map((filter, index) => (
              <CategoryFilterButton
                key={index}
                label={filter.libelle}
                isActive={filtersHook.params.cat_web?.includes(filter.id) ?? false}
                onClick={() => {handleFilterClick('cat_web', filter.id);}}
              />
            ))}
          </HStack>;
        }
        }
      />

      {/* Section des options de filtrage */}
      <Flex 
        justify="space-between" 
        p="3"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        <HStack divider={<Box borderLeft="1px"
          borderColor="gray.200"
          height="20px"
          mx={2} />}>
          {/*<Box height="38px"
            p="7px 11px 7px 11px"
            borderRadius="8px"
            fontWeight="400"
            color="blue.main"
            border="1px"
            borderColor="gray.light"
            bg="white">
            <Checkbox
              borderColor="gray.light"
              size="sm"
              onChange={(event) => {
                filtersHook.change('tac', event.target.checked ? 'true' : undefined);
              }}
              sx={{
                '& .chakra-checkbox__control': {
                  borderColor: 'gray.light',
                  _checked: {
                    bg: 'blue.main',
                    borderColor: 'blue.main',
                  },
                },
              }}
            >
              <Text>TAC</Text>
            </Checkbox>
          </Box> */}
          
          {/* Sourcing dropdown */}
          <Popover>
            <PopoverTrigger>
              <Button
                variant="outline"
                size="sm"
                fontWeight="normal"
                rightIcon={<ChevronDownIcon />}
                borderColor="gray.200"
                bg="white"
              >
                {t('catalogue.filtre.sourcing')}
              </Button>
            </PopoverTrigger>
            <PopoverContent marginLeft="10px"
              width="300px">
              <PopoverBody maxH="300px"
                overflowY="auto"
                backgroundColor="white"
                p={4}>
                <Flex direction="column"
                  gap={2}>
                  {/* eslint-disable-next-line i18next/no-literal-string */}
                  <Checkbox size="sm">Placeholder 1</Checkbox>
                  {/* <Checkbox size="sm">Placeholder 2</Checkbox>
                  <Checkbox size="sm">Placeholder 3</Checkbox> */}
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          
          {/* Labels dropdown */}
          <Popover>
            <PopoverTrigger>
              <Button
                variant="outline"
                size="sm"
                fontWeight="normal"
                rightIcon={<ChevronDownIcon />}
                borderColor="gray.200"
                bg="white"
                m='0'
              >
                {t('catalogue.filtre.labels')}
              </Button>
            </PopoverTrigger>
            <PopoverContent marginLeft="10px"
              width="300px">
              <PopoverBody maxH="300px"
                overflowY="auto"
                backgroundColor="white"
                p={4}>
                <Flex direction="column"
                  gap={2}>
                  {/* <Checkbox size="sm">Bio</Checkbox>
                  <Checkbox size="sm">Label Rouge</Checkbox>
                  <Checkbox size="sm">AOP</Checkbox> */}
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>

        {/* Tri par pertinence */}
        <SelectSortBy
          sortBy={filtersHook.params.sortBy}
          handleSortChange={(event) => {
            filtersHook.change('sortBy', event.target.value as 'pertinence' | 'alphabetique');
          }} />
      </Flex>

      {/* Contenu principal - à implémenter dans le futur */}
      <Box p="4"
        minH={'50vh'}>
        {/* Ici viendra le contenu des produits */}
      </Box>
    </Box>
  );
};
