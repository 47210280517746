import {Flex, Tag, TagCloseButton, TagLabel, Text} from '@chakra-ui/react';
import {useCallback} from 'react';
import {CalendarIcon} from '@chakra-ui/icons';
import {DateRangeComponentProps, openDateRangeModal} from '../utils/dateRangeUtils';
import {format, parseISO} from 'date-fns';
import {fr} from 'date-fns/locale';

interface DateRangeTagProps extends Omit<DateRangeComponentProps, 'selectedDateRange'> {
  dateRange: NonNullable<DateRangeComponentProps['selectedDateRange']>;
}

/**
 * Tag affichant la plage de dates sélectionnée avec possibilité de la modifier ou supprimer
 */
export const DateRangeTag: React.FC<DateRangeTagProps> = ({
  dateRange,
  onDateRangeChange
}) => {
  // Ouverture de la modale pour modifier la plage de dates
  const handleOpenModal = useCallback(() => {
    openDateRangeModal(onDateRangeChange, dateRange);
  }, [onDateRangeChange, dateRange]);
  
  // Suppression de la plage de dates
  const clearDateRange = useCallback((e: React.MouseEvent) => {
    e.stopPropagation(); // Évite d'ouvrir la modale lors du clic sur le bouton de suppression
    onDateRangeChange(null);
  }, [onDateRangeChange]);

  // Formatage simple d'une date ISO
  const formatDate = (dateStr: string): string => {
    try {
      const date = parseISO(dateStr);
      return format(date, 'dd/MM/yyyy', {locale: fr});
    } catch (error) {
      return dateStr;
    }
  };

  return (
    <Tag
      size="lg"
      borderRadius="md"
      variant="solid"
      cursor="pointer"
      color={'white'}
      bg={'blue.main'}
      onClick={handleOpenModal}
      p={2}
      my={2}
    >
      <TagLabel>
        <Flex alignItems="center"
          gap={2}>
          <CalendarIcon />
          <Text>
            {formatDate(dateRange.date_debut)} - {formatDate(dateRange.date_fin)}
          </Text>
        </Flex>
      </TagLabel>
      <TagCloseButton onClick={clearDateRange} />
    </Tag>
  );
};
