import {State} from '@synako/halitrek';
import {ArticlesCadencierSuggestionsAjoutesEventDesc} from 'api-types/ffconnect-hal';
import {useTranslation} from 'react-i18next';
import {TextToastCommand} from '../../types/TextToastCommand';

/**
 * Hook pour gérer dans le cadencier l'annulation des suggestions d'articles ajoutées au panier
 */
export const useAnnulationSuggestions = () => {
  const {t} = useTranslation();

  const annulerSuggestions = async (
    setIsAnnulationEnCours: (isLoading: boolean) => void,
    textToast: TextToastCommand,
    onToastClose?: () => void,
    event?: State<ArticlesCadencierSuggestionsAjoutesEventDesc>
  ) => {
    if (!event) return 0;

    // Fermeture du toast d'ajout au panier si demandé
    if (onToastClose) {
      onToastClose();
    }

    setIsAnnulationEnCours(true);
    try {
      // Récupération des codes articles qui ont été ajoutés
      const articlesAjoutes = event.data.data.panier.articles_ajoutes ?? [];

      // Récupération des lignes de panier à partir de l'événement
      const lignesDePanier = await event.follow('panier').followAll('lignes_de_panier').getAll();

      // Nombre d'opérations réussies
      let operationsReussies = 0;

      // Pour chaque article ajouté
      for (const article of articlesAjoutes) {
        // ligne actuelle dans le panier
        const ligne = lignesDePanier.find(l => l.data.code_article === article.code_article);

        if (!ligne) {
          console.error('Ligne non trouvée pour l\'article', article.code_article);
          textToast(t('cadencier.suggestion.ligne_introuvable', {code_article: article.code_article}), {icon: 'alert', id: 'ligne-introuvable-toast'});
          continue;
        }

        // Cas 1: L'article n'était pas dans le panier avant (ajout) -> on le supprime
        if (!article.etait_dans_panier) {
          await ligne.submitCommand('c:supprimer-du-panier', {});
          operationsReussies++;
        }
        // Cas 2: L'article était déjà dans le panier (modification) -> on remet la quantité initiale
        else if (article.quantite_initiale !== undefined) {
          await ligne.submitCommand('c:changer-la-quantite', {
            quantite: article.quantite_initiale,
            commande_en: 'unitaire'
          });
          operationsReussies++;
        }
      }

      // Succès de l'annulation
      if (operationsReussies > 0) {
        textToast(t('cadencier.suggestion.action_annulee', {count: operationsReussies}), {id: 'articles-annules-toast'});
      } else {
        textToast(t('cadencier.suggestion.annulation_echouee'), {icon: 'alert', id: 'annulation-echouee-toast'});
      }
      
      return operationsReussies;
    } catch (error) {
      console.error('Erreur lors de l\'annulation', error);
      textToast(t('cadencier.suggestion.annulation_echouee'), {icon: 'alert', id: 'annulation-echouee-toast'});
      return 0;
    } finally {
      setIsAnnulationEnCours(false);
    }
  };

  return {annulerSuggestions};
};
