import {Box, Button, HStack, Spinner, Stack, Text, Tooltip, VStack, useToast} from '@chakra-ui/react';
import {EtoilesIcon} from '@/components/svg/EtoilesIcon';
import {useTranslation} from 'react-i18next';
import {useCallback, useRef, useState} from 'react';
import {useCommand, useHalState} from '@synako/haligator';
import {routes, useAnnulationSuggestions, useLivraisonsPossiblesContext} from 'ff-common';
import {useNavigate} from 'react-router-dom';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {PanierLink} from '@/domains/panier/controllers/PanierLink';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {ArticlesCadencierSuggestionsAjoutesEventDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {useClient} from '@/haligator-factory';

interface SuggestionActionsProps {
  onSuggestionSuccess?: (event: State<ArticlesCadencierSuggestionsAjoutesEventDesc>) => void;
}
// Bouton pour tout ajouter d'un coup tous les articles ayant une suggestion de quantité
export const SuggestionActions: React.FC<SuggestionActionsProps> = ({
  onSuggestionSuccess
}) => {
  const {t} = useTranslation();
  const textToast = useSimpleTextToast();
  const toast = useToast();
  const navigate = useNavigate();
  const client = useClient();
  const {state: suggestionsState} = useHalState(client.follow('suggestions'));
  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const {isAssistanceMode, buildAssistanceRoute} = useIsAssistanceMode();
  const {annulerSuggestions} = useAnnulationSuggestions();
  
  // Stocke l'événement d'ajout pour pouvoir annuler
  const lastAddEventRef = useRef<State<ArticlesCadencierSuggestionsAjoutesEventDesc> | null>(null);
  const [isAnnulationEnCours, setIsAnnulationEnCours] = useState(false);
  // Stocke l'ID du toast pour pouvoir le fermer
  const toastIdRef = useRef<string | undefined>('ajoute-commande-panier-toast');

  const onSuccess = useCallback((eventState: State<ArticlesCadencierSuggestionsAjoutesEventDesc>) => {
    // Récupération du nombre d'articles depuis l'événement
    const articles = eventState.data.data.panier.nombre_articles_ajoutes ?? 0;
    // Stocke l'évent pour l'annulation
    lastAddEventRef.current = eventState;

    const handleNavigate = () => {
      const url = buildAssistanceRoute(routes.client.panier_redirect, {date_de_livraison: livraisonSelectionnee.data.tolede.date_de_livraison});
      navigate(url);
    };

    // Fonction pour annuler les articles ajoutés
    const handleAnnuler = async () => {
      const event = lastAddEventRef.current;
      if (!event) return;

      // Fermeture du toast
      const closeToast = () => {
        if (toastIdRef.current) {
          toast.close(toastIdRef.current);
        }
      };

      // hook pour annuler l'ajout au panier des suggestions de quantité
      await annulerSuggestions(
        setIsAnnulationEnCours,
        textToast,
        closeToast,
        event
      );
    };

    // recuperation de l'event pour le remonter et l'utiliser pour l'affichage du picto
    if (onSuggestionSuccess) {
      onSuggestionSuccess(eventState);
    }

    // Afficher le toast avec les articles ajoutés
    textToast([
      t('cadencier.suggestion.articles_ajoutes_au_panier', {count: articles}),
      <HStack key="action-buttons"
        spacing={2}>
        {isAssistanceMode ? (
          <Button
            key="voir-panier-cta"
            onClick={handleNavigate}
            variant='unstyled'
            fontWeight="bold"
            textDecoration="underline">
            {t('toast.voir_panier')}
          </Button>
        ) : (
          <PanierLink
            t={t}
            date_de_livraison={livraisonSelectionnee.data.tolede.date_de_livraison}
            navigate={navigate} />
        )}
        <Text>•</Text>
        <Button
          key="annuler-cta"
          onClick={handleAnnuler}
          variant='unstyled'
          fontWeight="bold"
          textDecoration="underline"
          disabled={isAnnulationEnCours}
          isLoading={isAnnulationEnCours}>
          {t('global.annuler')}
        </Button>
      </HStack>
    ], {id: toastIdRef.current, duration: 5000}); // Utilisation du même ID stockée dans la ref + delai pour laisser le temps d'annuler
  }, [livraisonSelectionnee, navigate, t, textToast, toast, buildAssistanceRoute, isAssistanceMode, onSuggestionSuccess, isAnnulationEnCours, annulerSuggestions]);

  const onError = useDefaultOnError(textToast);

  const {submit: submitAjouterArticlesSuggeres, isSubmitting} = useCommand(
    livraisonSelectionnee,
    'c:ajouter-suggestions-articles-cadencier-au-panier',
    {onSuccess, onError}
  );

  const handleClick = useCallback(() => {
    submitAjouterArticlesSuggeres({});
  }, [submitAjouterArticlesSuggeres]);

  return (
    <Stack 
      spacing={15}
      direction={{base: 'column', md: 'row'}}
      justify="space-between"
      alignItems="center"
      mt={0}
      mb={2}
      mr='10px'
      ml='10px'
      p={2}
      borderRadius="md"
      backgroundColor="blue.sky_light"
      w="100%"
    >
      <HStack alignItems="flex-start"
        maxW={{base: '100%', md: '65%'}}
        flexWrap={{base: 'wrap', md: 'nowrap'}}>
        <Box 
          display={{base: 'none', md: 'inline'}}
          py="10px"
          px="12px"
          bgGradient="linear(to-r, blue.main, blue.sky)"
          borderRadius="8px"
          mr={3}
          flexShrink={0}
        >
          <EtoilesIcon color='white'/>
        </Box>
        <VStack 
          align="start"
          spacing={1}
          flex="1"
          minW={0}
        >
          <Text
            fontWeight="700"
            fontSize="sm"
            color="blue.main"
          >
            {t('cadencier.suggestion.remplissez_votre_panier')}
          </Text>
          <HStack flexWrap="wrap"
            spacing={1}
            width="100%">
            <Text
              fontSize={{base: 'xs', md: 'sm'}}
              color="blue.main"
              whiteSpace="normal"
            >
              {t('cadencier.suggestion.ajoutez_en_un_clic')}
            </Text>
            <Tooltip label={t('cadencier.suggestion.savoir_plus_tooltip')}>
              <Text
                fontSize={{base: 'xs', md: 'sm'}}
                color="blue.main"
                textDecor={'underline'}
                whiteSpace="nowrap"
              >
                {t('cadencier.suggestion.savoir_plus')}
              </Text>
            </Tooltip>
          </HStack>
        </VStack>
      </HStack>

      <Tooltip label={!suggestionsState?.data.suggestions.length ? t('cadencier.suggestion.pas_suggestions_disponibles') : undefined}>
        <Button
          leftIcon={isSubmitting ? (
            <Spinner size="sm"
              color="white" />
          ) : undefined}
          bgGradient="linear(to-r, blue.main, blue.sky)"
          width={{base: '100%', md: 'auto'}}
          minW={{md: '200px'}}
          color="white"
          onClick={handleClick}
          disabled={isSubmitting || !suggestionsState?.data.suggestions.length}
          _hover={!suggestionsState?.data.suggestions.length ? {opacity: 0.4}: {opacity: 0.8}}
          fontSize="sm"
          px="10px"
          py="10px"
          whiteSpace="normal"
          flexShrink={0}
        >
          {t('cadencier.suggestion.remplir_avec_suggestions')}
        </Button>
      </Tooltip>
    </Stack>
  );
};
