import {Box, Button, HStack, Spinner, Tooltip} from '@chakra-ui/react';
import {useCommand} from '@synako/haligator';
import {State} from '@synako/halitrek';
import {ArticlesCadencierSuggestionsAjoutesEventDesc, ItemCatalogueDesc, LigneDePanierDesc, SuggestionsStateDesc} from 'api-types/ffconnect-hal';
import {useTranslation} from 'react-i18next';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {routes, useLivraisonsPossiblesContext} from 'ff-common';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {useNavigate} from 'react-router-dom';
import {PanierLink} from '../panier/controllers/PanierLink';
import {LightIcon} from '@/components/svg/LightIcon';
import {EtoilesIcon} from '@/components/svg/EtoilesIcon';
import {useAjouterAuPanier} from '@/hooks/livraison/use-ajouter-au-panier';
import {HistoriqueAchatButton} from './HistoriqueAchatButton';

// Affichage des suggestions de quantité pour un article
// Bouton pour ajouter l'article avec sa suggestion de quantité
export const SuggestionQuantite: React.FC<{
  itemState: State<ItemCatalogueDesc>,
  suggestionsState?: State<SuggestionsStateDesc>,
  lastSuggestionEvent?: State<ArticlesCadencierSuggestionsAjoutesEventDesc>,
  ligneDePanier?: State<LigneDePanierDesc>,
}> = ({
  itemState, suggestionsState, lastSuggestionEvent, ligneDePanier
}) => {
  const {t} = useTranslation();
  const textToast = useSimpleTextToast();
  const {code_article} = itemState.data.tolede;
  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const {isAssistanceMode, buildAssistanceRoute} = useIsAssistanceMode();
  const navigate = useNavigate();

  // Pour le changement de quantité si article deja présent au panier
  const {submit: submitChangerQuantite, isSubmitting: isChangementQuantite} = useCommand(
    ligneDePanier,
    'c:changer-la-quantite'
  );

  // Pour l'ajout au panier si article non présent au panier
  const {submit: ajouterAuPanierAction, isSubmitting: isAjoutAuPanier} = useAjouterAuPanier(
    livraisonSelectionnee.data.id,
    textToast
  );

  // Vérification si cet article fait partie des derniers ajoutés
  const articlesAjoutes = lastSuggestionEvent?.data.data.panier.articles_ajoutes;
  const estDansArticlesAjoutes = Array.isArray(articlesAjoutes) && articlesAjoutes.some(article =>
    article.code_article === code_article
  );

  // Trouver la suggestion de quantité pour cet article
  const suggestions = suggestionsState?.data.suggestions;
  const suggestionArticle = Array.isArray(suggestions)
    ? suggestions.find((s) => s.code_article === code_article)
    : undefined;

  if (!suggestionArticle) return null;

  const handleClick = async () => {
    if (ligneDePanier) {
      // Si l'article est déjà dans le panier, on met à jour la quantité
      submitChangerQuantite({
        quantite: suggestionArticle.suggestion_quantite
      });
      textToast([
        t('cadencier.suggestion.article_changement_quantite'),
        isAssistanceMode ? (
          <Button
            key="voir-panier-cta"
            onClick={() => {
              const url = buildAssistanceRoute(routes.client.panier_redirect, {
                date_de_livraison: livraisonSelectionnee.data.tolede.date_de_livraison
              });
              navigate(url);
            }}
            variant='unstyled'
            fontWeight="bold"
            textDecoration="underline">
            {t('toast.voir_panier')}
          </Button>
        ) : (
          <PanierLink
            t={t}
            date_de_livraison={livraisonSelectionnee.data.tolede.date_de_livraison}
            navigate={navigate} />
        )
      ], {id:'modifie-quantite-panier-toast'});
    } else {
      // Sinon on ajoute l'article au panier
      ajouterAuPanierAction({
        code_article: code_article,
        quantite: suggestionArticle.suggestion_quantite,
        commande_en: 'unitaire'
      });
    }
  };

  const isSubmitting = isChangementQuantite || isAjoutAuPanier;

  return (
    <HStack w='100%'>
      <HistoriqueAchatButton
        itemState={itemState}
        showIconOnly={true}
      />
      <HStack spacing={2}
        alignItems="center"
        justifyContent='center'
        borderRadius='5px'
        backgroundColor='blue.main_transparency_10'
        padding='2px 4px 4px 2px'
        w='100%'>
        {isSubmitting && <Spinner size="xs"
          mr={2} />}
        {!isSubmitting && (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            background={!estDansArticlesAjoutes ? 'blue.main_transparency_10' : 'blue.main'}
            borderRadius='8px'
            width='20px'
            height='20px'
            marginRight='2px'
          >
            {!estDansArticlesAjoutes ?
              <Tooltip label={t('cadencier.suggestion.tooltip')}>
                <span>
                  <LightIcon
                    color="blue.main"
                    width="10px"
                    height="14px"
                  />
                </span>
              </Tooltip>
              :
              <Tooltip label={t('cadencier.suggestion.quantite_suggeree_presente')}>
                <span>
                  <EtoilesIcon color='white'
                    width='12px'
                    height='12px' />
                </span>
              </Tooltip>
            }
          </Box>
        )}
        <Tooltip label={t('cadencier.suggestion.tooltip')}>
          <Box
            color="blue.main"
            fontSize="sm"
            cursor="pointer"
            _hover={{
              textDecoration: 'underline',
              color: 'blue.500'
            }}
            onClick={handleClick}
            display="flex"
            alignItems="center"
          >
            {t('cadencier.suggestion.texte', {
              count: suggestionArticle.suggestion_quantite,
              unite: t(`unite.${suggestionArticle.unite_de_commande_client}`, {count: suggestionArticle.suggestion_quantite})
            })}
          </Box>
        </Tooltip>
      </HStack>
    </HStack>
  );
};
