import {createContext} from 'react';
import {FollowPromiseOne} from '@synako/halitrek';
import {ClientDesc} from 'api-types/ffconnect-hal';

// Type du contexte
export interface BackofficeClientContextValue {
  client: FollowPromiseOne<ClientDesc>;
}

// Création du contexte
export const BackofficeClientContext = createContext<BackofficeClientContextValue | null>(null);
