import {AddIcon, ArrowBackIcon, MinusIcon} from '@chakra-ui/icons';
import {Button, HStack, Input, Text} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {Depuis, UniteQuantite} from 'api-types/ffconnect';
import {Unite} from 'api-types/tolede';
import {contextToDepuis, useBuyProductInputCommand} from 'ff-common';
import {useBoutonAchatQuantiteDisplay} from 'ff-common';
import {useCapture} from '@/posthog/PosthogSetup';
import {ContextsComposant} from 'ff-common/src/types/ContextsComposant';

export const BuyProductPanierInput: React.FC<{
  itemCatalogue: State<ItemCatalogueDesc>,
  quantite: number,
  onQuantite : (quantite: number) => void,
  commande_en: UniteQuantite,
  somethingSubmitting: boolean,
  context: ContextsComposant
}> = ({
  itemCatalogue,
  commande_en,
  quantite,
  onQuantite,
  somethingSubmitting,
  context
}) => {
  const [saisie, setSaisie] = useState(false);
  const capture = useCapture();
  
  //NOTE(Nathan) On limite à 0 pour permettre à l'utilisateur de supprimer l'article en dehors du panier
  const quantiteMin = 0; //Math.max(itemCatalogue.data.tolede.minimum_de_commande_client, itemCatalogue.data.tolede.multiple_de_commande_client);
  const quantiteMax = itemCatalogue.follow('disponibilite_article').getCache()?.data.quantite_livrable;

  const commandeEnColis = commande_en === 'colis';
  const colisEntier = itemCatalogue.data.tolede.quantite_colis_entier ?? 1;

  const {
    diminuerLaQuantite, augmenterLaQuantite, 
    debouncedQuantite, setDebouncedQuantite
  } = useBuyProductInputCommand(
    itemCatalogue,
    commandeEnColis,
    colisEntier,
    quantite,
    onQuantite
  );

  const depuisTracking = contextToDepuis(context);

  return (
    <HStack flexDirection={{base: 'row', md: 'row-reverse'}}
      w='100%' 
      transition='all 0.2s ease'
      opacity={somethingSubmitting?0.2:1.0}>
      {
        saisie ? 
          <SaisieDeQuantite
            quantiteInitiale={commandeEnColis ? (debouncedQuantite / colisEntier) : debouncedQuantite}
            step={commandeEnColis ? 1 : itemCatalogue.data.tolede.multiple_de_commande_client}
            unite={commandeEnColis ? 'CO' :itemCatalogue.data.tolede.unite_de_commande_client}
            quantiteMin={quantiteMin}
            code_article={itemCatalogue.data.tolede.code_article}
            depuis={depuisTracking}
            onClose={() => {setSaisie(false);}}
            onQuantite={(quantiteSaisie) => {
              const nouvelleQuantite = commandeEnColis ? quantiteSaisie * colisEntier : quantiteSaisie;
              setDebouncedQuantite(nouvelleQuantite);
              onQuantite(nouvelleQuantite);
              setSaisie(false);
            }}
          />
          :
          <HStack w='100%'>
            <Button isDisabled={debouncedQuantite<=quantiteMin}
              colorScheme='blue'
              onClick={()=>{
                capture({name:'panier:changer_quantite', props:{
                  code: itemCatalogue.data.tolede.code_article,
                  depuis: depuisTracking,
                  sens: 'moins',
                }});
                diminuerLaQuantite();
              }}><MinusIcon /></Button>
            <BuyDisplay
              itemCatalogue={itemCatalogue}
              commande_en={commande_en}
              onClick={() => {setSaisie(true);}}
              quantite={debouncedQuantite}
              somethingSubmitting={somethingSubmitting}
            />
            <Button isDisabled={quantiteMax !== undefined}
              colorScheme='blue'
              onClick={()=>{
                capture({name:'panier:changer_quantite', props:{
                  code: itemCatalogue.data.tolede.code_article,
                  depuis: depuisTracking,
                  sens: 'plus',
                }});
                augmenterLaQuantite();
              }}>
              {
                quantiteMax && quantite >= quantiteMax ? 
                  <>MAX</>
                  :
                  <AddIcon />
              }
            </Button>
          </HStack>
      }
    </HStack>
  );
};

const BuyDisplay: React.FC<{
  itemCatalogue: State<ItemCatalogueDesc>,
  commande_en: UniteQuantite,
  /** La quantité est obligatoirement en unite_de_commande_client */
  quantite: number,
  onClick: () => void;
  somethingSubmitting: boolean;
}> = ({
  itemCatalogue,
  commande_en,
  quantite,
  onClick,
  somethingSubmitting:_somethingSubmitting
}) => {
  const commandeEnColis = commande_en === 'colis';
  const {quantite_text} =  useBoutonAchatQuantiteDisplay({
    quantite, commandeEnColis,
    itemCatalogue, 
  });

  return (
    <HStack 
      textAlign='center' 
      fontSize='sm' 
      fontWeight='500' 
      boxSizing='border-box' 
      justify='center'
      w='100%' 
      outline='0.781px solid' 
      outlineColor='gray.dark' 
      borderRadius='8px' 
      px='11px' 
      py='8px'
      cursor='pointer' 
      _hover={{outlineColor:'blue.main', bg:'gray.bg'}}
      onClick={onClick}
    >
      <Text>
        {quantite_text}
      </Text>
    </HStack>
  );
};

const SaisieDeQuantite: React.FC<{
  /** Unité à afficher */
  unite: Unite,
  /** Ici c'est la quantité exprimée en  */
  quantiteInitiale: number,
  step: number,
  quantiteMin: number,
  code_article: string,
  depuis: Depuis,
  /** Quitter sans appliquer la quantité */
  onClose: () => void;
  /** Une fois que la quantité est validé.
   * 
   * Attention ici c'est la quantité saisie donc si colis pas automatiquement convertie en unité de commande client.
   */
  onQuantite: (
    quantiteSaisie: number
  ) => void;
}> = ({
  unite,
  quantiteInitiale,
  step,
  quantiteMin,
  code_article,
  depuis,
  onClose,
  onQuantite,
}) => {
  const {t} = useTranslation();
  const [quantite, setQuantite] = useState(quantiteInitiale);
  const capture = useCapture();

  const commitQuantite = () => {
    onQuantite(quantite);
  };
  return (
    <HStack w='100%'
      h='100%'
      alignItems='stretch'
      fontSize='sm'>
      <Button 
        colorScheme='gray' 
        bg="gray.bg"
        borderColor='gray' 
        onClick={onClose}
      >
        <ArrowBackIcon/>
      </Button>
      <HStack 
        position='relative' 
        border='1px' 
        borderColor='blue.main' 
        borderRadius='8px' 
        boxSizing='border-box'
        flex='1' 
        maxW={{base:'100%', lg:'13em'}} 
        w='100%' 
        p='0'
      >
        <Input 
          autoFocus={true} 
          textAlign='end' 
          fontSize='sm' 
          fontWeight='500' 
          w='45%' 
          border='none!important'
          type='number' 
          step={step} // De toute façon on fera l'arrondi plus tard
          value={quantite} 
          min={0}
          focusBorderColor='#0000' 
          p='0' 
          h='100%' 
          minW='0px'
          onChange={(event)=>{
            event.preventDefault();
            setQuantite(Math.max(quantiteMin, parseFloat(event.target.value)));
          }}
          onBlur={onClose}
          onKeyDown={(event)=>{
            if (event.key === 'Enter' || event.key === 'Tab') {
              capture({name:'panier:saisir_quantite', props:{
                code: code_article,
                depuis,
                valide_avec: event.key === 'Enter' ? 'enter' : 'tab'
              }});
              commitQuantite();
            }
            else if (event.key === 'Escape') {
              onClose();
            }
          }}
        />
        <Text textAlign='end'
          alignSelf='50%'>
          {t(`unite.${unite}`, {count: quantite})}
        </Text>
      </HStack>
      <Button 
        colorScheme='blue' 
        onMouseDown={()=>{
          capture({name:'panier:saisir_quantite', props:{
            code: code_article,
            depuis,
            valide_avec: 'button'
          }});
          commitQuantite();
        }}
        px='3'>
        <Text w='24px'>OK</Text>
      </Button>
    </HStack>    
  );
};
