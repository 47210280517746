import {Depuis} from 'api-types/ffconnect';
import {ContextsComposant} from '../types/ContextsComposant';

export const context_to_depuis: Record<ContextsComposant, Depuis|undefined> = {
  'cadencier': 'cadencier',
  'liste': 'liste',
  'fiche_produit': 'fiche',
  'panier': 'panier',
  'modale_preco': 'precommande',
  'commande': 'commande',
  'indisponible': 'autre',
  'catalogue': 'autre',
};

// Converti le ContextsComposant en une source utilisée pour le tracking
export const contextToDepuis: (context: ContextsComposant | undefined) => Depuis = (context) => {
  if (!context) return 'autre';
  return context_to_depuis[context] ?? 'autre';
};
