import {useLivraisonsPossiblesContext} from 'ff-common';
import {useClient} from '@/haligator-factory';
import {dateEnTexte} from 'ff-common';
import {HStack, Text, VStack} from '@chakra-ui/layout';
import {Tooltip} from '@chakra-ui/react';
import {HaliRenderOne} from '@synako/haligator';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {useTranslation} from 'react-i18next';
import {RotateSyncIcon} from '@/components/svg/RotateSyncIcon';
import {ArticleStatistiques} from 'api-types/tolede';

export const ProduitDejaCommande: React.FC<{
  itemState: State<ItemCatalogueDesc>,
  // date_de_livraison: string,
}> = ({
  itemState,
  // date_de_livraison,
}) => {
  const client = useClient();
  const {t} = useTranslation();
  const {date_de_livraison} = useLivraisonsPossiblesContext();

  const codeArticle = itemState.data.tolede.code_article;

  // console.log('RERENDER : ProduitDejaCommande '+ itemState.data.tolede.code_article);
  
  return(
    <HaliRenderOne
      nav={client.follow('commande_statistiques', {date_de_livraison})}
      render={({data}) => {
        const articles = data.par_article as ArticleStatistiques[];
        const articleTrouve = articles.find(article => article.code_article === codeArticle);
        if(articleTrouve){
          return (
            <VStack justifyContent='center'
              alignItems='center'
              mt='2px'
              pr='5px'
              pl='5px'
              borderRadius='5px'
              backgroundColor='blue.main_transparency_10'
              w='100%'>
              {articleTrouve.stats_par_livraison.map((stat, index) => (
                <HStack key={index}>
                  <RotateSyncIcon
                    width='14px'
                    height='14px' />
                  <Tooltip
                    label={t('commande.pour_livraison_du', {date: dateEnTexte(date_de_livraison)})}
                  >
                    <Text color='blue.main'
                      fontSize='sm'
                      textDecoration='underline'>
                      {t('commande.deja_commande')} {stat.quantite} {t(`unite.${itemState.data.tolede.unite_de_commande_client}`, {count: stat.quantite})}
                    </Text>
                  </Tooltip>
                </HStack>
              ))}
            </VStack>
          );
        }
        return null;
      }}
      fallback={() => null}
      skeleton={() => null}
    />
  );
};
