import {PhoneIcon} from '@/components/svg/PhoneIcon';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {Box, Button, Menu, MenuButton, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useCapture} from '@/posthog/PosthogSetup';

export const ContactComponent = () => {
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const {t} = useTranslation();
  const capture = useCapture();

  return <Box display='flex'
    alignItems='center'>
    <Menu>
      <Link 
        onClick={() => {
          capture({name: 'client:go_contacts', props:{depuis:'banner'}});
        }}
        to={buildAssistanceRoute(routes.client.contacts)}
      >
        <MenuButton
          variant='unstyle'
          as={Button}
          backgroundColor='transparent'
          color='white'
          _hover={{bg:'transparent'}}
          fontWeight='500'
        >
          <Box display="flex"
            alignItems="center" >
            <Box marginRight='5px'>
              <PhoneIcon/>
            </Box>
            <Text fontSize='sm'>{t('header_band.contactez_nous')}</Text>
          </Box>
        </MenuButton>
      </Link>
    </Menu>
  </Box>;
};
