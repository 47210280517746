import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {fr as commonFR} from './common/fr';
import {en as commonEN} from './common/en';

export const resources = {
  fr: {
    common:commonFR,
  },
  en: {
    common:commonEN,
  },
} as const;

const availableLocales = ['fr', 'en'];
const availableNamespaces = ['common'];
const defaultLocale = 'fr';
const defaultNamespace = 'common';

export const i18nConfig = (locale: string) => {
  i18n
    .use(initReactI18next)
    .init({
      defaultNS: defaultNamespace,
      ns: availableNamespaces,
      resources,
      lng: locale,
      fallbackLng: defaultLocale,
      interpolation: {
        escapeValue: false 
      },
    });
};
  
export {availableLocales, availableNamespaces, defaultLocale, defaultNamespace};