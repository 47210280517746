import {Box, HStack, Image, VStack} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {ArticleDesc, ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {HaliRenderOne, useHalState} from '@synako/haligator';
import {imageOptimiseeH} from './optimisation';
import {useTranslation} from 'react-i18next';
import {Text} from '@chakra-ui/layout';
import {useClient} from '@/haligator-factory';

const ProduitMarcheTag: React.FC<{
  top: string;
  left: string;
}> = ({top, left}) => {
  const clientNav = useClient();
  const {t} = useTranslation('common');

  return (
    <HaliRenderOne
      nav={clientNav}
      render={({data: clientData}) => {
        if (clientData.affichage_produit_marche === 'active') {
          return (
            <HStack
              position="absolute"
              top={top}
              left={left}
              bg="blue.main_light"
              color="blue.main"
              fontSize="xs"
              fontWeight="bold"
              py="2px"
              px="8px"
              borderRadius="md"
            >
              <Text>{t('global.produit_marche')}</Text>
            </HStack>
          );
        }
        return null;
      }}
    />
  );
};

export const ImagePrincipaleDuProduit: React.FC<{articleState: State<ArticleDesc>, itemState:State<ItemCatalogueDesc>,}>
= ({articleState, itemState}) => {
  const imagePrincipale = useHalState(articleState.follow('medias').follow('image_principale'));
  const natureDuPrix = itemState.data.tolede.prix_par_livraison?.[0]?.type;

  return(
    <Box position="relative"
      p={{base: '10px 20px 0px 20px', md: ''}}>
      {/* Version mobile */}
      <VStack mt='10px'
        display={{base:'flex', md:'none'}}>
        {natureDuPrix === 'marche' &&
          <ProduitMarcheTag
            top="10px"
            left="30px" />}
        <Box maxHeight="274px">
          <Image src={imageOptimiseeH(imagePrincipale.data?.url, 274)}
            alt=""
            objectFit="cover"
            width="100%"
            maxHeight="274px"/>
        </Box>
      </VStack>
      {/* Version desktop */}
      <Box m="20px"
        display={{base:'none', md:'block'}}>
        <Box position="relative">
          {natureDuPrix === 'marche' &&
          <ProduitMarcheTag
            top="0px"
            left="10px" />}
          <HStack height="372px"
            width='100%'
            justifyContent='center'>
            <Image src={imageOptimiseeH(imagePrincipale.data?.url, 372)}
              alt=""
              objectFit="contain"
              maxH="372px" />
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};
