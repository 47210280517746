import {useMe} from '@/haligator-factory';
import {useHalState} from '@synako/haligator';
import {PostHog, PostHogProvider, usePostHog} from 'posthog-js/react';
import {PropsWithChildren, createContext, useCallback, useContext, useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {PosthogCustomEvt} from 'api-types/ffconnect';

/**
 * Permet de tracker les page-views avec le react-router
 */
export function PostHogPageView() {
  const location = useLocation();
  const capture = useCapture();

  // Track pageviews
  useEffect(() => {
    capture({
      name: '$pageview',
      props: {
        $current_url: window.location.href,
      }
    });
  }, [capture, location]);
  return <Outlet />;
}

/**
 * Permet d'identify dès qu'on a un user
 * - le distinct_id est piloté par le back et un feature flag
 * - aucun événement n'est envoyé avant grâde à person_profiles: 'identified_only',
 */
function PostHogIdentify() {
  const posthog = usePostHog();
  const {data: pref, loading:isLoading} = useHalState(useMe().follow('contact').follow('consentement-analytics'));
  useEffect(() => {
    const consent = pref?.consentement;
    if(consent === 'accepte') {
      if (posthog.has_opted_out_capturing()) posthog.clear_opt_in_out_capturing();
      posthog.opt_in_capturing();
    } else {
      posthog.opt_out_capturing();
    }
  }, [isLoading, posthog, pref]);
  const {data: me} = useHalState(useMe());
  useEffect(() => {
    if (me?.identify?.distinct_id) {
      posthog.identify(me.identify.distinct_id);
    }
    // Object.entries(me?.identify?.groups ?? {}).forEach( ([name, group_id]) => posthog.group(name, group_id));
  }, [posthog, me?.identify?.distinct_id]);
  return null;
}

/** Tout simple pour typer les events */
type Capture = (track: PosthogCustomEvt) => void;

const CaptureContext = createContext<{
  capture: Capture
}>({
  capture: () => {/*no op */}
});

const CaptureProvider: React.FC<PropsWithChildren>  = ({
  children,
}) => {
  // Le SDK gère bien le faire que même non initialisé, la variable existe.
  const posthog = usePostHog();
  const capture = useCallback<Capture>((event) => {
    // Mais on sait jamais, il suffit qu'un adblocker le mette à undefined !?
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    posthog?.capture(event.name, event.props);
  }, [posthog]);
  return <CaptureContext.Provider value={{capture}}>
    {children}
  </CaptureContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components
export function useCapture() {
  const value = useContext(CaptureContext);
  return value.capture;
}

export const CaptureSetup: React.FC<PropsWithChildren<{
  posthog: PostHog | undefined
}>> = ({
  posthog,
  children,
}) => {
  return <PostHogProvider client={posthog}>
    <PostHogIdentify />
    <CaptureProvider>{children}</CaptureProvider>
  </PostHogProvider>;
};