import {Link} from '@chakra-ui/react';
import {HalitrekErrorHandler, isProblem, isProblemOfType} from '@synako/halitrek';
import {useCallback} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useIsAssistanceMode} from './auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {useNavigate} from 'react-router-dom';
import {TextToastCommand} from 'ff-common/src/types/TextToastCommand';
import {useCapture} from '@/posthog/PosthogSetup';

export const useDefaultOnError = (
  textToast:TextToastCommand
)=>{
  const {t} = useTranslation();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const navigate = useNavigate();
  const capture = useCapture();

  const onError = useCallback<HalitrekErrorHandler>((error)=>{
    let message = error.message;
    console.warn(message);
    if (isProblem(error)) {
      message = error.body.detail ?? error.body.title ?? message;
    }
    if (isProblemOfType(error, 'validation_error')){
      const validation = (error.body as {validation?:{keyword:string}[]}).validation; 
      if (validation?.length == 1){
        message = t([`error.${validation[0].keyword}`, message]);
      }
    }
    //NOTE(Nathan) Le hook ne peut pour le moment pas être headless à cause du link Chakra et de l'icone
    textToast(
      <Trans 
        t={t}
        components={{
          Reload: <Link onClick={() => {location.reload();}} />,
          // href provoque un rechargement de la page, on utilise onClick et navigate
          Contact: <Link onClick={() => {
            capture({name: 'client:go_contacts', props:{depuis:'toast'}});
            navigate(buildAssistanceRoute(routes.client.contacts));
          }} />
        }}
      >
        {t([`error.${message}`, message])}
      </Trans>
      , {duration:4000, icon:'alert'}
    );
  }, [textToast, t, capture, navigate, buildAssistanceRoute]);

  return onError;
};
