import {Center, Text} from '@chakra-ui/react';
import {format} from 'date-fns';
import {fr} from 'date-fns/locale';

export const DateRangeCell: React.FC<{
  date: Date,
  isSelected: boolean,
  isInRange: boolean,
  isCurrentMonth: boolean,
  onClick: () => void
}> = ({
  date,
  isSelected,
  isInRange,
  isCurrentMonth,
  onClick
}) => {
  // Formatage de la date
  const day = format(date, 'd', {locale: fr});
  
  // Déterminer les styles en fonction de l'état
  const getBgColor = () => {
    if (isSelected) return 'blue.main';
    if (isInRange) return 'blue.main_transparency_10';
    return 'transparent';
  };

  const getTextColor = () => {
    if (isSelected) return 'white';
    if (!isCurrentMonth) return 'gray.400';
    return 'gray.800';
  };

  const getBorderColor = () => {
    if (isSelected) return 'blue.main';
    if (isInRange) return 'blue.main_transparency_20';
    return 'gray.200';
  };

  return (
    <Center 
      as="button"
      onClick={onClick}
      height="40px"
      width="100%"
      borderRadius="md"
      border="1px solid"
      borderColor={getBorderColor()}
      bg={getBgColor()}
      _hover={{
        bg: isSelected ? 'blue.600' : isInRange ? 'blue.main_transparency_20' : 'gray.100'
      }}
      transition="all 0.2s"
    >
      <Text 
        fontSize="sm" 
        fontWeight={isSelected || isInRange ? 'bold' : 'normal'}
        color={getTextColor()}
      >
        {day}
      </Text>
    </Center>
  );
};
