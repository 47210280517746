import {Grid, GridItem, HStack, Text} from '@chakra-ui/react';
import {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

export interface BaseCalendarGridProps {
  children: ReactNode;
  columnGap?: Record<string, string>;
}

/**
 * Composant de base pour une grille de calendrier
 * Fournit la structure et l'en-tête des jours de la semaine
 */
export const BaseCalendarGrid: React.FC<BaseCalendarGridProps> = ({
  children,
  columnGap = {base: '0', md: '2'}
}) => {
  const {t} = useTranslation();
  const weekdays = t('calendar.weekdays.short', {returnObjects: true}) as string[];
  
  return (
    <Grid 
      w='100%' 
      templateColumns="repeat(7, 1fr)" 
      gap='2' 
      columnGap={columnGap} 
      mt={{base:'5', md:'7'}} 
      justifyItems='stretch' 
      alignItems='stretch'
    >
      {/* Jours de la semaine */}
      {weekdays.map((day, index) => (
        <GridItem 
          as={HStack} 
          key={index} 
          alignItems='stretch' 
          justify='center' 
          paddingBottom={2} 
          color='#9E9E9E'
        >
          <Text>{day}</Text>
        </GridItem>
      ))}
      
      {/* Contenu de la grille (cellules de dates) */}
      {children}
    </Grid>
  );
};
