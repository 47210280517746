import {HStack, Text, VStack} from '@chakra-ui/layout';
import {Image, Spacer} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import {routes} from 'ff-common';
import {useTranslation} from 'react-i18next';
import {HaliRenderOne} from '@synako/haligator';
import {useCadencier} from '@/haligator-factory';
import {RightSingleArrow} from '@/components/svg/RightSingleArrowIcon';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {imageOptimisee} from '@/domains/mediatheque/optimisation';
import {useCapture} from '@/posthog/PosthogSetup';

export const CardButtonCadencier: React.FC<{
  context: 'accueil' | 'panier_vide',
  width: {base: string; md: string; lg:string} | string,
  backgroundColor?: string,
  borderWidth?:string
}> = ({
  context,
  width,
  backgroundColor = 'blue.main_light',
  borderWidth = '0px'
}) => {
  const navigate = useNavigate();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const capture = useCapture();

  const {t: tglobal} = useTranslation('common', {keyPrefix: 'global'});
  const {t} = useTranslation('common', {keyPrefix: 'panier'});

  return (<VStack position='relative'
    backgroundColor={backgroundColor}
    _hover={{
      opacity: '0.8'
    }}
    cursor='pointer'
    height='100%'
    borderRadius='10px'
    p='7'
    alignItems='start'
    flex='1'
    borderWidth={borderWidth}
    onClick={() => {
      capture({name: 'cadencier:go', props: {depuis: context}});
      navigate(buildAssistanceRoute(routes.client.cadencier));
    }}>
    <HStack><Text fontWeight='600'>{t('depuis_cadencier')}</Text><RightSingleArrow /></HStack>
    <HaliRenderOne nav={useCadencier()}
      render={({
        data
      }) => {
        const nombre_de_produit = data.nombre_articles;
        return <Text color='gray.dark'>{tglobal('produit', {
          count: nombre_de_produit
        })}</Text>;
      }} />
    <Spacer/>
    <Image src={imageOptimisee(`${import.meta.env['VITE_MEDIA_URL']}/cadencier_bouton.png`, 300)}
      position='absolute'
      bottom='0'
      right='0'
      w={width}/>
  </VStack>);
};
