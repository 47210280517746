import {Box, HStack, Spacer, Text, Tooltip, VStack} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {DisponibiliteArticleDesc, LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {LigneProblem} from 'api-types/ffconnect';
import {ArticleBlock} from '../ArticleBlock';
import {RemplacerErrorButton} from '../ErrorButton/RemplacerErrorButton';
import {DiffererErrorButton} from '../ErrorButton/DiffererErrorButton';
import {SupprimerErrorButton} from '../ErrorButton/SupprimerErrorButton';
import {AjusterErrorButton} from '../ErrorButton/AjusterErrorButton';
import {useLigneDePanierCommands, usePanierAffichageBoutonsErreurs} from 'ff-common';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {ModaleRupture} from '@/domains/cadencier/components/ModaleRupture';
import {useCapture} from '@/posthog/PosthogSetup';

export interface ProduitPanierErrorCommunProps {
  context: 'modale' | 'panier',
  ligneDePanier: State<LigneDePanierDesc>,
  idError: string,
  probleme?: LigneProblem,
  dispo?: State<DisponibiliteArticleDesc>,
  onClose?: () => void,
  submittingOrRefreshing?: boolean,
  multipleCommandeClient?: number,
}

export const ProduitPanierErrorCommun: React.FC<ProduitPanierErrorCommunProps> = (props) => {
  const {
    context,
    idError,
    probleme,
    dispo,
    ligneDePanier,
    onClose,
    submittingOrRefreshing,
    multipleCommandeClient,
  } = props;
  const textToast = useSimpleTextToast();
  const onError = useDefaultOnError(textToast);
  const capture = useCapture();
  const commands = useLigneDePanierCommands(ligneDePanier, textToast, onError, ModaleRupture);

  const captureBoutonErreur = (action:'remplacer' | 'differer' | 'ajuster' | 'supprimer')=>{
    capture({
      name: 'panier:problem_button',
      props: {
        action,
        problem: idError,
        depuis: context,
      }
    });
  };

  const articleBlock = <ArticleBlock
    code_article={ligneDePanier.data.code_article}
    dispo={ligneDePanier.follow('disponibilite_article')}
    libelleFallback={ligneDePanier.data.libelle}
  />;
  
  const {buttons, message} = usePanierAffichageBoutonsErreurs({
    multipleCommandeClient,
    ligneDePanier,
    probleme,
    dispo,
    tooltip:(label, message)=><Tooltip label={label}
      placement='top'>
      <Text>{message}</Text>
    </Tooltip>,
    RemplacerErrorButton: <RemplacerErrorButton commands={commands}
      onClose={()=>{
        captureBoutonErreur('remplacer');
      }}
      ligneDePanier={ligneDePanier}
      submittingOrRefreshing={submittingOrRefreshing}/>,
    DiffererErrorButton:  <DiffererErrorButton commands={commands}
      onClose={()=>{
        captureBoutonErreur('differer');
      }}
      ligneDePanier={ligneDePanier}
      submittingOrRefreshing={submittingOrRefreshing}/>,
    SupprimerErrorButton: <SupprimerErrorButton commands={commands}
      onClose={()=>{
        captureBoutonErreur('supprimer');
        onClose?.();
      }}
      code_article={ligneDePanier.data.code_article}
      submittingOrRefreshing={submittingOrRefreshing}/>,
    AjusterErrorButton:(quantiteMax)=>
      <AjusterErrorButton ligneDePanier={ligneDePanier}
        commands={commands}
        quantiteMaximale={quantiteMax}
        onClose={()=>{
          captureBoutonErreur('ajuster');
          onClose?.();
        }}
        submittingOrRefreshing={submittingOrRefreshing}/>
  });

  return (
    <VStack
      id={idError}
      p={{base:'0', md:'20px'}}
      borderTop="1px"
      borderColor="gray.light"
      _notLast={{borderBottom:'0'}}
      background="white"
      width="100%" 
      alignItems="stretch"
      position="relative"
      display="flex"
    >
      {/* Desktop */}
      <HStack 
        display={{base: 'none', md: 'flex'}}
        width='100%' 
        alignItems='start' 
        justify='end' 
        flexWrap='wrap' 
        gap='0'
        pt="0px" 
        flex='1' 
        opacity={submittingOrRefreshing ? 0.3 : 1}
      >

        {articleBlock}

        <Spacer/>

        <HStack justifyContent='end'
          alignItems='start'
          justifySelf='end'
          w="100%">
          <VStack alignItems="end">
            <HStack>
              <Box 
                p='1.5' 
                px='2.5' 
                borderRadius='8px' 
                backgroundColor='red.warning_bg' 
                color='red.fail'
                fontSize='xs'
              >
                {message}
              </Box>
            </HStack>
            <HStack 
              justifyContent={{base:'start', lg:'end'}}
            >
              {buttons}
            </HStack>
          </VStack>
        </HStack>
      </HStack>

      {/* Mobile */}
      <VStack
        display={{base: 'block', md: 'none'}}
        width='100%' 
        alignItems='start' 
        justify='end'
      >
        {articleBlock}
        <Spacer />
        <Box 
          p='1.5' 
          px='2.5' 
          borderRadius='8px' 
          backgroundColor='red.warning_bg' 
          color='red.fail'
          fontSize='xs'
          mb='1'
        >
          {message}
        </Box>
        <HStack justify={{base:'center', md:'space-between'}}
          sx={{flex:1}}
          mt='10px'>
          {buttons}
        </HStack>
      </VStack> 
    </VStack>
  );
};
