import {isDateEqual} from 'ff-common';
import {GridItem} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {LivraisonDesc} from 'api-types/ffconnect-hal';
import {useCallback, useMemo} from 'react';
import {CalendarCellInactive} from './components/CalendarCellInactive';
import {CalendarCellPanier} from './components/CalendarCellPanier';
import {useHalStates} from '@synako/haligator';
import {useLivraisons} from '@/haligator-factory';
import {BaseCalendarGrid} from '../../common/BaseCalendarGrid';
import {
  adjustDateTimezone,
  generateDateGrid
} from '../../common/calendarUtils';

export interface CalendarGridProps {
  selectedLivraison: State<LivraisonDesc>;
  onDateClick: (livraison: State<LivraisonDesc>) => void;
  monthOffset: number;
  dateDebut: Date | undefined;
}

export const CalendarGrid: React.FC<CalendarGridProps> = ({
  selectedLivraison,
  onDateClick, 
  monthOffset, 
  dateDebut
}) => {
  // Mémoriser la date sélectionnée pour éviter des recalculs inutiles
  const selectedDate = useMemo(() => {
    return new Date(selectedLivraison.data.tolede.date_de_livraison);
  }, [selectedLivraison.data.tolede.date_de_livraison]);
  
  // Utilise l'utilitaire pour générer la grille de dates
  const getDateGrid = useCallback(() => {
    return generateDateGrid(selectedDate, monthOffset, false);
  }, [selectedDate, monthOffset]);
  
  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));
  if (!livraisonsPossibles.states || livraisonsPossibles.error) {
    return null;
  }

  return (
    <BaseCalendarGrid columnGap={{base: '0', md: '3'}}>
      {getDateGrid().map((d, idx) => {
        // date notée en UTC mais avec la timezone appliquée 
        // -> décalage de 1 ou 2h si le système est en Europe/Paris
        const date = adjustDateTimezone(d);
        const isSelected = isDateEqual(date, selectedDate);
        
        const livraisonAssociee = livraisonsPossibles.states?.find((livraison) => {
          const dateLivraison = new Date(livraison.data.tolede.date_de_livraison);
          if (dateDebut && dateLivraison < dateDebut) {
            return false;
          }
          return isDateEqual(dateLivraison, date); 
        });

        const offsetDate = new Date(selectedDate);
        //Si la date selectionnée est > 28, Février est sauté par la prochaine opération
        offsetDate.setUTCDate(1); 
        offsetDate.setUTCMonth(offsetDate.getUTCMonth()+monthOffset);
        const offsetMonth = offsetDate.getUTCMonth();
        const dateMonth = date.getUTCMonth();

        if (dateMonth < offsetMonth || date.getUTCFullYear() < offsetDate.getUTCFullYear()) {
          return <GridItem key={idx}></GridItem>;
        }
        if (dateMonth > offsetMonth) return null;
        
        if (livraisonAssociee) { // la date sélectionnée correspond à une livraison
          return (
            <CalendarCellPanier
              key={idx}
              date={date}
              isSelected={isSelected}
              livraisonAssociee={livraisonAssociee}
              onDateClick={onDateClick}
            />
          );
        } else { // date non sélectionnable
          return <CalendarCellInactive 
            key={idx} 
            date={date} 
          />;
        }
      })}
    </BaseCalendarGrid>
  );
};
