import {Button, ButtonProps, Menu, MenuButton, MenuList, MenuProps} from '@chakra-ui/react';
import {ChevronDownIcon} from '@chakra-ui/icons';
import {ReactNode} from 'react';

// Style commun pour les boutons de filtre
const filterButtonStyle = {
  bg: 'gray.50',
  color: 'gray.dark',
  fontWeight: 'normal',
  // h: '32px',
  p: '4',
  fontSize: 'sm',
  _hover: {
    bg: 'gray.100'
  }
} as const;

interface FilterButtonProps extends Omit<ButtonProps, 'rightIcon'> {
  label: string;
  menuProps?: Omit<MenuProps, 'children'>;
  chevronColor?: string;
  children?: ReactNode;
}

// Composant réutilisable pour les boutons de filtre
export const FilterButton = ({
  label, menuProps, children, chevronColor='gray.600', ...buttonProps
}: FilterButtonProps) => {
  return (
    <Menu {...menuProps}>
      <MenuButton
        as={Button}
        
        {...filterButtonStyle}
        {...buttonProps}
        rightIcon={<ChevronDownIcon color={chevronColor}
          w={3}
          h={3} />}
      >
        {label}
      </MenuButton>
      <MenuList>
        {children}
      </MenuList>
    </Menu>
  );
};