import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import {VStack} from '@chakra-ui/layout';
import {Box, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import {HaliRenderOne} from '@synako/haligator';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {useTranslation} from 'react-i18next';
import {ArticleBlock} from '../panier/PanierFound/ArticleBlock';
import {dateAvecSlashes} from 'ff-common';

export interface HistoriqueAchatModalProps {
  itemState: State<ItemCatalogueDesc>;
  dateDebut?: string;
  dateFin?: string;
}

export const HistoriqueAchatModal = NiceModal.create(({
  itemState,
  dateDebut,
  dateFin
}: HistoriqueAchatModalProps) => {
  const {t} = useTranslation();
  const modal = NiceModal.useModal();

  // Récupèration des informations de l'article
  const code_article = itemState.data.tolede.code_article;
  const unite = itemState.data.tolede.unite_de_commande_client;

  return (
    <ModaleLayout
      visible={modal.visible}
      hide={() => modal.hide()}
      titre={t('historique_livraison.title')}
    >
      <VStack align="start"
        mb={4}
        spacing={1}>
        <ArticleBlock
          code_article={code_article}
        />
      </VStack>

      <HaliRenderOne
        nav={itemState.follow('article_statistiques', {debut: dateDebut, fin: dateFin})}
        render={({data}) => {
          // Trier l'historique par date (du plus récent au plus ancien)
          const statsTriees = [...data.tolede.stats_par_livraison].sort((a, b) =>
            new Date(b.livraison_du).getTime() - new Date(a.livraison_du).getTime()
          );

          return (
            <TableContainer maxHeight="400px"
              overflowY="auto">
              <Table variant="unstyled"
                size="md"
                width="100%">
                {/* En-tête du tableau */}
                <Thead>
                  <Tr backgroundColor="gray.bg"
                    borderRadius="8px">
                    <Th 
                      color="gray.600"
                      fontWeight="medium"
                      fontSize="xs"
                      py={2}
                      width="50%"
                    >
                      {t('global.date')}
                    </Th>
                    <Th 
                      isNumeric
                      color="gray.600"
                      fontWeight="medium"
                      fontSize="xs"
                      py={2}
                      width="50%"
                    >
                      {t('global.quantite')}
                    </Th>
                  </Tr>
                </Thead>
                
                {/* Espacement entre l'en-tête et le corps */}
                <Tbody>
                  <Tr height="10px">
                    <Td colSpan={2}
                      padding={0}></Td>
                  </Tr>
                
                  {statsTriees.map((stat, index) => (
                    <Tr key={`${stat.livraison_du}-${index}`}
                      border="1px solid" 
                      borderColor="gray.100"
                      fontSize="sm">
                      <Td py={2.5}
                        width="50%">
                        {dateAvecSlashes(stat.livraison_du)}
                      </Td>
                      <Td isNumeric
                        py={2.5}
                        width="50%">
                        {stat.quantite} {t(`unite.${unite}`, {count: stat.quantite})}
                      </Td>
                    </Tr>
                  ))}
                  {statsTriees.length === 0 && (
                    <Tr>
                      <Td colSpan={2}
                        textAlign="center">
                        {t('historique_livraison.aucun_historique')}
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          );
        }}
        skeleton={() => (
          <Box display="flex"
            justifyContent="center"
            py={6}>
            <Spinner size="lg" />
          </Box>
        )}
        fallback={() => (
          <Box textAlign="center"
            py={4}>
            <Text>{t('historique_livraison.aucun_historique')}</Text>
          </Box>
        )}
      />
    </ModaleLayout>
  );
});
