import {HStack, StyleProps, VStack} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {Dispatch, SetStateAction, useEffect} from 'react';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc, LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {UniteQuantite} from 'api-types/ffconnect';
import {TooltipAsToast} from '@/components/TooltipAsToast/TooltipAsToast';
import {BuyProductPanierInput} from '../panier/BuyProduct/BuyProductPanierInput';
import {PrecommandeButton} from '../panier/BuyProduct/PrecommandeButton';
import {AjouterButton} from '../panier/BuyProduct/AjouterButton';
import {AjoutListeBouton} from '../cadencier/components/AjoutListeBouton';
import {produitBuyButtonDisplay, useLigneDePanierCommands} from 'ff-common';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {ModaleRupture} from '../cadencier/components/ModaleRupture';
import {ContextsComposant} from 'ff-common/src/types/ContextsComposant';

export const ProduitBuyButton: React.FC<{
  lignePanier?:State<LigneDePanierDesc>,
  itemState: State<ItemCatalogueDesc>,
  commande_en: UniteQuantite,
  somethingSubmitting?: boolean,
  afficher_bouton_liste?:boolean
  quantiteDisplay:number|undefined,
  context: ContextsComposant,
  setQuantiteDisplay:(quantite:number|undefined)=>void,
  tooltip:string|undefined,
  setTooltip:Dispatch<SetStateAction<string | undefined>>
  texte?:string
} & StyleProps> 
= ({
  lignePanier,
  itemState,
  commande_en, 
  somethingSubmitting=false,
  afficher_bouton_liste,
  quantiteDisplay,
  context,
  setQuantiteDisplay,
  tooltip,
  setTooltip,
  texte,
  ...props
}) => {
  const {t} = useTranslation();

  // console.log('RERENDER ProduitBuyButton');

  useEffect(() => {
    setQuantiteDisplay(lignePanier?.data.quantite);
  }, [lignePanier, setQuantiteDisplay]);

  const textToast = useSimpleTextToast();
  const onError = useDefaultOnError(textToast);
  const multipleCommandeClient = itemState.data.tolede.multiple_de_commande_client;
  const {changerLaQuantite} = useLigneDePanierCommands(lignePanier, textToast, onError, ModaleRupture, multipleCommandeClient);
  const precommande = itemState.data.tolede.precommande;

  const QuantiteInputBlock = (quantiteDisplay:number)=><BuyProductPanierInput
    quantite={quantiteDisplay}
    itemCatalogue={itemState}
    commande_en={commande_en}
    somethingSubmitting={somethingSubmitting || changerLaQuantite.isSubmitting}
    context={context}
    onQuantite={(nouvelleQuantite) => {
      setQuantiteDisplay(nouvelleQuantite);
      changerLaQuantite.submit({
        quantite: nouvelleQuantite
      });
    }}
  />;

  const BuyButtonBlock = <HStack w='100%'>
    {precommande
      ? <PrecommandeButton itemState={itemState}
        t={t}/>
      : <AjouterButton
        itemCatalogue={itemState.data}
        commandeEnColis={commande_en==='colis'} 
        context={context}
        t={t}
        texte={texte}
      />
    }
    {afficher_bouton_liste && <AjoutListeBouton itemState={itemState}/>}
  </HStack>;

  return <VStack justifyContent={'end'}
    mt='15px'
    w={{base:'100%', lg:'auto'}}
    minW='300px'
    position="relative"
    {...props}>
    <TooltipAsToast tooltip={tooltip}
      setTooltip={setTooltip}
      bottom="0em"/>
    {produitBuyButtonDisplay(
      {quantiteDisplay, QuantiteInputBlock, BuyButtonBlock}
    )}
  </VStack>;    
};
