import {Box, HStack, Text, VStack} from '@chakra-ui/layout';
import {Grid, GridItem, Link, Skeleton, Tooltip} from '@chakra-ui/react';
import {keyframes} from '@emotion/react';
import {PanierListeLivraison} from './controllers/PanierListeLivraison';
import {Trans, useTranslation} from 'react-i18next';
import {PanierHeader} from './PanierFound/PanierHeader';
import {PanierContent} from './PanierFound/PanierContent';
import {PanierRecapitulatif} from './PanierRecapitulatif/PanierRecapitulatif';
import {HaliRenderMany, useHalState} from '@synako/haligator';
import {ulid} from 'ulid';
import {differenceInDays, formatDistanceToNow} from 'date-fns';
import {usePanierCommands} from 'ff-common';
import {Navigable, isLinkNotFound} from '@synako/halitrek';
import {PanierDesc} from 'api-types/ffconnect-hal';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {useNavigate} from 'react-router-dom';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {useNavigatePanier} from '@/hooks/panier/useNavigatePanier';
import {useEffect, useState} from 'react';
import {CommandeProblem, LigneProblem} from 'api-types/ffconnect';
import {useCapture} from '@/posthog/PosthogSetup';

export const PanierFound: React.FC<{
  panier: Navigable<PanierDesc>,
}> = ({
  panier: panierNav
}) => {
  const {t} = useTranslation();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const navigate = useNavigate();
  const textToast = useSimpleTextToast();
  const onError = useDefaultOnError(textToast);
  const navigatePanier = useNavigatePanier();
  const capture = useCapture();

  const panierHook = useHalState(panierNav);
  const dateCutOff = panierHook.state?.data.livraison_tolede.cut_off_de_preparation;

  const panierWithCommands = usePanierCommands(panierNav, textToast, onError, navigatePanier);
  
  const validerOptions = useHalState(panierNav.follow('valider_panier_options'));
  const validationRefresh = validerOptions.loading || validerOptions.refreshing;
  // Nouveaux states pour les erreurs de ligne et commande
  const [commandeProblems, setCommandeProblems] = useState<CommandeProblem[]>([]);
  const [ligneProblems, setLigneProblems] = useState<LigneProblem[]>([]);

  // Mise à jour depuis validerOptions
  useEffect(() => {
    setCommandeProblems(validerOptions.data?.commande_problems ?? []);
    setLigneProblems(validerOptions.data?.lignes_problems ?? []);
  }, [validerOptions.data]);

  const bgFade = keyframes({
    from: {opacity: 0.8},
    to: {opacity: 0.5},
  });
  
  const panier = panierHook.data;
  // console.log(validerOptions.error);
  if (!panier) {
    return null;
  }

  return (
    <Box
      position={{base:'fixed', md:'relative'}}
      zIndex={{base:'overlay', md:'initial'}}
      w='100%' 
      top='0' 
      bottom='0'
      overflowY={{base: 'scroll', lg: 'unset'}} 
      overflowX={{base: 'hidden', lg: 'unset'}}
    >
      <VStack 
        bg='white' 
        py={{base: '0', md: '2'}} 
        px={{base: '0', md: '4', xl: '20'}} 
        alignItems='start' 
        overflow={'hidden'}
      >
        <PanierListeLivraison />
      </VStack>
      {dateCutOff && (
        <HStack 
          display={{base: 'flex', md: 'none'}} 
          bg='lblue.50' 
          justify='center' 
          p='1' 
          textColor='blue.main'
        >
          {
            differenceInDays(dateCutOff, new Date()) > 2 ?
              <Text 
                textAlign='center'
              > 
                {t('panier.validez.plus_de_2_jours')}
              </Text>
              :
              <Text 
                textAlign='center'
              > 
                <Trans t={t}>{t('panier.validez.moins_de_2_jours', {date: formatDistanceToNow(dateCutOff)})}</Trans>
              </Text>
          }
        </HStack>      
      )}

      <Box bg='#F5F5F5'
        py={{base:'1', md:'5'}}
        px={{base: '0', md: '2', xl: '20'}}
        position='relative'>
        <Grid templateColumns={{base: '1fr', lg: 'repeat(7, 1fr)'}}
          gap={{base:'5', md:'8'}}
          mb='10'
          position="relative">
          <PanierHeader 
            viderCommand={panierWithCommands.vider}
            nombre_de_lignes={panier.nombre_de_lignes}
            tourneeNav={panierNav.follow('tournee')}
          />

          <GridItem colSpan={{base: 1, lg: 5}}
            position="relative">
            {commandeProblems.length > 0 && (
              <VStack alignItems='stretch'>
                {commandeProblems.map((problem) => {
                  const color = problem.niveau === 'bloquant' ? 'red.warning' : 'blue.dark';
                  const message = problem.code === 'generique' ?
                    <Tooltip label={`${problem.message} (${problem.code})`}
                      placement='top'>
                      <Text>{t('error.generique')}</Text>
                    </Tooltip>
                    :
                    <Trans
                      t={t}
                      components={{
                        // href provoque un rechargement de la page, on utilise onClick et navigate
                        Contact: <Link textDecoration="underline"
                          onClick={() => {
                            capture({name: 'client:go_contacts', props:{depuis:'panier'}});
                            navigate(buildAssistanceRoute(routes.client.contacts));
                          }} />
                      }}
                    >{t([`error.${problem.code}`, problem.message])}</Trans>;
                  return (
                    <Box 
                      key={ulid()}
                      bg={color} 
                      color='white' 
                      p ='3' 
                      my='2' 
                    >
                      {message}
                    </Box>
                  );
                })}
              </VStack>
            )}
            {validationRefresh && (
              <Skeleton
                p ='2.5' 
                my='2' 
                textColor='black.text' 
                style={{animation: `1s linear infinite alternate ${bgFade}`}}
                position={{base:'relative', md:'absolute'}}
                top={{base:'0', md:'-50px'}}
                width="100%"
              >
                {t('panier.nous_validons')}
              </Skeleton>
            )}
            {validerOptions.error
              // La commande vider invalide la livraison, le temps que PanierView s'en rende compte, ça évite d'afficher l'erreur
              && !isLinkNotFound(validerOptions.error)
              && <Box 
                bg='red.warning' 
                color='white' 
                p ='3' 
                my='2' 
              >
                <Trans t={t}>{t('error.controle_panier')}</Trans>
              </Box>
            }
            <HaliRenderMany 
              many={panierNav.followAll('lignes_de_panier')} 
              skeleton={() => Array.from({length: panierHook.data?.nombre_de_lignes ?? 5}, (_, idx)=><Skeleton w='100%'
                h='8em'
                mb='2'
                key={idx}/>)}
              render={({states}) => (
                <PanierContent 
                  date_de_livraison={panierHook.data!.livraison_tolede.date_de_livraison}
                  lignesDePanier={states}
                  problemesLignes={ligneProblems}
                  validationRefresh={validationRefresh}
                />
              )} />
          </GridItem>
          <GridItem colSpan={{base: 1, lg: 2}}>
            <PanierRecapitulatif
              panier={panier}
              panierWithCommands={panierWithCommands}
              validerOptions={validerOptions}
              setCommandeProblems={setCommandeProblems}
              setLigneProblems={setLigneProblems}
            />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};
