import {isDateEqual} from 'ff-common';

/**
 * Génère une grille de dates pour un mois donné
 * @param referenceDate Date de référence
 * @param monthOffset Décalage de mois par rapport à la date de référence
 * @param includeAdjacentMonthDays Inclure les jours des mois adjacents pour compléter les semaines
 * @returns Tableau de dates représentant le mois
 */
export const generateDateGrid = (
  referenceDate: Date,
  monthOffset: number,
  includeAdjacentMonthDays = true
): Date[] => {
  const selectedMonth = referenceDate.getMonth() + monthOffset;
  const selectedYear = includeAdjacentMonthDays 
    ? referenceDate.getFullYear() 
    : referenceDate.getUTCFullYear();
    
  const daysInMonth = includeAdjacentMonthDays 
    ? new Date(selectedYear, selectedMonth + 1, 0).getDate()
    : 31;
    
  const startDate = new Date(selectedYear, selectedMonth, 1);
  const startDay = startDate.getDay();
  const dateGrid: Date[] = [];

  if (includeAdjacentMonthDays) {
    // Jours du mois précédent pour remplir la première semaine
    for (let i = 0; i < startDay; i++) {
      const day = new Date(selectedYear, selectedMonth, -startDay + i + 1);
      dateGrid.push(day);
    }

    // Jours du mois actuel
    for (let i = 1; i <= daysInMonth; i++) {
      const day = new Date(selectedYear, selectedMonth, i);
      dateGrid.push(day);
    }

    // Jours du mois suivant pour compléter la dernière semaine
    const endDay = new Date(selectedYear, selectedMonth, daysInMonth).getDay();
    for (let i = 1; i < 7 - endDay; i++) {
      const day = new Date(selectedYear, selectedMonth + 1, i);
      dateGrid.push(day);
    }
  } else {
    // Génération simple sans jours adjacents (comme dans CalendarGrid)
    for (let i = 0; i <= daysInMonth + startDay; i++) {
      const currentDate = new Date(selectedYear, selectedMonth, i - startDay + 2);
      dateGrid.push(currentDate);
    }
  }

  return dateGrid;
};

/**
 * Détermine si une date appartient au mois affiché
 * @param date Date à vérifier
 * @param referenceDate Date de référence
 * @param monthOffset Décalage de mois
 * @returns true si la date est dans le mois affiché
 */
export const isDateInCurrentMonth = (
  date: Date,
  referenceDate: Date,
  monthOffset: number
): boolean => {
  const refMonth = new Date(referenceDate);
  refMonth.setMonth(refMonth.getMonth() + monthOffset);
  
  return date.getMonth() === refMonth.getMonth() && 
         date.getFullYear() === refMonth.getFullYear();
};

/**
 * Détermine si une date est dans une plage de dates
 * @param date Date à vérifier
 * @param startDate Date de début de la plage
 * @param endDate Date de fin de la plage
 * @returns true si la date est dans la plage
 */
export const isDateInRange = (
  date: Date,
  startDate: Date | null,
  endDate: Date | null
): boolean => {
  if (!startDate || !endDate) return false;
  return date > startDate && date < endDate;
};

/**
 * Détermine si une date est sélectionnée (début ou fin de plage)
 * @param date Date à vérifier
 * @param startDate Date de début
 * @param endDate Date de fin optionnelle
 * @returns true si la date est sélectionnée
 */
export const isDateSelected = (
  date: Date,
  startDate: Date | null,
  endDate: Date | null = null
): boolean => {
  if (!startDate) return false;
  if (isDateEqual(date, startDate)) return true;
  if (endDate && isDateEqual(date, endDate)) return true;
  return false;
};

/**
 * Décalage de date pour corriger les problèmes de timezone
 * @param date Date à ajuster
 * @returns Date ajustée
 */
export const adjustDateTimezone = (date: Date): Date => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};
