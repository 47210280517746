import {
  Box, 
  Button, 
  Collapse, 
  Flex, 
  HStack, 
  Link, 
  Skeleton, 
  Spinner, 
  Text,
  Tooltip,
  VStack,
  useDisclosure
} from '@chakra-ui/react';
import {ArticleBlock} from '@/domains/panier/PanierFound/ArticleBlock';
import isEqual from 'react-fast-compare';
import {HaliRenderOne, useHalCollection, useHalState, useHalStates} from '@synako/haligator';
import {Navigable, Problem, State} from '@synako/halitrek';
import {ArticleDesc, ItemCatalogueDesc, ListeDesc} from 'api-types/ffconnect-hal';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useApi, useCadencier, useClient, useListes} from '../../../../haligator-factory';
import {LigneProduitLayout} from '../LigneProduitLayout';
import {AddIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons';
import {SwitchIcon} from '@/components/svg/SwitchIcon';
import NiceModal from '@ebay/nice-modal-react';
import {ModaleRemplacementCatalogue} from '@/domains/panier/components/ModaleRemplacementCatalogue';
import {useListItems} from '../../context/ListItemsContext';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {Periode} from '../ProduitsParSaison';
import {PrixBlockUnitaire} from '@/domains/cadencier/components/PrixBlockUnitaire';
import {HistoriqueAchatButton} from '@/domains/ligneProduit/HistoriqueAchatButton';
import {useCapture} from '@/posthog/PosthogSetup';
import {ArticleStatistiques} from 'api-types/tolede';
import {TriSelection} from 'api-types/ffconnect';
import {useNavigate} from 'react-router-dom';
import {routes, toDecimalFr} from 'ff-common';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {DotLoaderSlow} from '@/components/Loading/DotLoaderSlow';
import {ListItemsProvider} from '../../context/ListItemsContext';

/**
 * Composant pour une section de produits saisonniers avec en-tête pliable
 */
interface LigneProduitsSaisonniersSection {
  libelle_web: string;
  categorie_id: string;
  articles_tri: {code: string}[];
  statItems: ArticleStatistiques[];
  selectedListe: Navigable<ListeDesc> | undefined;
  refreshing: boolean;
}

const LigneProduitsSaisonniersSection = 
React.memo<LigneProduitsSaisonniersSection>(({
  libelle_web,
  categorie_id,
  articles_tri,
  statItems,
  refreshing
}) => {
  const {isOpen, onToggle} = useDisclosure();
  
  const productItems = useMemo(() => {
    return articles_tri.flatMap(produit => {
      // Find matching items
      const matchingStats = statItems.filter(stat => 
        stat.code_article === produit.code
      );
      
      if (matchingStats.length) {
        return matchingStats.map(stat => (
          <LigneProduitSaisonnier 
            key={`${produit.code}-${stat.code_article}`}
            articleStat={stat}
            refreshing={refreshing}
          />
        ));
      }
      
      // Placeholder si pas de correspondance
      return [
        <LigneProduitLayout key={produit.code}
          precommande={false}>
          <Skeleton height="50px"
            w="100%" />
        </LigneProduitLayout>
      ];
    });
  }, [articles_tri, statItems, refreshing]);
    
  return (
    <VStack
      alignItems="stretch"
      width="100%"
      mt="1"
      opacity={'100%'}
      id={categorie_id}>
      <HStack margin={{base: '2', lg: '0'}}>
        <Text fontWeight="600">{libelle_web}</Text>
        <Box textColor="gray.dark"
          fontWeight="600"
          fontSize="xs"
          bg="gray.light"
          p="1"
          borderRadius="8px"
          minW={'2em'}
          aspectRatio={1}
          textAlign="center">
          {articles_tri.length}
        </Box>
        <Button variant="ghost"
          p="0"
          onClick={onToggle}>{!isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</Button>
      </HStack>
      <Collapse in={!isOpen}>
        <Box marginBottom="1px">
          {productItems}
        </Box>
      </Collapse>
    </VStack>
  );
}, (prevProps, nextProps) => {
  // Check if the data that would cause expensive recomputation has changed
  return isEqual(prevProps.articles_tri, nextProps.articles_tri) &&
         isEqual(prevProps.statItems, nextProps.statItems) &&
         prevProps.refreshing === nextProps.refreshing;
});

/**
 * Composant principal pour l'affichage des lists saisonnières
 */
export const ProduitsSaisonniers: React.FC<{
  selectedPeriod: Periode;
  selectedListe: Navigable<ListeDesc> | undefined;
  setItemCount: (count: number) => void;
  setSomethingRefreshing: (refreshing: boolean) => void;
}> = ({selectedPeriod, selectedListe, setItemCount, setSomethingRefreshing}) => {
  const {t} = useTranslation();
  const listesHook = useHalCollection(useListes());

  const cadencierHook = useHalState(useCadencier());
  const {state: selectedListeState} = useHalState(selectedListe);
  const isCadencier = selectedListe === undefined;
  
  const textToast = useSimpleTextToast();
  const onError = useDefaultOnError(textToast);
  const navigate = useNavigate();
  const capture = useCapture();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  
  const liste_saisonniere = useHalState(useClient().follow('commande_statistiques_tri', 
    {
      debut:selectedPeriod.date_debut,
      fin:selectedPeriod.date_fin,
    }));
  
  useEffect(() => {
    if (liste_saisonniere.data)
      setItemCount(liste_saisonniere.data.nombre_articles ?? 0);
  }
  , [liste_saisonniere.data, setItemCount]);

  useEffect(() => {
    setSomethingRefreshing(liste_saisonniere.loading || liste_saisonniere.refreshing);
  }
  , [setSomethingRefreshing, liste_saisonniere.refreshing, liste_saisonniere.loading]);

  const {state: statState, loading: statLoading} = useHalState(liste_saisonniere.navigable.follow('commande_statistiques'));
  // Utiliser la première liste par défaut si on n'a pas sélectionné de liste
  const cadencierItems = useHalStates(cadencierHook.navigable.followAll('articles'));
  const selectedListeItems = useHalStates(selectedListe?.followAll('selection'));
  
  // Référence pour suivre la dernière liste valide
  const lastValidProduitsListeRef = useRef<State<ArticleDesc>[] | null>(null);
  const {states: produits_liste} = useMemo(() => {
    const currentValue = isCadencier 
      ? cadencierItems
      : selectedListeItems;
    
    if (currentValue.states) {
      lastValidProduitsListeRef.current = currentValue.states;
    }
    
    if (listesHook.refreshing && (!currentValue.states) && lastValidProduitsListeRef.current) {
      return {states: lastValidProduitsListeRef.current};
    }
  
    return currentValue;
  }, [isCadencier, cadencierItems, selectedListeItems, listesHook.refreshing]);
  
  // Si aucun mois sélectionné ou "tout" sélectionné, afficher tous les produits
  // const isAllMonths = selectedPeriod.date_debut === undefined && selectedPeriod.date_fin === undefined;

  // Fonction pour ajouter à la liste
  const handleAddToList = useCallback(async (item_catalogue:State<ItemCatalogueDesc>, setIsAdding:(adding:boolean)=>void) => {
    if (!isCadencier && !selectedListeState) {
      textToast(t('liste.aucune_liste'));
      return;
    }
    
    // Utiliser la liste sélectionnée
    
    setIsAdding(true);
    try {
      // Appel à l'API pour ajouter l'article à la liste
      if (isCadencier) {
        await item_catalogue.submitCommand('c:ajouter-au-cadencier', {
          code_article: item_catalogue.data.tolede.code_article
        });
        // Afficher un message de succès
        textToast([
          <Trans key="toast-text"
            t={t}>
            {t('toast.ajout_cadencier')}
          </Trans>,
          <Link
            key="toast-button"
            fontWeight='bold'
            textDecorationLine='underline'
            onClick={()=>{navigate(buildAssistanceRoute(routes.client.cadencier));}}>
            {t('erreur404.voir_cadencier')}
          </Link>
        ]);

        capture({
          name: 'cadencier:ajouter',
          props: {
            depuis: 'liste_saisonniere',
          }
        });
      }
      else {
        if (!selectedListeState) {
          textToast(t('liste.aucune_liste'));
          return;
        }
        await selectedListe.submitCommand('c:ajouter-article-a-une-liste', {
          liste_id: selectedListeState.data.id,
          code_article: item_catalogue.data.tolede.code_article
        });

        // Afficher un message de succès
        const code_liste = selectedListeState.data.code_liste;
        textToast([
          <Trans key="toast-text"
            t={t}>
            {t('liste.produit_ajoute', {nom: selectedListeState.data.nom_liste})}
          </Trans>,
          <Link 
            key="toast-button"
            fontWeight='bold'
            textDecorationLine='underline'
            onClick={()=>{navigate(buildAssistanceRoute(routes.client.listes, {code_liste}));}}>
            {t('toast.voir_liste')}
          </Link>
        ]);
        // Enregistrer l'événement dans Posthog
        capture({
          name: 'liste:ajouter',
          props: {
            code: item_catalogue.data.tolede.code_article,
            liste: selectedListeState.data.id,
            depuis: 'liste_saisonniere',
          }
        });
      }
    } catch (error) {
      onError(error as Problem);
    } finally {
      setIsAdding(false);
    }
  }, [isCadencier, selectedListeState, textToast, t, capture, navigate, buildAssistanceRoute, selectedListe, onError]);
  
  if (statLoading){
    return <HStack w='100%'
      justifyContent='center'>
      <DotLoaderSlow />
    </HStack>;
  }
  if (statState?.data.par_article.length === 0 || !liste_saisonniere.data?.tri_selection) {
    return (
      <>
        <Box p={5}
          textAlign="center"
          bg="white">
          <Text>{t('liste.aucun_produit')}</Text>
        </Box>
      </>
    );
  }

  // Filtrer par mois si nécessaire (à implémenter selon la logique métier)
  const tri_selection_produits: TriSelection = liste_saisonniere.data.tri_selection;

  return (
    <ListItemsProvider produits_liste={produits_liste}
      handleAddToList={(isCadencier ? !!cadencierHook.state : !!selectedListeState) ? handleAddToList : undefined}>
      <VStack alignItems="stretch"
        width="100%"
        spacing={4}>
        <VStack alignItems="stretch"
          width="100%"
          spacing={4}>
          {tri_selection_produits.map(({libelle_web, categorie_id, articles:articles_tri}) => (
            <LigneProduitsSaisonniersSection
              key={categorie_id}
              libelle_web={libelle_web}
              categorie_id={categorie_id}
              articles_tri={articles_tri}
              statItems={statState?.data.par_article ?? []}
              selectedListe={selectedListe}
              refreshing={listesHook.refreshing}
            />
          ))}
        </VStack>
      </VStack>
    </ListItemsProvider>
  );
};

/**
 * Charge la ligne de produit
 */
interface LigneProduitSaisonnierProps {
  articleStat: ArticleStatistiques;
  refreshing?: boolean;
}

export const LigneProduitSaisonnier: React.FC<LigneProduitSaisonnierProps> 
= React.memo(({articleStat, refreshing}) => {
  const {t} = useTranslation();
  const client = useClient();
  const code_article = articleStat.code_article;
  const articleNav = useApi().follow('get-article', {code_article});
  const itemNav = client.follow('go:item_catalogue', {code_article});
  
  return (
    <HaliRenderOne 
      nav={articleNav}
      fallback={()=>{
        //L'article est normalement toujours présent dans la base article
        //(puisqu'il est dans le tri)
        return <ProduitSaisonnierItemFallback 
          articleStat={articleStat}
          message={t('error.article_introuvable')}
          refreshing={refreshing} />;
      }}
      render={({state:articleState}) => {
        return <HaliRenderOne 
          
          nav={itemNav} 
          fallback={()=>{
            return <ProduitSaisonnierItemFallback 
              articleStat={articleStat}
              articleState={articleState}
              message={t('error.item_catalogue_introuvable')}
              refreshing={refreshing} />;
          }}
          render={({state:itemState}) => {
            return <ProduitSaisonnierItem
              itemState={itemState}
              articleState={articleState}
              articleStat={articleStat}
              refreshing={refreshing}
            />;
          }}
        />;
      }}
    />
  );
});

export const ProduitSaisonnierItemFallback: React.FC<{
  articleStat: ArticleStatistiques;
  articleState?: State<ArticleDesc>;
  itemState?: State<ItemCatalogueDesc>;
  message?: string;
  refreshing?: boolean;
}> = ({articleStat, articleState, message}) => {
  const {code_article, libelle} = articleStat;
  const client = useClient();
  const {t} = useTranslation();
  const {isItemInList, handleAddToList} = useListItems();
  const [isAdding, setIsAdding] = useState(false);

  // Fonction pour ouvrir la modale de recherche d'alternatives
  const onFindAlternative = () => {
    // Création d'un objet article minimal pour la modale
    if (!articleState) return;
    const articleData = articleState.data;

    // Fonction pour ajouter l'article de remplacement à la liste
    const onListAdd = async (code_article: string) => {
      if (!handleAddToList) return;
      
      // Récupérer l'item catalogue pour le code article sélectionné
      const navItem = client.follow('go:item_catalogue', {code_article});
      const itemState = await navItem.get();
      
      // Utiliser la fonction ajouter_liste existante
      handleAddToList(itemState, setIsAdding);
    };

    // Afficher la modale
    NiceModal.show(ModaleRemplacementCatalogue, {
      articleData, 
      mode: 'add-to-list',
      onListAdd,
      isDisabled: isItemInList,
    });
  };

  return (
    <LigneProduitLayout precommande={false}>
      <Flex direction={{base: 'column', md: 'row'}}
        width="100%">
        <Box flex={1}>
          <ArticleBlock
            code_article={code_article}
            libelleFallback={libelle}
          />
        </Box>
        <HStack
          ml='auto'
          flex={1}
          spacing={3}
          align={{base: 'stretch', md: 'center'}}
        >
          <Text
            mx='auto'
            textAlign={'center'}
          >{message}</Text>
          
          {handleAddToList && articleState && (
            <Button
              leftIcon={isAdding ? <Spinner/> : <SwitchIcon color='blue.main'/>}
              variant='outline'
              colorScheme='blue'
              _hover={{bg: 'blue.main_transparency_30'}}
              w={{base: '100%', md: '13em'}}
              onClick={onFindAlternative}
            >
              {isAdding ? '' : t('liste.trouver_alternative')}
            </Button>
          )}
        </HStack>
      </Flex>
    </LigneProduitLayout>
  );
};

/**
 * Affiche un produit
 */
interface ProduitSaisonnierItemProps {
  itemState: State<ItemCatalogueDesc>;
  articleState: State<ArticleDesc>;
  articleStat: ArticleStatistiques;
  refreshing?: boolean;
}

const ProduitSaisonnierItem: React.FC<ProduitSaisonnierItemProps> 
= React.memo(({itemState, articleState, articleStat, refreshing}) => {
  const {t} = useTranslation();
  const [isAdding, setIsAdding] = useState(false);
  // État persistant pour suivre si l'article a été récemment ajouté (pendant le rafraîchissement)
  const [wasRecentlyAdded, setWasRecentlyAdded] = useState(false);
  const {isItemInList, handleAddToList} = useListItems();
  const dansLaListe = isItemInList(articleState.data.tolede.code_article);
  
  // Maintenir l'état pendant le rafraîchissement
  React.useEffect(() => {
    if (isAdding) {
      // Quand on commence à ajouter, marquer comme récemment ajouté
      setWasRecentlyAdded(true);
    } else if (!refreshing && wasRecentlyAdded && dansLaListe) {
      // Réinitialiser seulement quand le rafraîchissement est terminé ET
      // que le serveur a confirmé que l'item est dans la liste
      setWasRecentlyAdded(false);
    }
  }, [isAdding, refreshing, wasRecentlyAdded, dansLaListe]);
  
  // Déterminer si l'article est dans la liste ou a été récemment ajouté
  const isInList = dansLaListe || wasRecentlyAdded;
  
  // Récupération des données du produit
  const {stats_par_livraison, unite_de_commande_client} = articleStat;
  const quantite_totale = stats_par_livraison.reduce((acc, stat) => {
    acc += stat.quantite;
    return acc;
  }
  , 0);
  const quantite_format = toDecimalFr(quantite_totale, 0, 2);
  
  // Récupération des données du prix
  const prixHT = itemState.data.tolede.prix_par_livraison?.[0]?.montant_ht ?? 0;
  const tauxTVA = itemState.data.tolede.taux_de_tva;
  
  // Composant pour le prix
  const prixBlock = (
    <PrixBlockUnitaire
      prixUnitaire={prixHT}
      uniteFacturation={itemState.data.tolede.unite_de_facturation} 
      tauxTVA={tauxTVA}
      style={{h:'100%', mt:{base:'0', md:'15px'}}}
    />
  );
  
  return (
    <LigneProduitLayout precommande={false}>
      <Flex direction={{base: 'column', md: 'row'}}
        width="100%">
        {/* Première ligne avec code produit, drapeau et informations de l'article */}
        <Flex align="center"
          mr={{base: '0', md: 'auto'}}
          flex={1}
          mb={2}
          width={{base: '100%', md: 'auto'}}>
          <ArticleBlock
            code_article={articleState.data.tolede.code_article}
          />
        </Flex>
        
        <Flex 
          direction={{base: 'column', md: 'row'}} 
          justify="space-between" 
          align={{base: 'flex-start', md: 'center'}}
          mt={3}
        >
          <HStack spacing={4}
            flex={1}
            width={{base: '100%', md: 'auto'}}
            mb='4'
          >
            {/* Information de quantité */}
            {unite_de_commande_client && (
              <Tooltip 
                label={t('liste.quantite_saisonniere_tooltip')}
                placement="top"
                hasArrow
              >
                <VStack
                  bg='gray.100'
                  borderRadius='md'
                  p='4'
                >
                  <Text fontSize="sm"
                    color="gray.700">
                    {quantite_format}&nbsp;{t(`unite.${unite_de_commande_client}`, {count: quantite_totale})}
                  </Text>
                </VStack>
              </Tooltip>
            )}
            
            {/* Bloc de prix */}
            <Box w={{base: 'auto', md: 32}}
              mx='2'
              ml={{base: 'auto', md: 2}}
            >
              {prixBlock}
            </Box>
          </HStack>
          
          <VStack ml={{base: '0', md: 'auto'}}
            pt={{base: 3, md: 0}}
            width={{base: '100%', md: 'auto'}}
            justifyContent={{md: 'flex-end'}}
            align={{base: 'flex-end', md: 'center'}}
          >
            
            {/* Bouton d'ajout à la liste */}
            <Tooltip
              w={{base: '100%', md: 'auto'}}
              isDisabled={!(!handleAddToList || (dansLaListe && !isAdding))}
              label={!handleAddToList ? t('liste.selectionner_liste_tooltip') : t('liste.produit_deja_dans_liste')}
              placement="top"
              hasArrow
            >
              <Button
                leftIcon={isInList ? <CheckIcon/> : <AddIcon />}
                variant='outline'
                colorScheme={handleAddToList ? 'blue' : 'gray'}
                _hover={{bg: 'blue.main_transparency_30'}}
                w={{base: '100%', md: '13em'}}
                isLoading={isAdding}
                onClick={()=>{handleAddToList?.(itemState, setIsAdding);}}
                //Disable si déjà dans la liste
                disabled={isAdding || isInList || !handleAddToList}
              >
                {isInList ? t('liste.dans_la_liste') : t('liste.ajouter_a_la_liste')}
              </Button>
            </Tooltip>
            <Box alignSelf={'flex-start'}>
              <HistoriqueAchatButton itemState={itemState} />
            </Box>
          </VStack>
        </Flex>
      </Flex>
    </LigneProduitLayout>
  );
});
