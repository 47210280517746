import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {Box, Button, Flex, HStack, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {format, parseISO} from 'date-fns';
import {useCallback, useState} from 'react';
import {calculateMonthDifference, formatDateForDisplay, getDefaultMonthOffset} from 'ff-common';
import {CalendarModale} from '../CalendarModale/CalendarModale';
import {BlueButton} from '@/components/Button/BlueButton';
import {CalendarHeader} from '../CalendarModale/Calendar/CalendarHeader';
import {DateRangeGrid} from './DateRangeGrid';
import {Periode} from '@/domains/liste/components/ProduitsParSaison';
import {GrayButton} from '@/components/Button/GrayButton';

// Modale de sélection d'une plage de dates
export const DateRangeModale = NiceModal.create(({
  onDateRangeSelect,
  initialDateRange
}: {
  onDateRangeSelect: (periode: Periode) => void;
  initialDateRange?: Periode | null;
}) => {
  const modal = useModal();
  const {t} = useTranslation();
  // Utilise la fonction utilitaire pour obtenir le décalage de mois initial
  const getInitialMonthOffset = useCallback((): number => {
    if (initialDateRange?.date_debut) {
      return calculateMonthDifference(initialDateRange.date_debut);
    }
    
    // Sinon afficher le même mois de l'année dernière par défaut
    return getDefaultMonthOffset();
  }, [initialDateRange]);

  const [monthOffset, setMonthOffset] = useState<number>(getInitialMonthOffset());
  const [startDate, setStartDate] = useState<Date | null>(
    initialDateRange?.date_debut ? parseISO(initialDateRange.date_debut) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    initialDateRange?.date_fin ? parseISO(initialDateRange.date_fin) : null
  );
  const [validating, setValidating] = useState(false);

  // Gestion de la sélection des dates
  const handleDateClick = useCallback((date: Date) => {
    if (!startDate || endDate) {
      // Si pas de date de début ou les deux dates sont déjà définies, on réinitialise
      setStartDate(date);
      setEndDate(null);
    } else {
      // Si la date cliquée est avant la date de début, on inverse
      if (date < startDate) {
        setEndDate(startDate);
        setStartDate(date);
      } else {
        setEndDate(date);
      }
    }
  }, [startDate, endDate]);

  // Validation de la sélection
  const validateSelection = useCallback(() => {
    if (startDate && endDate) {
      setValidating(true);
      
      const periode: Periode = {
        date_debut: format(startDate, 'yyyy-MM-dd'),
        date_fin: format(endDate, 'yyyy-MM-dd')
      };
      
      onDateRangeSelect(periode);
      modal.remove();
    }
  }, [startDate, endDate, onDateRangeSelect, modal]);

  // Annulation et fermeture de la modale
  const handleCancel = useCallback(() => {
    modal.remove();
  }, [modal]);

  // Initialisation de la date référence (utilisée pour l'affichage du mois)
  const referenceDate = new Date();

  return (
    <CalendarModale 
      modal={modal}
      messageKey="selection_periode"
      onCancel={handleCancel}
      size_desktop="xl"
    >
      <Box>
        {/* Affichage de la sélection actuelle */}
        <Box 
          bg="gray.75" 
          p={4} 
          borderRadius="md" 
          mb={4}
        >
          <Flex justify="space-between"
            align="center">
            <Box>
              <Text>
                {formatDateForDisplay(startDate, 'dd MMM yyy', t('liste.date_range.debut'))} 
                {' - '} 
                {formatDateForDisplay(endDate, 'dd MMM yyy', t('liste.date_range.fin'))}
              </Text>
            </Box>
            <Button 
              variant="outline" 
              size="sm"
              isDisabled={!startDate}
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
              }}
            >
              {t('liste.date_range.effacer')}
            </Button>
          </Flex>
        </Box>

        {/* En-tête du calendrier */}
        <CalendarHeader 
          dateLivraison={referenceDate.toISOString()} 
          monthOffset={monthOffset} 
          setMonthOffset={setMonthOffset}
        />
        
        {/* Grille du calendrier adaptée pour la sélection de plage */}
        <DateRangeGrid
          onDateClick={handleDateClick} 
          monthOffset={monthOffset}
          startDate={startDate}
          endDate={endDate}
          referenceDate={referenceDate}
        />
        
        {/* Footer avec boutons */}
        <HStack
          mt={{base:'5', md:'10'}} 
          gap='3' 
          flexDirection={{base: 'column', md: 'row'}} 
          alignItems='stretch' 
          p='5px'
        >
          <GrayButton 
            variant="outline"
            onClick={handleCancel}
            flex='1'
            mt='0'
            texte={t('global.annuler')}
          />
          <BlueButton 
            texte={t('liste.date_range.valider')}
            isDisabled={validating || !startDate || !endDate}
            onClick={validateSelection}
            flex='1'
            p='3'
            flexShrink={0}
            mt='0'
          />
        </HStack>  
      </Box>
    </CalendarModale>
  );
});
