import {toDecimalFr} from 'ff-common';
import {HStack, StyleProps, Text, VStack} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {useTranslation} from 'react-i18next';

export const PrixBlock: React.FC<{
  refreshing: boolean,
  ligneDePanier: State<LigneDePanierDesc>,
} & StyleProps> = ({
  refreshing,
  ligneDePanier,
  ...props
}) => {
  const {t} = useTranslation();
  const prixPrincipal = ligneDePanier.data.prix_total_estime_ht;
  const prixUnitaire = ligneDePanier.data.prix_unitaire;
  const uniteFacturation = ligneDePanier.data.unite_de_facturation;
  return (
    <VStack minW={{base: 0, md: '9em'}}
      alignItems="start"
      gap='0'
      mt='15px'
      ml='auto'
      mr={{base: 0, md: 7}}
      {...props}>
      <HStack gap='0.5'
        alignItems='start'>
        <Text textColor='black.text'
          fontSize='xl'
          fontWeight='700'
          opacity={refreshing ? 0.3 : 1}>{toDecimalFr(prixPrincipal)}</Text>
        <Text fontSize='xs'>€</Text>
        <Text fontSize='xs'>{t('ligne_produit.HT')}</Text>
      
      </HStack>
      <Text fontSize='xs'
        color='gray.dark'>
        {t('ligne_produit.soit_prix', {prix:toDecimalFr(prixUnitaire, 2, 3)})}/{t(`unite.${uniteFacturation}`, {count:1})}
      </Text>;
    </VStack>
  );
};