import {Box, Flex, HStack, MenuItem, Spinner, Text, VStack} from '@chakra-ui/react';
import {arePeriodesEqual} from '../utils/dateRangeUtils';
import {useTranslation} from 'react-i18next';
import {useCallback, useEffect, useState} from 'react';
import {useHalCollection} from '@synako/haligator';
import {useListes} from '../../../haligator-factory';
import {MonthSelector} from './MonthSelector';
import {DateRangeSelectorButton} from './DateRangeSelectorButton';
import {DateRangeTag} from './DateRangeTag';
import {FilterButton} from './FilterButton';
import {ProduitsSaisonniers} from './produits-par-saison/LigneProduitsSaisonniers';
import {Navigable} from '@synako/halitrek';
import {ListeDesc} from 'api-types/ffconnect-hal';

export interface Periode {
  date_debut: string;
  date_fin: string;
}

// Types de sélection de périodes
type PeriodeSelectionType = 'month' | 'custom';
// Vue des produits commandés par saison
export const ProduitsParSaison: React.FC 
= () => {
  const {t} = useTranslation();
  const [selectedPeriod, setSelectedPeriod] = useState<Periode | undefined>(undefined);
  const [customDateRange, setCustomDateRange] = useState<Periode | null>(null);
  const [selectionType, setSelectionType] = useState<PeriodeSelectionType>('month');
  const [selectedListe, setSelectedListe] = useState<Navigable<ListeDesc>|undefined>(undefined);
  const [selectedListeName, setSelectedListeName] = useState<string>('');
  const {items:listes, refreshing, loading} = useHalCollection(useListes());

  const [itemCount, setItemCount] = useState<number>(0);
  const [somethingRefreshing, setSomethingRefreshing] = useState<boolean>(false);

  // Gère les changements de période sélectionnée via MonthSelector
  const handlePeriodChange = useCallback((period: Periode | undefined) => {
    setSelectedPeriod(period);
    // Si une plage de dates personnalisée était sélectionnée, la désactiver
    if (customDateRange) {
      setCustomDateRange(null);
      setSelectionType('month');
    }
  }, [customDateRange]);

  // Gère les changements de plage de dates personnalisée
  const handleDateRangeChange = useCallback((dateRange: Periode | null) => {
    setCustomDateRange(dateRange);
    if (dateRange) {
      setSelectedPeriod(dateRange);
      setSelectionType('custom');
    } else {
      // Si on supprime la plage personnalisée, revenir à la sélection par mois
      setSelectionType('month');
    }
  }, []);

  // Effet pour mettre à jour la période sélectionnée quand le type de sélection change
  useEffect(() => {
    if (selectionType === 'custom' && customDateRange && !arePeriodesEqual(selectedPeriod, customDateRange)) {
      setSelectedPeriod(customDateRange);
    }
  }, [selectionType, customDateRange, selectedPeriod]);

  return (
    <VStack 
      align="stretch"
      spacing={4}
      width="100%"
      py={4}
    >
      <Box bg="white"
        width="100%">
        <Flex direction="column"
          w="100%">
          {/* Ligne supérieure avec le bouton et le sélecteur de mois */}
          <Flex direction="row"
            align="center"
            gap={4}
            p={2}>
            <DateRangeSelectorButton
              selectedDateRange={customDateRange}
              onDateRangeChange={handleDateRangeChange}
            />
            <Box flex="1"
              overflow="auto">
              <MonthSelector 
                selectedPeriod={selectedPeriod}
                onPeriodChange={handlePeriodChange}
                isDisabled={selectionType === 'custom'}
              />
            </Box>
          </Flex>
          
          {/* Affichage du tag de plage de dates en dessous */}
          {customDateRange && selectionType === 'custom' && (
            <Box px={2}
              pb={2}>
              <DateRangeTag 
                dateRange={customDateRange}
                onDateRangeChange={handleDateRangeChange}
              />
            </Box>
          )}
        </Flex>
      </Box>

      <HStack> 
        <HStack 
          spacing={2}
          bg="gray.75"
          borderRadius="md"
          height={14}
          divider={<Box width="1px"
            height="100%"
            bg="gray.200" />}
        >
          <Text p='2'
            px='5'
            color="gray.700">
            {t('global.produit', {count: itemCount})}
          </Text>
        </HStack>
        {somethingRefreshing && <Spinner />}
        <HStack 
          ml='auto'
          spacing={2}
          p={2}
          px={2}
          borderRadius='lg'
          bg='white'
        >

          <Text ml='auto'>
            {t('liste.ajouter_produits')}
          </Text>
          {/* Dropdown menu selection liste */}
          <FilterButton
            label={selectedListe ? selectedListeName : t('cadencier.mon_cadencier')}
            bg='blue.main'
            textColor='white'
            chevronColor='white'
            borderRadius={8}
            py='4'
          >
            {/* Option Cadencier comme premier élément */}
            <MenuItem 
              key="cadencier"
              onClick={() => {setSelectedListe(undefined);}}
            >
              <Text fontWeight="500">{t('cadencier.mon_cadencier')}</Text>
            </MenuItem>
          
            {/* Séparateur entre Cadencier et les listes personnalisées */}
            <Box borderTop="1px solid"
              borderColor="gray.200"
              my={2} />
          
            {loading || refreshing ? (
              <MenuItem disabled
                opacity={0.5}>{t('liste.chargement_listes')}</MenuItem>
            ) : (
              listes?.map((liste) => (
                <MenuItem 
                  key={liste.data.id}
                  onClick={() => {
                    setSelectedListe(liste.follow('self'));
                    setSelectedListeName(liste.data.nom_liste);}}
                >
                  <Text>{liste.data.nom_liste}</Text>
                  <Text ml={2}
                    fontSize="xs"
                    color="gray.500">
                    {t('global.produit', {count: liste.followAllOrThrow('selection').length})}
                  </Text>
                </MenuItem>
              ))
            )}
          </FilterButton>
        </HStack>
      </HStack>

      {selectedPeriod && (
        <Box mt={4}>
          <ProduitsSaisonniers 
            selectedPeriod={selectedPeriod}
            selectedListe={selectedListe} 
            setItemCount={setItemCount}
            setSomethingRefreshing={setSomethingRefreshing}
          />
        </Box>
      )}
    </VStack>
  );
};