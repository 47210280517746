import {fromRoute} from '@synako/enhanced-router';
import index from './ffconnect';

const cadencier = fromRoute(index,{
  path: '/cadencier'
});
const inspect_search = fromRoute(index,{
  path: '/inspect/search'
});
const inspect_remplacement = fromRoute(index,{
  path: '/inspect/remplacement'
});
const inspect_nomenclature = fromRoute(index,{
  path: '/inspect/nomenclature'
});
const panier_default = fromRoute(index,{
  path: '/panier'
});
const panier = fromRoute<{date_panier_actif: string}>(index,{
  path: 'panier/:date_panier_actif'
});
const panier_redirect = fromRoute<{date_de_livraison: string}>(index,{
  path: 'panier/redirect/:date_de_livraison'
});

const catalogue = fromRoute(index,{
  path: '/catalogue'
});

const produit = fromRoute<{code_article: string}>(index,{
  path: '/produit/:code_article',
});

const recherche = fromRoute(index,{
  path: '/recherche'
});
const mentions = fromRoute(index,{
  path: '/mentions_legales'
});
const listes_default = fromRoute(index,{
  path: '/listes'
});
const listes = fromRoute<{code_liste: string}>(index,{
  path: '/listes/:code_liste'
});
const cache = fromRoute(index,{
  path: '/cache'
});
const accueil = fromRoute(index, {
  path: '/accueil'
});
const menuClient = fromRoute(index, {
  path: '/menuClient'
});
const profil = fromRoute(index, {
  path: '/profil'
});
const commandes = fromRoute(index, {
  path: '/commandes'
});
const commande_detail = fromRoute(index, {
  path: '/commandes/:client_id/:cle_commande'
});
const factures = fromRoute(index, {
  path: '/factures'
});
const livraisons = fromRoute(index, {
  path: '/livraisons'
});
const contacts = fromRoute(index, {
  path: '/contacts'
});
const preferences = fromRoute(index, {
  path: '/preferences'
});
const clientSelect = fromRoute(index, {
  path: '/client/select'
});
const backOffice = fromRoute(index, {
  path: '/backoffice'
});
const backOfficeClients = fromRoute(index, {
  path: '/backoffice/clients'
});
const backOfficeAjouterSocieteCommerciale = fromRoute(index, {
  path: '/backoffice/societes-commerciales/ajouter'
});
const backOfficeModifierSocieteCommerciale = fromRoute<{societe_commerciale_id: string}>(index, {
  path: '/backoffice/societes-commerciales/:societe_commerciale_id/modifier'
});
const backOfficeSocieteCommercialeClientsSelect = fromRoute<{id_societe_commerciale: string}>(index, {
  path: '/backoffice/societes-commerciales/:id_societe_commerciale/clients'
});
const backOfficeSocieteCommercialeClientDetail = fromRoute<{id_societe_commerciale: string, id_client:string}>(index, {
  path: '/backoffice/societes-commerciales/:id_societe_commerciale/clients/:id_client'
});
const assistanceClient = fromRoute<{id_client_assistance: string}>(index, {
  path: '/backoffice/assistance/client/:id_client_assistance'
});

// Route pour la Prise de Commande
const backOfficePriseCommande = fromRoute<{id_societe_commerciale: string}>(index, {
  path: '/backoffice/commande/:id_societe_commerciale'
});
// TODO: à revoir
const backOfficePriseCommandeNouvelleCommande = fromRoute<{id_societe_commerciale: string, id_client: string}>(index, {
  path: '/backoffice/paniers/:id_societe_commerciale/:id_client/'
});
// TODO: à revoir
const backOfficePriseCommandePanier = fromRoute<{id_societe_commerciale: string, id_client: string, date_de_livraison?: string}>(index, {
  path: '/backoffice/paniers/:id_societe_commerciale/:id_client/:date_de_livraison'
});

// Route pour le Catalogue Grand Compte
const catalogueGrandCompte_path = '/catalogue-grand-compte';
const catalogueGrandCompte = fromRoute(index, {
  path: catalogueGrandCompte_path
});
const catalogueGrandCompteSearch = fromRoute(index, {
  path: `${catalogueGrandCompte_path}/search`
});
const backOfficeCatalogueGrandCompte = fromRoute(index, {
  path: `/backoffice${catalogueGrandCompte_path}`
});
const backOfficeCatalogueGrandCompteSearch = fromRoute(index, {
  path: `/backoffice${catalogueGrandCompte_path}/search`
});

export default {
  cadencier,
  inspect_search,
  inspect_remplacement,
  inspect_nomenclature,
  panier,
  panier_default,
  panier_redirect,
  catalogue,
  produit,
  recherche,
  mentions,
  listes_default,
  listes,
  cache,
  menuClient,
  profil,
  accueil,
  commandes,
  commande_detail,
  preferences,
  factures,
  livraisons,
  contacts,
  clientSelect,
  backOffice,
  backOfficeClients,
  backOfficeAjouterSocieteCommerciale,
  backOfficeModifierSocieteCommerciale,
  backOfficeSocieteCommercialeClientsSelect,
  backOfficeSocieteCommercialeClientDetail,
  backOfficePriseCommande,
  backOfficePriseCommandePanier,
  backOfficePriseCommandeNouvelleCommande,
  assistanceClient,
  catalogueGrandCompte,
  catalogueGrandCompteSearch,
  backOfficeCatalogueGrandCompte,
  backOfficeCatalogueGrandCompteSearch
};
