import {Periode} from '../components/ProduitsParSaison';
import NiceModal from '@ebay/nice-modal-react';
import {DateRangeModale} from '@/components/Modale/DateRangeModale/DateRangeModale';

/**
 * Ouvre la modale de sélection de plage de dates
 * @param onDateRangeSelect Callback appelé lorsqu'une plage de dates est sélectionnée
 * @param initialDateRange Période initiale à afficher (optionnel)
 */
export const openDateRangeModal = (
  onDateRangeSelect: (periode: Periode) => void,
  initialDateRange?: Periode | null
): void => {
  NiceModal.show(DateRangeModale, {
    onDateRangeSelect,
    initialDateRange
  });
};

/**
 * Type pour les props communes aux composants de date range
 */
export interface DateRangeComponentProps {
  selectedDateRange: Periode | null;
  onDateRangeChange: (dateRange: Periode | null) => void;
}

/**
 * Détermine si deux périodes sont égales
 */
export const arePeriodesEqual = (a?: Periode | null, b?: Periode | null): boolean => {
  if (!a && !b) return true;
  if (!a || !b) return false;
  
  return a.date_debut === b.date_debut && a.date_fin === b.date_fin;
};
