import {Box, HStack} from '@chakra-ui/layout';
import {Button, Stack, Tag} from '@chakra-ui/react';
import {ProductControlSideHeader} from '../../../components/Product/ProductControlSideHeader';
import {useTranslation} from 'react-i18next';
import {CadencierTitreNombreDeProduits} from './CadencierTitreNombreDeProduits';
import {useCallback, useEffect, useState} from 'react';
import {useCapture} from '@/posthog/PosthogSetup';

export interface CadencierHeaderProps {
  nombreDeProduits:number,
  editMode:boolean,
  setEditMode:(_v:boolean)=>void,
  categories: {categorie_id: string; libelle_web: string}[],
}

export const CadencierHeader: React.FC<CadencierHeaderProps> = ({nombreDeProduits, editMode, setEditMode, categories}) => {
  const [activeTag, setActiveTag] = useState<string | null>(null);
  
  const capture = useCapture();
  
  const scrollVersCategorie = useCallback((categorie_id: string, libelle_web: string, index: number, count: number) => {
    capture({
      name: 'cadencier:tag',
      props: {
        categorie_id,
        libelle_web,
        index,
        count,
      }
    });
    const element = document.getElementById(categorie_id);
    // recuperation de la hauteur du header
    const headerRef:HTMLDivElement|null = document.querySelector('#cadencier_header_container');
    const headerHeight = headerRef?.offsetHeight ?? 0;
    // recuperation de la hauteur du header
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      // position finale de défilement
      const offsetPosition = elementPosition - headerHeight - 130;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      setActiveTag(categorie_id);
    }
  }, [capture]);

  const handleScroll = useCallback(() => {
    // verification si la reférence à une section existe
    const sectionsRef:HTMLDivElement|null = document.querySelector('#cadencier_body_container');
    if (!sectionsRef) return;
    // recuperation de la hauteur du header
    const headerRef:HTMLDivElement|null = document.querySelector('#cadencier_header_container');
    const headerHeight = headerRef?.offsetHeight ?? 0;
    const isMobile = window.innerWidth <= 768;
    // ajout 1/4 en mobile de la hauteur de la fenêtre pour déclencher le changement un peu avant que la section atteigne le haut
    // 80px en desktop
    const scrollPosition = window.scrollY + headerHeight + (isMobile ? window.innerHeight / 4 : 80);    // tableau à partir des enfants de l'élément référencé par sectionsRef
    // chaque enfant représente une catégorie dans le cadencier
    const sections = Array.from(sectionsRef.children);
    // pour chaque section comparaison de sa position supérieure avec la position de défilement calculée
    // si la position de défilement est >= à la position de la section, cette section est considérée comme active
    for (let i = sections.length - 1; i >= 0; i--) {
      const section = sections[i] as HTMLElement;
      const sectionTop = section.offsetTop;
      if (scrollPosition >= sectionTop) {
        setActiveTag(section.id);
        break;
      }
    }
  }, []);

  useEffect(() => {
    // écouteur d'événement sur scroll
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  
  return (
    <Stack>
      <Stack gap='0'
        flexDirection={{base: 'column', md:'row'}}
        justify='start'
        width='100%'
        alignItems='baseline'
        p={{base: '0', md: '1'}}
        bg='white'>
        <Box display={{base:'none', md:'contents'}}>
          <CadencierTitreNombreDeProduits nombreDeProduits={nombreDeProduits} />
          {!editMode?<ProductControlSideHeader deleteAction={()=>{setEditMode(true);}}/>:
            <ProductControlSideHeaderEdition validateAction={()=>{console.log('Validate edition');}}
              backAction={()=>{setEditMode(false);}} />
          }
        </Box>
      </Stack>
      <Box bg="white"
        width="100%">
        <HStack wrap="wrap"
          spacing={2}
          p={{base:'0 10px 0 10px', md:'0 0 5px 10px'}}
          bg='white'
          zIndex='dropdown'
          mb='0px'>
          {categories.map((cat, index) => (
            <Tag
              key={`tag_${cat.categorie_id}`}
              size='md'
              cursor="pointer"
              border='solid 1px'
              borderColor={activeTag === cat.categorie_id ? 'black.text' : 'gray.light'}
              bg='white'
              color={activeTag === cat.categorie_id ? 'black.text' : 'gray.dark'}
              onClick={() => {
                scrollVersCategorie(cat.categorie_id, cat.libelle_web, index, categories.length);}
              }
              _hover={{bg: 'gray.dark', color:'white'}}
            >
              {cat.libelle_web}
            </Tag>
          ))}
        </HStack>
      </Box>
    </Stack>
  );
};

export const ProductControlSideHeaderEdition:React.FC<{validateAction:()=>void,backAction:()=>void}> = ({validateAction, backAction})=>{
  const {t} = useTranslation();
  
  return <>
    {/* Version mobile */}
    <HStack flexWrap='wrap'
      w='100%'
      gap='0'
      display={{base:'flex', md:'none'}}>
      <Button minW='min(fit-content, 50%)'
        flex='1'
        w='max-content'
        borderRadius='0'
        p='5'
        borderY='1px solid'
        borderX='0.5px solid'
        colorScheme='blue'
        onClick={()=>{validateAction(); backAction();}}
      >
        {t('cadencier.quitter_edition')}
      </Button>
    </HStack>
    {/* ----- */}
    {/* Version desktop */}
    <HStack ml='auto'
      alignItems='baseline'
      display={{base:'none', md:'flex'}}>
      <Button colorScheme='blue'
        onClick={()=>{validateAction(); backAction();}}>{t('cadencier.quitter_edition')}</Button>
    </HStack>
  </>;
};
