import {Box, HStack} from '@chakra-ui/react';
import {NavButton} from '../NavButton';
import {HomeIcon} from '../../svg/HomeIcon';
import {useTranslation} from 'react-i18next';
import {CatalogueIcon} from '../../svg/CatalogueIcon';
import NiceModal from '@ebay/nice-modal-react';
import {CatalogueModale} from '@/domains/article/NomenclatureModale/CatalogueModale';
import {MenuClientMobileModale} from '@/domains/client/menu/MenuClientMobileModale';
import {routes, useRemoveAllModal} from 'ff-common';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {CadencierIcon} from '../../svg/CadencierIcon';
import {ListIcon} from '../../svg/ListIcon';
import {UserIcon} from '../../svg/UserIcon';
import {useLocation} from 'react-router-dom';
import {useCapture} from '@/posthog/PosthogSetup';

export const bottomNavHeight = '4.5rem';

export const BottomNav:React.FC = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const closeModales = useRemoveAllModal();

  const capture = useCapture();

  //TODO(Nathan) Catalogue & Profil : Toggle les modales lorsqu'elles sont déjà ouvertes

  const dansLePanier = location.pathname.startsWith(routes.client.panier_default.path);

  return (
    (!dansLePanier) && <HStack zIndex='popover'
      display={{base:'flex', md:'none'}}
      position='fixed'
      bottom='0'
      justify='space-between'
      alignContent='stretch'
      bg="white"
      textColor='gray.dark'
      w='100vw'
      h={bottomNavHeight}
      px={{base:'0', md:'12'}}
      gap={{base:'0', md:'1'}}
      boxShadow="base">
      {/* Accueil */}
      <NavButton ButtonIcon={HomeIcon}
        label={t('global.accueil')} 
        onClick={closeModales}
        to={buildAssistanceRoute(routes.client.accueil)}/>
      {/* Catalogue */}
      <NavButton ButtonIcon={CatalogueIcon}
        label={t('global.catalogue')}
        showAs={Box}
        onClick={() => {
          closeModales();
          NiceModal.show(CatalogueModale);
        }}/>
      {/* Cadencier */}
      <NavButton ButtonIcon={CadencierIcon}
        label={t('global.cadencier')}
        onClick={()=>{
          closeModales();
          capture({name: 'cadencier:go', props: {depuis: 'menu'}});
        }}
        to={buildAssistanceRoute(routes.client.cadencier)}/>
      {/* Listes */}
      <NavButton ButtonIcon={ListIcon}
        label={t('global.listes')} 
        onClick={closeModales}
        to={buildAssistanceRoute(routes.client.listes_default)}/>
      {/* Profil */}
      <NavButton ButtonIcon={UserIcon}
        label={t('global.profil')} 
        showAs={Box}
        onClick={()=>{
          closeModales();
          NiceModal.show(MenuClientMobileModale);
        }}
      />
    </HStack>
  );
};
