import {
  Box, Center, Container, Heading, Image, Input,
  InputGroup, InputLeftElement, SimpleGrid, Text
} from '@chakra-ui/react';
import {CategorieImage} from '../article/NomenclatureModale/CategorieImage';
import {useTranslation} from 'react-i18next';
import {imageOptimisee} from '../mediatheque/optimisation';
import {LOGO_FF_BLANC_URL} from '../mediatheque/logoFFBlancUrl';
import {SearchIcon} from '@/components/svg/SearchIcon';
import {HaliRenderOne} from '@synako/haligator';
import {LOGO_FF_COULEUR_URL} from '../mediatheque/logoFFCouleurUrl';
import {useNomenclature} from '@/haligator-factory';
import {routes} from 'ff-common';
import {buildRoute} from '@synako/enhanced-router';
import {useNavigate} from 'react-router-dom';
import {useCallback} from 'react';

// Composant pour les cartes de catégories
const CategoryCard: React.FC<{id: string; title: string; onClick: () => void}> = ({id, title, onClick}) => {
  return (
    <Box 
      bg="white" 
      borderRadius="md" 
      p={4} 
      textAlign="center"
      transition="transform 0.2s"
      _hover={{transform: 'scale(1.03)'}}
      cursor="pointer"
      onClick={onClick}
      shadow={'md'}
    >
      <Center mb={3}>
        <Box height="75px"
          width="75px"
          position="relative">
          <CategorieImage
            categorieId={id}
            libelle={title}
            objectFit="cover"
            height="75px"
            width="75px"
            borderRadius="md"
          />
        </Box>
      </Center>
      <Text 
        fontWeight="medium" 
        fontSize="sm"
        color={'black.text'}
      >
        {title}
      </Text>
    </Box>
  );
};

// Importation des composants du catalogue standard que nous réutilisons

/**
 * Composant pour le Catalogue Grand Compte
 */
export const CatalogueGrandCompteAccueil: React.FC<{colorScheme?:'invert'|'normal', backoffice?:boolean}> 
= ({colorScheme, backoffice=false}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const nomenclatureNav = useNomenclature();

  const textColor = colorScheme=='invert' ? 'black.text' : 'white';
  const bgColor = colorScheme=='invert' ? 'white' : 'blue.main';
  const logoURL = colorScheme=='invert' ? LOGO_FF_COULEUR_URL : LOGO_FF_BLANC_URL;

  const navigateToSearch = useCallback(({q, cat_web}: {q?:string, cat_web?: string}) => {
    navigate(buildRoute(backoffice ? routes.client.backOfficeCatalogueGrandCompteSearch : routes.client.catalogueGrandCompteSearch, 
      undefined, {q, cat_web}));
  }, [backoffice, navigate]);

  return (
    <Box bg={bgColor}
      minH="100vh"
      color={textColor}>
      {/* Logo Section */}
      <Box pt={10}
        pb={8}
        textAlign="center">
        <Container maxW="container.md">
          <Heading 
             
            as="h1" 
            fontFamily="heading" 
            fontWeight="bold" 
            color="white"
            fontSize="4xl"
            textTransform="uppercase"
            letterSpacing="wider"
            position="relative"
            display="inline-block"
          >
            <Image src={imageOptimisee(logoURL, 300)}
              alt={t('global.alt_logo_france_frais')}
              marginX='auto'
              w="300px"
              mt={{base: '30px', md: '0'}} />
          </Heading>
        </Container>
      </Box>
      
      {/* Search Bar */}
      <Box mb={16}>
        <Container maxW="container.md">
          <InputGroup bg="white"
            borderRadius="md">
            <InputLeftElement pointerEvents="none">
              <SearchIcon />
            </InputLeftElement>
            <Input 
              placeholder={t('grand_compte.chercher_un_produit')} 
              color="black"
              _placeholder={{color: 'gray.500'}}
              borderRadius="md"
              onKeyDown={(e) => {
                if (e.key !== 'Enter') return;
                navigateToSearch({q: e.currentTarget.value});
              }}
            />
          </InputGroup>
        </Container>
      </Box>
      
      {/* Product Categories Section */}
      <Container maxW="container.xl"
        pb={16}>
        {/* Section Title */}
        <Heading 
           
          as="h2" 
          fontSize="2xl" 
          textAlign="center" 
          mb={8}
        >
          {t('grand_compte.decouvrez_nos_gammes')}
        </Heading>
        
        {/* Product Categories Grid */}
        <HaliRenderOne
          nav={nomenclatureNav}
          fallback={() => <Text>{t('global.erreur_categories')}</Text>}
          render={({data}) => {
            return <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}}
              spacing={6}>
              {data.web.categories.map(categorie => {
                const handleClick = () => {
                  navigateToSearch({cat_web: categorie.id});
                };
                if (categorie.id_parent) return;
                return (
                  <CategoryCard 
                    key={categorie.id}
                    id={categorie.id}
                    title={categorie.libelle}
                    onClick={handleClick}
                  />
                );
              })}
            </SimpleGrid>;
          }}/>
      </Container>
    </Box>
  );
};
