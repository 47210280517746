import {useTranslation} from 'react-i18next';
import {useCallback} from 'react';
import {CalendarIcon} from '@chakra-ui/icons';
import {DateRangeComponentProps, openDateRangeModal} from '../utils/dateRangeUtils';
import {BlueButton} from '@/components/Button/BlueButton';

/**
 * Bouton de sélection de plage de dates précise
 */
export const DateRangeSelectorButton: React.FC<DateRangeComponentProps> = ({
  selectedDateRange,
  onDateRangeChange
}) => {
  const {t} = useTranslation();

  // Ouverture de la modale de sélection de plage de dates
  const handleOpenModal = useCallback(() => {
    openDateRangeModal(onDateRangeChange, selectedDateRange);
  }, [onDateRangeChange, selectedDateRange]);

  return (
    <BlueButton
      margin={0}
      leftIcon={<CalendarIcon />}
      variant="outline"
      // colorScheme="blue"
      onClick={handleOpenModal}
      size="md"
      isActive={!!selectedDateRange}
      texte={t('liste.date_range.select')}
    />
  );
};
