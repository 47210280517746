import {Icon} from '@chakra-ui/react';

export const LightIcon: React.FC<{
  color?: string;
  width?: string;
  height?: string;
}> = ({color='#0072BA', width='10px', height='14px'}) => {
  return (
    <Icon
      width={width}
      height={height}
      viewBox="0 0 10 14"
      fill={color}
    >
      <path 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.196 2.00788C7.66378 1.5759 7.04056 1.26663 6.3718 1.10262C5.70304 0.938619 5.00562 0.924016 4.33041 1.05988C3.43136 1.2391 2.60651 1.67783 1.96052 2.32042C1.31454 2.963 0.876532 3.78047 0.702075 4.66911C0.574677 5.33541 0.597827 6.02123 0.769875 6.67768C0.941923 7.33413 1.25863 7.94503 1.69741 8.4668C2.10462 8.92028 2.33803 9.50018 2.3571 10.1058V11.819C2.3571 12.2734 2.54001 12.7092 2.86558 13.0305C3.19115 13.3518 3.63272 13.5323 4.09315 13.5323H6.40788C6.8683 13.5323 7.30987 13.3518 7.63545 13.0305C7.96102 12.7092 8.14392 12.2734 8.14392 11.819V10.2143C8.16334 9.54567 8.41226 8.90335 8.84991 8.39256C9.61674 7.45643 9.9782 6.25947 9.85576 5.0618C9.73332 3.86414 9.13685 2.76255 8.196 1.99646V2.00788ZM6.98656 11.819C6.98656 11.9705 6.92559 12.1158 6.81707 12.2229C6.70854 12.33 6.56135 12.3901 6.40788 12.3901H4.09315C3.93967 12.3901 3.79248 12.33 3.68396 12.2229C3.57544 12.1158 3.51447 11.9705 3.51447 11.819V11.248H6.98656V11.819ZM7.95296 7.68442C7.3711 8.36549 7.03162 9.21608 6.98656 10.1058H5.82919V8.39256C5.82919 8.2411 5.76823 8.09585 5.6597 7.98875C5.55118 7.88165 5.40399 7.82148 5.25051 7.82148C5.09704 7.82148 4.94985 7.88165 4.84132 7.98875C4.7328 8.09585 4.67183 8.2411 4.67183 8.39256V10.1058H3.51447C3.4992 9.23097 3.17128 8.38957 2.58858 7.73011C2.20413 7.27553 1.9456 6.73039 1.83821 6.14784C1.73081 5.5653 1.77822 4.96523 1.9758 4.40615C2.17339 3.84707 2.5144 3.34806 2.9656 2.95777C3.4168 2.56748 3.96278 2.29924 4.55031 2.1792C5.05524 2.0766 5.57698 2.08625 6.07766 2.20745C6.57834 2.32865 7.04539 2.55836 7.4449 2.87989C7.84441 3.20142 8.16636 3.60672 8.38736 4.06635C8.60837 4.52598 8.72288 5.02841 8.7226 5.53716C8.72689 6.31946 8.45472 7.07877 7.95296 7.68442Z"
      />
    </Icon>
  );
};
