export const en = {
  'authentification': {
    'bienvenu': 'Welcome',
    'commande_en_ligne': 'Online order',
    'compte_france_frais': 'Do you have an account?',
    'connectez_vous': 'Log in',
    'premiere_connexion': 'First time connecting?',
    'utiliser_email': 'Use your contact email address to register and access your account.',
    'activer_compte': 'Log in',
    'devenir_client': 'Become a France Frais customer',
    'contactez_nous': 'Contact Us',
    'tout_proche': 'France Frais, close to',
    'de_vous': 'you!',
    'error': {
      'erreur_rencontree': 'An error was encountered',
      'valider_email': 'You must validate that you are the owner of the email address by clicking on the email that was sent to you.',
      'email_envoye': 'Email sent, please check your inbox.',
      're_envoyer': 'Resend',
      'erreur': 'Error',
      'contacter_commercial': 'please contact your sales representative.',
      'email_correspond_plus': 'Your email no longer corresponds to a contact in our customer database. Please contact your sales representative.',
      'no_sandbox': 'You cannot connect to the sandbox.<br /><br />To access France Frais Connect please use the following link:<br /><br /><Link>https://ffconnect.france-frais.fr</Link>.',
      'switched': 'You have changed customer accounts in another tab. <br /><Link>Reload</Link>'
    }
  },
  'global': {
    'produit': '{{count}} product',
    'produit_one': '{{count}} product',
    'produit_other': '{{count}} products',
    'produit_many': '{{count}} products',
    'produit_plural': '{{count}} products',
    'panier': '{{count}} cart',
    'panier_one': '{{count}} cart',
    'panier_other': '{{count}} carts',
    'panier_many': '{{count}} carts',
    'panier_plural': '{{count}} carts',
    'colis': '{{count}} package',
    'colis_one': '{{count}} package',
    'colis_other': '{{count}} packages',
    'colis_many': '{{count}} packages',
    'colis_plural': '{{count}} packages',
    'annuler': 'Cancel',
    'valider': 'Confirm',
    'retour': 'Back',
    'voir': 'View',
    'remplacer': 'Replace',
    'consulter': 'Consult',
    'choisir': 'Choose',
    'creer': 'Create the list',
    'client': '{{count}} customer',
    'client_one': '{{count}} customer',
    'client_other': '{{count}} customers',
    'client_many': '{{count}} customers',
    'client_plural': '{{count}} customers',
    'a_valider': 'To validate',
    'promotion': 'Promotion',
    'produit_marche': 'Market product',
    'accueil': 'Home',
    'catalogue': 'Catalog',
    'cadencier': 'Reorder List',
    'mon_cadencier': 'Reorder List',
    'listes': 'Lists',
    'mes_listes': 'My Lists',
    'profil': 'Profile',
    'recherche': 'Search',
    'chargement': 'Loading',
    'revenir': 'Return',
    'connecter': 'Login',
    'accepter': 'I accept',
    'refuser': 'Refuse',
    'voir_mon_panier': 'View my cart',
    'choisir_livraison': 'Choose a delivery',
    'logo': 'logo',
    'raison_sociale': 'company name',
    'code': 'code',
    'attention': 'Warning',
    'modifier': 'Modify',
    'compris': 'Understood',
    'jai_compris': 'I understand',
    'alt_logo_france_frais': 'France Frais Logo',
    'jour': 'day',
    'mois': 'month',
    'mois_precedent': 'Previous month',
    'mois_suivant': 'Next month',
    'date': 'date',
    'quantite': 'quantité'
  },
  'header_band': {
    'temps_restant': 'Remember to order as soon as possible for your next delivery on <Text>{{date_de_livraison}}</Text>!',
    'contactez_nous': 'Contact us'
  },
  'cadencier': {
    'cta_panier': 'Go to cart',
    'mon_cadencier': 'My Reorder List',
    'quitter_edition': 'Exit edit mode',
    'chargement_cadencier': 'Loading your Reorder List',
    'suggestion': {
      'texte': 'Suggestion: {{count}} {{unite}}',
      'tooltip': 'Add the suggested quantity directly based on your habits.',
      'remplir_avec_suggestions': 'Fill my cart automatically',
      'articles_ajoutes_au_panier': '{{count}} item successfully added to cart',
      'articles_ajoutes_au_panier_one': '{{count}} item successfully added to cart',
      'articles_ajoutes_au_panier_other': '{{count}} items successfully added to cart',
      'articles_ajoutes_au_panier_many': '{{count}} items successfully added to cart',
      'article_changement_quantite': 'The item quantity has been successfully modified',
      'remplissez_votre_panier': 'Fill your cart in 1 click!',
      'ajoutez_en_un_clic': 'Your custom cart: find your usual products and quantities with one click.',
      'quantite_suggeree_presente': 'Quantity automatically added based on your habits - modify it if needed.',
      'savoir_plus': 'Learn more',
      'savoir_plus_tooltip': 'Products in your Reorder List with a quantity suggestion based on your habits will be added to the cart.',
      'action_annulee': '{{count}} item canceled',
      'action_annulee_one': '{{count}} item canceled',
      'action_annulee_other': '{{count}} items canceled',
      'action_annulee_many': '{{count}} items canceled',
      'action_annulee_plural': '{{count}} items canceled',
      'annulation_echouee': 'Cancellation failed',
      'ligne_introuvable': 'Cart line not found for item {{code_article}}',
      'pas_suggestions_disponibles': 'No quantity suggestion available within your Reorder List.'
    },
    'tri': {
      'tri': 'Sort: ',
      'alphabetique': 'Alphabetical',
      'frequence': 'Purchase frequency'
    }
  },
  'temperature_livraison': {
    'la_livraison_du': 'The delivery of',
    'surgeles_uniquement': 'Frozen only',
    'non_surgeles_uniquement': 'Non-frozen only',
    'livraison_surgelee': 'Frozen delivery',
    'pas_de_surgele': 'No frozen',
    'livraison_non_surgelee': 'Non-frozen delivery',
    'livraison_surgeles_uniquement': 'Frozen delivery only',
    'livraison_non_surgeles_uniquement': 'Non-frozen delivery only',
    'est_livraison_surgeles_uniquement': 'is a frozen-only delivery.',
    'est_livraison_non_surgeles_uniquement': 'is a non-frozen-only delivery.',
    'warning_temperature_ignoree': 'Warning: displayed products may not be available for this delivery temperature'
  },
  'toast': {
    'ajoute_panier_date': 'Product <strong>added to cart for {{date, datetime}}</strong>',
    'modifie_panier_date': 'Product quantity modified <strong>in your cart for {{date, datetime}}</strong>',
    'supprime_panier': 'Product removed from cart',
    'supprime_cadencier': 'Product #{{code_article}} has been removed from the Reorder List',
    'supprime_cadencier_simulation': "Product #{{code_article}} wasn't actually removed from the Reorder List",
    'supprime_cadencier_safelive': 'Product #{{code_article}} has actually been removed from the Reorder List.<br />For it to be taken into account, add it to the Reorder List right away',
    'supprime_liste': 'Product #{{code_article}} has been removed from the list',
    'warning_minimum': 'The minimum quantity is {{minimum}}',
    'warning_arrondis': 'The quantity has been adjusted to the nearest higher multiple (multiple:{{multiple}})',
    'ajout_preco': 'The product has been added to the cart for {{date, datetime}}',
    'unite_switchee': 'The quantity has changed to <strong>{{quantite}} packages</strong>',
    'produit_introuvable_supprime': 'The product cannot be found and cannot be deleted.',
    'ajout_nouvelle_liste': 'The list {{newListName}} has been created',
    'ajout_liste': 'The item has been added to the list',
    'ajout_cadencier': 'The product has been added to the Reorder List',
    'ajout_cadencier_simulation': "The product hasn't actually been added to the Reorder List",
    'ajout_cadencier_safelive': 'The product has actually been added to the Reorder List.<br />For it not to be taken into account, remove it from the Reorder List right away',
    'panier_deplace': 'Cart moved to <strong>{{date, datetime}}</strong>',
    'panier_vidé': 'The cart for {{date}} has been emptied',
    'produit_differe': 'Product <strong>moved to cart for {{date}}.</strong>',
    'produit_differe_cta': "Don't forget to validate it as soon as possible.",
    'produit_remplace': 'Product replaced by {{libelle}}',
    'voir_panier': 'View cart',
    'voir_liste': 'View list',
    'produit_precommande': 'Warning pre-order product',
    'quantite_ajustee': 'The quantity has been adjusted to {{quantite}} {{unite}}',
    'donnees_rafraichies': 'Customer data has been successfully updated.',
    'panier_valide_simulation': "Your cart has been checked but hasn't actually been converted to an order in Tolede.",
    'panier_valide_safelive': 'Your cart has actually been converted to an order in Tolede.<br />To prevent it from being prepared, you must cancel it right away.',
    'prolonge_session': 'Your session has been successfully extended.',
    'prolongement_session_impossible': 'Unable to extend your session.',
    'clients_rafraichis': 'The customer list has been successfully refreshed.',
    'invitation_envoyee': 'The invitation has been successfully sent to {{email}}.',
    'relance_envoyee': 'The reminder has been successfully sent to {{email}}.'
  },
  'panier': {
    'panier': 'Cart',
    'panier_vide_cta': 'Order now!',
    'panier_vide_cta_top': "Don't forget to validate your open carts as soon as possible!",
    'depuis_cadencier': 'From my Reorder List',
    'depuis_catalogue': 'From the catalog',
    'depuis_listes': 'From my lists',
    'remplacement_de': 'Replacement of {{produit}}',
    'pour_la_livraison_du': 'For delivery on <strong>{{date, datetime}}</strong>',
    'pour_la_livraison_du_mobile': 'Delivery on <strong>{{date, datetime}}</strong>',
    'nous_validons': 'We are validating your cart',
    'menu_tooltip': 'Show cart for {{date}}',
    'changer_date': 'Change date',
    'x_panier_a_valider': '{{count}} cart to validate',
    'x_panier_a_valider_one': '{{count}} cart to validate',
    'x_panier_a_valider_other': '{{count}} carts to validate',
    'x_panier_a_valider_plural': '{{count}} carts to validate',
    'liste_livraisons_prompt_mobile': 'Order for another date',
    'supprimer_panier_confirm': 'Are you sure you want to empty the cart?',
    'supprimer_panier_action': 'Empty cart',
    'panier_en_cours': 'Current cart',
    'commande_existant': 'Existing order for this delivery day',
    'listes': '{{count}} list',
    'listes_one': '{{count}} list',
    'listes_other': '{{count}} lists',
    'listes_plural': '{{count}} lists',
    'vide': {
      'votre_panier_est_vide': 'Your cart is empty',
      'merci': 'Thank you',
      'pour_votre_commande': 'for your order!',
      'vous_allez_recevoir_email': 'You will receive a confirmation email shortly.',
      'consulter_detail_commande': 'You can view your order details at any time in the "My Orders" section.',
      'pour_toute_question': 'For any questions about your order, please contact your advisor.',
      'vous_avez_x_panier': 'You have {{count}} cart to validate.',
      'vous_avez_x_panier_one': 'You have {{count}} cart to validate.',
      'vous_avez_x_panier_other': 'You have {{count}} carts to validate.',
      'vous_avez_x_panier_plural': 'You have {{count}} carts to validate.',
      'validez_pour_reserver': 'Validate your current carts to reserve your stock.',
      'n_oubliez_pas_de_valider_restant': "Don't forget to validate<br/>your remaining cart",
      'n_oubliez_pas_de_valider_restant_one': "Don't forget to validate<br/>your remaining cart",
      'n_oubliez_pas_de_valider_restant_other': "Don't forget to validate<br/>your remaining carts",
      'n_oubliez_pas_de_valider_restant_many': "Don't forget to validate<br/>your remaining carts",
      'n_oubliez_pas_de_valider_restant_plural': "Don't forget to validate<br/>your remaining carts",
      'prenez_de_lavance': 'Get ahead!',
      'preparez_prochaine_livraison': 'Prepare your next delivery now',
      'alt_carton_vide': 'Empty order box'
    },
    'recap': {
      'recapitulatif': 'Summary',
      'valider_le_panier': 'Validate cart',
      'total_ht': 'Total excluding tax',
      'total_tva': 'Total VAT',
      'total_ttc': 'Total including tax',
      'tooltip_total_ht': 'Estimated amount based on ordered quantities and will be adjusted upon delivery',
      'accepter_conditions': 'I accept <Link>the general terms of sale</Link>',
      'mode_de_rendu': {
        'enlevement': 'Warehouse pickup',
        'livraison': 'Delivery'
      },
      'modale_adresse': {
        'titre_enlevement': 'Pick up your order at your warehouse',
        'titre_livraison': 'Delivery'
      },
      'reference_commande': 'Order reference'
    },
    'validez': {
      'plus_de_2_jours': 'Validate your cart to block quantities.',
      'moins_de_2_jours': 'Only <strong>{{date}}</strong> left to be delivered on the chosen date.'
    },
    'quantites_inhabituelles': {
      'titre': 'Unusual quantities',
      'message': 'The quantities of these products are unusual:',
      'message_one': 'The quantity of this product is unusual:',
      'message_other': 'The quantities of these products are unusual:',
      'message_plural': 'The quantities of these products are unusual:',
      'message_suite': 'Do you want to validate your order or modify these quantities?',
      'modifier': 'Modify',
      'valider': 'Validate'
    }
  },
  'bouton_achat': {
    'buy': 'Add to cart',
    'preco': 'Pre-order'
  },
  'ligne_produit': {
    'soit_prix': 'or {{prix}}€',
    'retirer_le_produit': 'Remove',
    'poids_variable': 'Variable weight',
    'poids_variable_label': 'The product will be billed at actual weight',
    'tva_percent': 'VAT {{tauxTVA}}%',
    'HT': 'Excl. tax',
    'encart_dlc': 'Estimated best before date: <strong>{{date}}</strong>',
    'encart_dlc_tooltip': 'Same day best before date for same day preparation',
    'indisponible': {
      'rupture_partielle': 'Only <strong>{{quantite_max}}</strong> is available <i>(requested quantity: {{quantite_demande}})</i>',
      'rupture_partielle_one': 'Only <strong>{{quantite_max}}</strong> is available <i>(requested quantity: {{quantite_demande}})</i>',
      'rupture_partielle_other': 'Only <strong>{{quantite_max}}</strong> are available <i>(requested quantity: {{quantite_demande}})</i>',
      'rupture_partielle_plural': 'Only <strong>{{quantite_max}}</strong> are available <i>(requested quantity: {{quantite_demande}})</i>',
      'rupture': 'Out of stock ({{quantite}} {{unite}} in cart)',
      'generique': 'An issue prevents ordering this item.',
      'date_dispo': 'Available from <strong>{{date}}</strong>',
      'remplacer': 'Replace',
      'differer': 'Defer',
      'ajuster_quantite': 'Adjust quantity to {{quantite}} {{unite}}',
      'tooltip_ajuster_quantite': 'Adjust to {{quantite}} {{unite}} in cart',
      'tooltip_rupture_totale': 'Remove from cart',
      'entete': '<strong>{{count}} product</strong> is unavailable for <strong>{{dateDeLivraison}}</strong>',
      'entete_one': '<strong>{{count}} product</strong> is unavailable for <strong>{{dateDeLivraison}}</strong>',
      'entete_other': '<strong>{{count}} products</strong> are unavailable for <strong>{{dateDeLivraison}}</strong>',
      'entete_plural': '<strong>{{count}} products</strong> are unavailable for <strong>{{dateDeLivraison}}</strong>',
      'produit_indisponible': 'Product unavailable for {{dateDeLivraison}}'
    }
  },
  'disponibilite': {
    'precommande': 'Pre-order',
    'a_partir': 'From {{date}}'
  },
  'modale_livraison': {
    'header': {
      'preco': 'Choose your delivery date',
      'changement_panier': 'Choose another delivery date',
      'default': 'Order for another date'
    },
    'preco': {
      'title': 'Pre-order product',
      'info': 'This product will only be available from the dates below.',
      'date': 'Available dates after {{dateDebut, datetime}}:'
    },
    'valider_date': 'Change delivery date',
    'afficher_plus': 'Show more dates',
    'nous-requetons-les-disponibilites-de-larticle': 'We are requesting product availability'
  },
  'erreur404': {
    'alt_img_carton': 'empty box with "Oops!" written at the bottom',
    'c_est_bien_vide_ici': "Hmm... It's quite empty here",
    'pas_de_panique': "Don't panic, this way to fill it:",
    'voir_cadencier': 'View my Reorder List',
    'voir_catalogue': 'View catalog',
    'retour_accueil': 'Return to home'
  },
  'error': {
    'introuvable_en_db': 'The item cannot be found in the database.',
    'cgv_requise': 'Accepting the general terms of sale is required.',
    'echec_call_api': 'An error has occurred.',
    'item_catalogue_introuvable': 'Article not found in catalog.',
    'quantite_invalide': 'The quantity is not a customer order multiple.',
    'code_adresse_de_livraison_inconnu': "This delivery address doesn't exist for this customer.",
    'livraison_introuvable': 'No delivery for this date and this customer.',
    'quantite_en_colis_indisponible': 'The package quantity is not available for this item.',
    'prix_a_zero': 'The catalog price for this item is not specified.',
    'label_asset_introuvable': 'Image loading failed.',
    'probleme_bloquant_sur_commande': 'Order registration failed, check your cart.',
    'article_deja_present_dans_le_panier': 'The item is already in the cart for the target delivery.',
    'changement_mode_de_rendu_impossible': 'Unable to change rendering mode for this delivery.',
    'doublon_liste': 'The product is already in this list.',
    'quantite_max_depassee': 'Insufficient stock quantity for product <strong>{{libelle}}</strong><br />Maximum orderable: <strong>{{max}} {{unite}}</strong>.',
    'article_inconnu': 'Article information not found.',
    'medias_non_touves': 'Problem retrieving media associated with the article.',
    'panier_introuvable_en_db': 'This cart cannot be found,<br /><strong><Reload>please refresh your page.</Reload></strong>.',
    'ligne_de_panier_introuvable_en_db': 'This cart line cannot be found,<br /><strong><Reload>please refresh your page.</Reload></strong>.',
    'panier_tolede_mode_desactive': 'Converting a cart to a Tolede order is not authorized.',
    'cadencier_tolede_mode_desactive': 'Actions on the Reorder List are not authorized.',
    'colis_ajuste': 'The quantity has been adjusted to the nearest package.',
    'controle_panier': 'An error occurred while validating the cart.<br />Please try again later.',
    'commande_panier': 'The cart could not be validated, please try again later.<br /><Contact>If the problem persists, please contact us</Contact>.',
    'controle_dispo': 'We could not verify the availability of the item.<br />Please try again later.',
    'client_bloque': 'You cannot place orders from this account, <Contact>please contact us.</Contact>.',
    'generique': 'An error in the order prevents its processing.',
    'quantite_trop_importante': 'Quantity too large for one or more products.',
    'rupture': 'Product {{libelle}} is out of stock.',
    'erreur_date_livraison': 'The delivery date is earlier than the current date. Modify your order date.',
    'deja_au_cadencier': 'The item is already in the Reorder List.',
    "la valeur d'une clé dupliquée rompt la contrainte unique « liste_selection_pkey »": '$t(error.doublon_liste)',
    'duplicate key value violates unique constraint "liste_selection_pkey"': '$t(error.doublon_liste)',
    'dispo': {
      'erreur_generique': 'An error related to item availability has occurred. Check your cart.',
      'erreur_quantite_insuffisante': 'Insufficient stock for this item. Check your cart.'
    },
    'date_differer_manquante': 'Error: The next delivery date for this item has not been communicated',
    'rupture_article': 'The desired quantity for your item is unavailable.',
    'no_client': 'No customer for this commercial company.',
    'no_tournee': 'No delivery route scheduled for this commercial company.',
    'article_introuvable': 'Article non trouvé dans le base.'
  },
  'unite': {
    'B': 'bottle',
    'B_one': 'bottle',
    'B_other': 'bottles',
    'B_many': '$t(unite.B_other)',
    'B_plural': '$t(unite.B_other)',
    'BA': 'tray',
    'BA_one': 'tray',
    'BA_other': 'trays',
    'BA_many': '$t(unite.BA_other)',
    'BA_plural': '$t(unite.BA_other)',
    'BO': 'jar',
    'BO_one': 'jar',
    'BO_other': 'jars',
    'BO_many': '$t(unite.BO_other)',
    'BO_plural': '$t(unite.BO_other)',
    'BT': 'can',
    'BT_one': 'can',
    'BT_other': 'cans',
    'BT_many': '$t(unite.BT_other)',
    'BT_plural': '$t(unite.BT_other)',
    'BX': 'box',
    'BX_one': 'box',
    'BX_other': 'boxes',
    'BX_many': '$t(unite.BX_other)',
    'BX_plural': '$t(unite.BX_other)',
    'CO': 'package',
    'CO_one': 'package',
    'CO_other': 'packages',
    'CO_many': '$t(unite.CO_other)',
    'CO_plural': '$t(unite.CO_other)',
    'CT': 'Hundred',
    'CT_one': 'Hundred',
    'CT_other': 'Hundred',
    'CT_many': '$t(unite.CT_other)',
    'CT_plural': '$t(unite.CT_other)',
    'FU': 'keg',
    'FU_one': 'keg',
    'FU_other': 'kegs',
    'FU_many': '$t(unite.FU_other)',
    'FU_plural': '$t(unite.FU_other)',
    'KG': 'kg',
    'KG_one': 'kg',
    'KG_other': 'kg',
    'KG_many': '$t(unite.KG_other)',
    'KG_plural': '$t(unite.KG_other)',
    'L': 'liter',
    'L_one': 'liter',
    'L_other': 'liters',
    'L_many': '$t(unite.L_other)',
    'L_plural': '$t(unite.L_other)',
    'LO': 'lot',
    'LO_one': 'lot',
    'LO_other': 'lots',
    'LO_many': '$t(unite.LO_other)',
    'LO_plural': '$t(unite.LO_other)',
    'MI': 'Thousand',
    'MI_one': 'Thousand',
    'MI_other': 'Thousands',
    'MI_many': '$t(unite.MI_other)',
    'MI_plural': '$t(unite.MI_other)',
    'PI': 'piece',
    'PI_one': 'piece',
    'PI_other': 'pieces',
    'PI_many': '$t(unite.PI_other)',
    'PI_plural': '$t(unite.PI_other)',
    'PO': 'portion',
    'PO_one': 'portion',
    'PO_other': 'portions',
    'PO_many': '$t(unite.PO_other)',
    'PO_plural': '$t(unite.PO_other)',
    'PT': 'tray',
    'PT_one': 'tray',
    'PT_other': 'trays',
    'PT_many': '$t(unite.PT_other)',
    'PT_plural': '$t(unite.PT_other)',
    'SA': 'bag',
    'SA_one': 'bag',
    'SA_other': 'bags',
    'SA_many': '$t(unite.SA_other)',
    'SA_plural': '$t(unite.SA_other)',
    'SO': 'bucket',
    'SO_one': 'bucket',
    'SO_other': 'buckets',
    'SO_many': '$t(unite.SO_other)',
    'SO_plural': '$t(unite.SO_other)',
    'colis': 'package',
    'colis_one': 'package',
    'colis_other': 'packages',
    'colis_many': '$t(unite.colis_other)',
    'colis_plural': '$t(unite.colis_other)'
  },
  'month': {
    '1': 'January',
    '2': 'February',
    '3': 'March',
    '4': 'April',
    '5': 'May',
    '6': 'June',
    '7': 'July',
    '8': 'August',
    '9': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
  },
  'countries': {
    'FR': 'France',
    'ES': 'Spain',
    'IT': 'Italy',
    'DE': 'Germany',
    'GB': 'United Kingdom',
    'US': 'United States',
    'CN': 'China',
    'IN': 'India',
    'JP': 'Japan',
    'BR': 'Brazil',
    'RU': 'Russia',
    'CA': 'Canada',
    'AU': 'Australia',
    'KR': 'South Korea',
    'MX': 'Mexico',
    'ID': 'Indonesia',
    'TR': 'Turkey',
    'NL': 'Netherlands',
    'SA': 'Saudi Arabia',
    'CH': 'Switzerland',
    'PL': 'Poland',
    'SE': 'Sweden',
    'BE': 'Belgium',
    'AR': 'Argentina',
    'TH': 'Thailand',
    'AT': 'Austria',
    'NO': 'Norway',
    'IR': 'Iran',
    'ZA': 'South Africa',
    'DK': 'Denmark',
    'SG': 'Singapore',
    'MY': 'Malaysia',
    'CL': 'Chile',
    'UA': 'Ukraine',
    'SK': 'Slovakia',
    'BG': 'Bulgaria',
    'EE': 'Estonia',
    'LT': 'Lithuania',
    'NZ': 'New Zealand',
    'LV': 'Latvia',
    'CZ': 'Czech Republic',
    'IS': 'Iceland',
    'RO': 'Romania',
    'HR': 'Croatia',
    'MT': 'Malta',
    'HU': 'Hungary',
    'CY': 'Cyprus',
    'SI': 'Slovenia',
    'PT': 'Portugal',
    'IE': 'Ireland',
    'GR': 'Greece',
    'FI': 'Finland',
    'LU': 'Luxembourg',
    'MA': 'Morocco',
    'BQ': 'Caribbean Netherlands',
    'IO': 'British Indian Ocean Territory',
    'EG': 'Egypt',
    'BD': 'Bangladesh',
    'MG': 'Madagascar',
    'BA': 'Bosnia and Herzegovina',
    'VN': 'Vietnam',
    'PE': 'Peru',
    'CI': 'Ivory Coast',
    'GL': 'Greenland',
    'LI': 'Liechtenstein',
    'AM': 'Armenia',
    'MN': 'Mongolia',
    'MD': 'Moldova',
    'UY': 'Uruguay',
    'RS': 'Serbia',
    'GE': 'Georgia',
    'FO': 'Faroe Islands',
    'GP': 'Guadeloupe',
    'PH': 'Philippines',
    'BO': 'Bolivia',
    'TN': 'Tunisia',
    'MU': 'Mauritius'
  },
  'menu': {
    'compte': 'My account',
    'informations': 'My information',
    'commandes': 'My orders',
    'factures_et_avoirs': 'My invoices',
    'bons_de_livraison': 'My delivery notes',
    'preferences': 'My preferences',
    'changer_de_client': 'Change customer',
    'deconnecter': 'Log out',
    'contacts': 'My contacts'
  },
  'menu_livraison': {
    'tooltip': 'Click to order for another date.',
    'livraison': 'Delivery on <strong>{{date}}</strong>'
  },
  'bon_de_livraison': {
    'numero_de_commande': 'Order number',
    'date_de_commande': 'Order date',
    'total_de_commande': 'Order total',
    'bon_de_livraison_titre': 'Delivery note',
    'commande': 'Order',
    'action': 'Action',
    'telecharger': 'Download',
    'telecharger_tout': 'Download all',
    'bon_de_livraison': '{{count}} DELIVERY NOTE',
    'bon_de_livraison_one': '{{count}} DELIVERY NOTE',
    'bon_de_livraison_other': '{{count}} DELIVERY NOTES',
    'bon_de_livraison_many': '{{count}} DELIVERY NOTES',
    'bon_de_livraison_plural': '{{count}} DELIVERY NOTES',
    '2_derniers_mois': 'Last 2 months',
    '3_6_mois': '3 to 6 months',
    'N°': 'No.',
    'aucun_bon_de_livraison': 'You have no delivery notes for this period.'
  },
  'liste': {
    'mes_listes': 'My lists',
    'produits_par_saison': 'My seasonal products',
    'offre_commercial': 'Recommended for me',
    'creer_liste': 'Create a new list',
    'veuillez_entrer_nom_liste': 'Please enter the name of the new list',
    'veuillez_entrer_nouveau_nom_liste': 'Please enter the new list name',
    'nom_liste_placeholder': 'New list name',
    'liste_supprimee': 'The list {{nom}} has been deleted',
    'liste_renommee': 'The List has been renamed',
    'syntaxe_nom_liste': 'Only letters, numbers and spaces are allowed',
    'ajouter_a_une_liste': 'Add to a list',
    'ajouter_au_cadencier': 'Add to Reorder List',
    'aria_label_modifier': 'Modify list name',
    'aria_label_enregistrer': 'Save new list name',
    'aria_label_renommer': 'Rename list',
    'chargement_listes': 'Loading your lists',
    'ajouter_article': 'Add an item',
    'veuillez-selectionner-ou-ajouter-larticle': 'Please select where to add the item',
    'editer_nom_liste': 'Edit list name',
    'supprimer_liste_confirm': 'Are you sure you want to delete this list?',
    'supprimer_liste_action': 'Delete list',
    'mois': {
      'tout': 'All',
    },
    'par_periode': 'By period',
    'categorie': 'Category',
    'frequence_achat': 'Purchase frequency',
    'filtre': {
      'selection_date': 'Select a period',
      'date_debut': 'Start date',
      'date_fin': 'End date',
      'appliquer': 'Apply',
      'reinitialiser': 'Reset',
    },
    'ajouter_a_la_liste': 'Add to list',
    'dans_la_liste': 'In the list',
    'aucun_produit': 'No product in this list',
    'selection_liste': 'Select a list',
    'ajouter_produits': 'Add products to:',
    'produit_ajoute': 'Product added to the list',
    'aucune_liste': 'No list selected',
    'commande_x_fois': 'Ordered {{count}} times',
    'produit_deja_dans_liste': 'Product already in the list',
    'selectionner_liste_tooltip': 'Select a list to add the product',
    'quantite_saisonniere_tooltip': 'Sum of deliveries over the selected period'
  },
  'back_office': {
    'unauthorized': 'You must be logged in with your France Frais email.',
    'clients_bloques': 'Blocked',
    'client_actif': 'Open',
    'clients_ouverts': 'Open Status',
    'chercher_client': 'Search for a customer',
    'metier': 'Business',
    'liste_clients': 'Customer list',
    'clients_avec_panier': 'With open cart',
    'connexion_ffconnect': 'FFConnect connections',
    'connexion_ffconnect_tooltip': 'Latest connections to France Frais Connect',
    'derniere_connexion': 'Last connection on {{date}}',
    'adresse_depot': 'Pickup warehouse address',
    'adresse_magasin': 'Store address',
    'liste_societes_commerciales': 'List of commercial companies',
    'nom_etablissement': 'Establishment name',
    'filiale': 'subsidiary',
    'paniers': 'Carts',
    'panier_en_cours': 'Current cart',
    'statut': 'status',
    'nombre_commandes': 'Number of orders',
    'statut_tooltip': 'Tolede business rule to determine if customer can order in Tolede or not (independent of digital aspect)',
    'paniers_tooltip': 'Number of open carts',
    'cgv': 'Terms and Conditions',
    'pas_de_client': 'No customer',
    'prochaine_date_livraison': 'Next delivery on {{date_de_livraison}}',
    'cutoff_non_disponible': 'Cut off not available',
    'selection_commercial': 'By Sales Representative',
    'rafraichir_clients': 'Refresh customer list',
    'rafraichir_clients_tooltip': "Refreshes the company's customer list.",
    'invitation_envoyee_le': 'Invitation sent on {{date}}',
    'relance_envoyee_le': 'Reminder sent on {{date}}',
    'email_ouvert_le_tooltip': 'Seen on {{date}}',
    'envoyer_relance_tooltip': 'Send a reminder to {{email}}',
    'envoyer_invitation_tooltip': 'Send an invitation to {{email}}',
    'relance_message_placeholder': 'Your personalized message... (optional)',
    'envoyer_email_relance': 'Send email',
    'relance_titre': 'Reminder to order on France Frais connect',
    'liste_emails_relance': 'To:',
    'historique_invitation': 'Invitation history',
    'historique_relance': 'Reminder history',
    'historique_emails_titre': 'Email history',
    'email_ouvert_le': 'email opened on {{date}}',
    'pas_email': 'contact without email',
    'pas_historique_email': 'No email history',
    'connection_ffconnect_tooltip': {
      'derniere_connexion': 'Latest FFConnect connections',
      'bouton_tete': ': allows sending an email to contacts who have never connected to invite them to create their FFConnect account.',
      'bouton_mail': ": allows sending a reminder email to contacts to invite them to order on FFConnect (contact who has already connected but customer who hasn't placed an order in the last 30 days). Before sending the email you can add a personalized message."
    },
    'multicanal': 'Active multi-channel',
    'activation': 'Activation',
    'activation_tooltip': 'Activation Status',
    'jamais_validé': 'Never validated',
    'details_client': {
      'assistance_client': 'Customer support',
      'informations_livraison': 'Delivery information',
      'informations_client': 'Customer information',
      'contacts': 'Contacts',
      'code_client': 'Customer account',
      'paniers': 'Carts',
      'panier_en_cours': 'Current cart',
      'pas_de_panier_en_cours': 'No current cart',
      'information_a_renseigner': 'Information must be entered in Tolede.',
      'rafraichissement': 'Refreshing...',
      'rafraichir_information': 'Refresh all information',
      'rafraichir_information_tooltip': 'Refreshes all information (contacts, catalog, delivery routes, Reorder List, ...).',
      'affiche_dlc_modifie': 'The display of expiration dates has been modified.',
      'affichage_dlc': 'Show expiration dates',
      'affiche_produit_marche_modifie': 'The market product display has been modified.',
      'affichage_produit_marche': 'Show Market Products',
      'denomination_sociale': 'Company name',
      'pas_de_contact': 'No contact provided',
      'cgv_tooltip': 'Terms acceptance date',
      'message': 'Message',
      'date': 'Event date',
      'telecharger': 'Payload',
      'identifiant_panier': 'Cart ID',
      'statut_panier': 'Cart status',
      'quinze_derniers_jours': 'Over the last 15 days.',
      'validé': 'Validated',
      'nombre_produits': 'Number of products',
      'no_events': 'No cart validation or validation failure in the last 30 days.'
    }
  },
  'metier': {
    'A1': 'SOCIAL CATERING',
    'A2': 'COMMERCIAL CATERING',
    'A3': 'TO REASSIGN B1 B2 B3 A2',
    'A4': 'STORE AND GM',
    'A5': 'SUBSIDIARIES / PERSONNEL',
    'A6': 'INDUSTRIAL WHOLESALERS',
    'B1': 'BAKERY PASTRY',
    'B2': 'CREAMERIES',
    'B3': 'BUTCHER DELICATESSEN CATERER',
    'B4': 'OTHER ARTISANS MERCHANTS',
    'E1': 'EXPORT TO RECLASSIFY',
    'P1': 'INDIVIDUALS'
  },
  'activation': {
    'aucun_email': 'No email',
    'aucun_email_color': 'red.fail',
    'non_connecte': 'Not connected',
    'non_connecte_color': 'red.fail',
    'aucun_panier': 'No cart',
    'aucun_panier_color': 'orange.warning',
    'aucune_commande': 'No web order',
    'aucune_commande_color': 'orange.warning',
    'actif': 'Active',
    'actif_color': 'green.success'
  },
  'mes_informations': {
    'modification_alerte': 'Would you like to make a change?',
    'contacter_commercial': 'Contact your sales representative',
    'votre_commercial': 'Your sales representative',
    'mes_adresses': 'My addresses',
    'adresses_de_facturation': 'Billing addresses',
    'adresses_de_livraison': 'Delivery addresses',
    'adresse_de_retrait': 'Pickup address',
    'mon_identite': 'My identity',
    'mon_entreprise': 'My company',
    'mes_coordonnees': 'My contact details',
    'numero_de_compte': 'Account number:',
    'siret': 'SIRET:',
    'tva': 'VAT:',
    'par_mail': 'By email',
    'adresses_enlevement': 'Pickup addresses',
    'date_de_livraison': 'Delivery date:',
    'cut_off_de_preparation': 'Preparation cut off:',
    'les_tournees': 'Delivery routes',
    'tournee_enlevement': 'Pickup route #{{numero}}',
    'tournee_livrante': 'Delivery route #{{numero}}'
  },
  'contact': {
    'societe_commerciale': 'Commercial company',
    'televente': 'Telesales',
    'commercial': 'Sales representative',
    'copier_email': 'Copy email',
    'non_renseigne': 'Not specified'
  },
  'commande': {
    'nombre_commandes': '{{count}} ORDER',
    'nombre_commandes_one': '{{count}} ORDER',
    'nombre_commandes_other': '{{count}} ORDERS',
    'nombre_commandes_many': '{{count}} ORDERS',
    'nombre_commandes_plural': '{{count}} ORDERS',
    'commande': 'ORDER',
    'commande_one': 'ORDER',
    'commande_other': 'ORDERS',
    'commande_plural': 'ORDERS',
    '2_derniers_mois': 'Last 2 months',
    '3_6_mois': '3 to 6 months',
    'en_cours': 'In progress',
    'livrees': 'Delivered',
    'livree': 'Delivered',
    'annulees': 'Cancelled',
    'en_cours_de_traitement': 'Processing',
    'voir_le_detail': 'View details',
    'non_definie': 'Undefined',
    'saisie': 'Entered',
    'annulee': 'Cancelled',
    'ferme': 'Closed',
    'mise_en_prepa': 'In preparation',
    'prepa_en_cours': 'Preparation in progress',
    'prepa_terminee': 'Preparation completed',
    'facturee': 'Invoiced',
    'total': 'Total',
    'total_ttc': 'Total including tax',
    'total_ht': 'Total excluding tax',
    'total_tva': 'Total VAT',
    'livraison_du': 'Delivery on',
    'date_de_commande': 'Order date',
    'passee_le': 'Order placed on',
    'adresse_facturation': 'Billing address',
    'adresse_livraison': 'Delivery address',
    'reference_de_commande': 'Order reference',
    'ajouter_produit': 'To add a product',
    'supprimer_produit_changer_quantite': 'To remove a product, change quantities',
    'passer_nouvelle_commande': 'Place a new order',
    'contacter_conseiller': 'Contact my advisor',
    'vous_etes_trompe': 'Made a mistake?',
    'on_vous_accompagne': "We'll help you.",
    'bon_de_commande': 'Purchase order',
    'details_de_ma_commande': 'My order details',
    'frais_de_livraison': 'Delivery fees',
    'n°': 'no.',
    'documents_non_disponibles': 'Documents not available',
    'modale_changer_adresse_header': 'Select your service',
    'digital': 'France Frais Connect',
    'ancien_web': 'Old Web',
    'televente': 'Telesales',
    'commercial': 'Sales representative',
    'article': '{{count}} item',
    'article_one': '{{count}} item',
    'article_other': '{{count}} items',
    'article_many': '{{count}} items',
    'article_plural': '{{count}} items',
    'remplacer_article': 'Replace <strong>{{nom}}</strong>',
    'chercher_tout_catalogue': 'Search entire catalog',
    'remplacement_aucun_article': 'Sorry, no replacement item was found',
    'par_multiple': 'by {{quantite}} {{unite}}',
    'par_colis': 'by package of {{quantite}} {{unite}}',
    'de_multiple_facturation': 'of {{multiple_facturation}} {{unite_facturation}}',
    'ajouter_cette_commande_panier': 'Add this order to cart',
    'vous_souhaitez_renouveler_commande': 'Would you like to renew this order?',
    'gagner_temps': 'Add all products from this order with their quantities in one click!',
    'a_payer': 'To pay',
    'payee': 'Paid',
    'aucune_commande': 'You have no orders for this period',
    'tooltip_tva': 'VAT amount',
    'produits': 'Products',
    'statut': 'Status',
    'statut_tooltip': 'Order status',
    'actions': 'Actions',
    'deja_commande': 'Already ordered:',
    'pour_livraison_du': 'For your delivery on {{date}}',
    'type': 'Order type',
    'articles_ajouter_au_panier': 'Products have been added to the cart with the same quantities.',
    'AF': 'CREDIT',
    'AV': 'CREDIT',
    'CA': 'ORDER',
    'CO': 'ORDER',
    'FC': 'ORDER',
    'FF': 'ADJUSTMENT',
    'FQ': 'ADJUSTMENT',
    'MD': 'MULTI RECIPIENT',
    'PD': 'ORDER',
    'PH': 'HISTORICAL FORECAST',
    'PR': 'FORECAST'
  },
  'factures_avoirs': {
    'facture': 'Invoice',
    'avoir': 'Credit note',
    'factures_et_avoirs': '{{count}} INVOICE AND CREDIT NOTE',
    'factures_et_avoirs_one': '{{count}} INVOICE AND CREDIT NOTE',
    'factures_et_avoirs_other': '{{count}} INVOICES AND CREDIT NOTES',
    'factures_et_avoirs_many': '{{count}} INVOICES AND CREDIT NOTES',
    'factures_et_avoirs_plural': '{{count}} INVOICES AND CREDIT NOTES',
    'facture_n°': 'Invoice no.',
    'date_emission': 'Issue date',
    'echeance': 'Due date',
    'total_ttc': 'Total including tax',
    'total_ht': 'Total excluding tax',
    'statut': 'Status',
    'action': 'Action',
    'telecharger': 'Download',
    'telecharger_tout': 'Download all',
    'n°': 'no.',
    'numero_de_facture': 'Invoice number',
    'factures': 'Invoices',
    'avoirs': 'Credit notes',
    'alerte': 'Warning! Invoice status is only updated once per day.',
    'aucune_facture_ou_avoir': 'No invoice or credit note available for this period.',
    '2_derniers_mois': 'Last 2 months',
    '6_derniers_mois': 'Last 6 months',
    '12_derniers_mois': 'Last 12 months'
  },
  'recherche': {
    'resultat_pour_mobile': 'Results for',
    'resultat_pour_desktop': 'Search results for'
  },
  'fiche_produit': {
    'table': {
      'ingredient_nutrition': 'Ingredients and nutrition',
      'informations_nutritionelles': 'Nutritional information',
      'pour100gml': 'Per 100 g/ml',
      'ajr': 'Recommended daily allowance (%)',
      'ingredients_manquants': 'The ingredients list has not yet been provided.',
      'allergenes': 'Allergens:'
    },
    'fiche_produit_pdf': {
      'telecharger_fiche_produit': 'Download product sheet.',
      'fiche_produit_non_disponible': 'Product sheet not available'
    }
  },
  'select_client': {
    'greeting': 'Great to see you again!',
    'selectionnez_compte': 'Select a customer account to continue',
    'client_de': 'Customer of {{nom_societe}}'
  },
  'catalogue': {
    'chargement': 'Loading your catalog',
    'aucun_produit': 'No products in this section!',
    'les_promotions': 'Promotions',
    'les_indispensables': 'Essentials',
    'indispensables': 'Essentials',
    'promotions': 'Promotions',
    'promotion_du_moment': 'Current promotions',
    'voir_tout_promotion': 'See all promotions',
    'voir_tout': 'See all',
    'plus_de_produit': 'More products',
    'commander_maintenant': 'Order now',
    'commander_maintenant_pour': 'Order now for',
    'produits_marque_proprietaire': 'Essentials',
    'chercher_produit': 'Search for a product: {{min_lettres}} letters minimum',
    'fin_liste': 'End of your catalog',
    'filtre': {
      'promotions': 'Promos',
      'produit_marche': 'Market products',
      'promo': 'Promo',
      'labels': 'Labels',
      'origine': 'Origin',
      'a-z': 'A - Z',
      'pertinence': 'Relevance',
      'tri': 'Sort:',
      'filtrer': 'Filter',
      'trier': 'Sort',
      'afficher': 'Display',
      'tout_effacer': 'Clear all',
      'non_surgele_surgele': 'Non-frozen/Frozen',
      'non_surgele': 'Non-frozen',
      'surgele': 'Frozen',
      'effacer': 'Clear all filters'
    }
  },
  'accueil': {
    'bonjour': 'Hello {{nom}}!',
    'bienvenue': 'Hello {{nom}}, welcome to {{nom_societe}}!',
    'chargement_catalogue': 'We are loading your catalog. During your first connection, this may take 2 minutes, thank you for your patience.',
    'chargement_tournees': 'Loading your delivery routes',
    'compte_bloque': 'Your account is not configured to take orders, please contact your representative.',
    'mes_paniers_en_cours': 'My current carts ({{nombre_paniers}})',
    'paniers_en_cours': 'Current carts ({{nombre_paniers}})',
    'afficher_paniers': 'Show all my carts',
    'voir_paniers': 'View',
    'pour_livraison': 'For delivery on'
  },
  'footer': {
    'conseiller_ecoute': 'Your advisor at your service',
    'mail': 'By email',
    'telephone': 'By phone',
    'consulter_rayons': 'Browse our departments',
    'nos_rayons': 'Our departments',
    'a_propos': 'About',
    'contactez_nous': 'Contact us',
    'tel': 'Tel.',
    'e_mail': 'Email.',
    'votre_conseiller': 'Your advisor:',
    'mentions_legales': 'Legal notice',
    'engagements': 'Our commitments',
    'politique_confidentialite': 'Privacy policy',
    'gestion_cookies': 'Cookie management',
    'cgv': 'Terms and Conditions',
    'cgu': 'Terms of Use'
  },
  'modale_nomenclature': {
    'chargement': 'Loading your menu'
  },
  'modale_enlevement': {
    'contacter_representant': "To organize the pickup of your goods, don't forget to contact your representative"
  },
  'modale_differer': {
    'deplacement': 'Your product will be <strong>moved to the cart for {{date_de_livraison}}</strong>'
  },
  'preferences': {
    'alertes': 'My order alerts',
    'activer': 'Enable all',
    'email': 'Email',
    'mobile': 'Mobile app',
    'livraison': 'Next delivery reminder (the day before)',
    'livraison_mobile': 'Next delivery reminder<br />(the day before)',
    'paniers': 'Cart validation reminder'
  },
  'session': {
    'session_bientot_expiree': 'Session expiring soon',
    'prolonger_session': 'Extend session',
    'prolongation': 'Extending...',
    'delai_expiration': 'Your session will expire in {{minutes}} minutes.'
  },
  'form': {
    'societe_commerciale': {
      'titleAjouter': 'Add a commercial company',
      'titleModifier': 'Modify a commercial company',
      'dropzone': 'Drop your file or click on the area',
      'ajouter': 'Add',
      'modifier': 'Modify',
      'ajoutee': 'The commercial company has been registered.',
      'modifiee': 'The commercial company has been modified.',
      'supprimer': 'Delete',
      'ajouter_lien': 'Add a link',
      'annuler': 'Cancel',
      'valider': 'Confirm',
      'general': 'General information',
      'id_base_label': 'Base ID',
      'id_base_placeholder': 'Select base ID',
      'code_societe_commerciale_label': 'Commercial company code',
      'code_societe_commerciale_placeholder': 'Select company code',
      'nom_commercial_label': 'Trade name',
      'nom_commercial_placeholder': 'Company trade name',
      'ffconnect': 'FFConnect information',
      'permettre_commande_retrait_label': 'Allow order pickup',
      'message_validation_livraison_label': 'Delivery validation message',
      'message_validation_livraison_placeholder': 'Message for delivery validation',
      'message_validation_enlevement_label': 'Pickup validation message',
      'message_validation_enlevement_placeholder': 'Message for warehouse pickup validation',
      'contact': 'Contact information',
      'adresse_label': 'Address',
      'adresse_placeholder': 'Company address',
      'telephone_label': 'Phone',
      'telephone_placeholder': 'Company phone',
      'email_label': 'Email',
      'email_placeholder': 'Company email',
      'visuel': 'Visual information',
      'couleur_label': 'Company main color',
      'logo_label': 'Company logo (jpg or png)',
      'cgv_label': 'Company Terms and Conditions (pdf)',
      'cgu_label': 'Company Terms of Use (pdf)',
      'footer': 'Footer information',
      'url_lien': 'URL for the link',
      'label_lien': 'Link label'
    }
  },
  'assistance': {
    'banniere': 'You are acting on the account {{raison_sociale}}'
  },
  'kpis': {
    'voir_les': 'View the',
    'erreur': 'Error loading KPIs',
    'total_clients': 'Total customers',
    'nb_connectes': 'Connected customers',
    'nb_connectes_tooltip': 'Customers who connected to FFConnect in the last 30 days.',
    'non_connecte': 'not connected',
    'non_connecte_tooltip': 'Customers who have not connected to FFConnect in the last 30 days.',
    'avec_panier': 'Who created a cart',
    'avec_panier_tooltip': 'Customers who opened a cart in the last 30 days.',
    'sans_panier': 'no cart',
    'sans_panier_tooltip': "Customers who haven't created a cart on FFConnect in the last 30 days.",
    'panier_valide': 'Who validated a cart',
    'panier_valide_tooltip': 'Customers who placed an order in the last 30 days.',
    'sans_commande': 'no web order',
    'sans_commande_tooltip': "Customers who haven't ordered on FFConnect in the last 30 days.",
    'tendance_tooltip': 'Trend over the last 30 days compared to the previous 30 days.'
  },
  'analytics': {
    'modale_consentement_titre': 'Would you like to contribute to improving France Frais Connect by sharing usage statistics?'
  },
  'maintenance': {
    'title': 'Maintenance in progress',
    'contact_support': 'For any questions, please contact technical support.'
  },
  'historique_livraison': {
    'aucun_historique': 'No purchase history available.',
    'historique_achat': 'Purchase history',
    'historique_achat_tooltip': 'Purchase history of the product over the last 90 days.'
  },
  'calendar': {
    'weekdays': {
      'short': ['S', 'M', 'T', 'W', 'T', 'F', 'S']
    }
  },
};
