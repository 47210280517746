import {useContext} from 'react';
import {BackofficeClientContext} from './BackofficeClientContext';

// Hook personnalisé pour utiliser le contexte
export const useBackofficeClient = (fallback = true) => {
  const context = useContext(BackofficeClientContext);
  if (!context) {
    if (fallback) {
      // Retourner null au lieu de lever une erreur
      return null;
    }
    throw new Error('useBackofficeClient doit être utilisé dans un BackofficeClientContextProvider');
  }
  return context.client;
};
