import {Client, ShortMemoryStorage, middlewares} from '@synako/halitrek';

export function createClient(language: string) {
  const client = new Client(new URL(import.meta.env.VITE_API_ROOT_URL, window.location.toString()).toString());
  // pour éviter une perte de cache trop rapide, on allonge sa durée de vie à 20 minutes
  client.stateStorage = new ShortMemoryStorage(1200000);
  client.fetcher.use(middlewares.acceptLanguage({language}));
  client.fetcher.use(middlewares.credentials('include'));
  client.fetcher.use(middlewares.checkContentType(['application/json', 'application/problem+json']));
  // TODO : à réfléchir : un middleware front pour catcher tous les erreurs ? ou alors à gérer ailleurs ?
  //Redirect on 503
  client.fetcher.use(async (request, next) => {
    const res = await next(request);
    if (res.status === 503){
      window.location.href = '/';
    }
    return res;
  });
  return client;
}
