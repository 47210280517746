import {Icon} from '@chakra-ui/react';

export const RotateSyncIcon: React.FC<{
  width?: string;
  height?: string;
}> = ({width = '16px', height = '16px'}) => {
  const color = '#0072BA';
  return (
    <Icon
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_rotate_sync)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 9.33268C0 8.96449 0.298477 8.66602 0.666667 8.66602H4.66667C5.03486 8.66602 5.33333 8.96449 5.33333 9.33268C5.33333 9.70087 5.03486 9.99935 4.66667 9.99935H1.33333V13.3327C1.33333 13.7009 1.03486 13.9993 0.666667 13.9993C0.298477 13.9993 0 13.7009 0 13.3327V9.33268Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3334 2C15.7016 2 16.0001 2.29848 16.0001 2.66667V6.66667C16.0001 7.03486 15.7016 7.33333 15.3334 7.33333H11.3334C10.9652 7.33333 10.6667 7.03486 10.6667 6.66667C10.6667 6.29848 10.9652 6 11.3334 6H14.6667V2.66667C14.6667 2.29848 14.9652 2 15.3334 2Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.47822 2.87734C8.61278 2.62701 7.69802 2.59999 6.81932 2.79881C5.94061 2.99763 5.12658 3.4158 4.4532 4.01431C3.77982 4.61282 3.26902 5.37216 2.96848 6.22147C2.84565 6.56857 2.4647 6.75038 2.1176 6.62755C1.77051 6.50472 1.5887 6.12377 1.71152 5.77668C2.0872 4.71504 2.7257 3.76586 3.56743 3.01773C4.40915 2.26959 5.42668 1.74687 6.52507 1.49835C7.62346 1.24983 8.7669 1.2836 9.8487 1.59651C10.9271 1.90843 11.9092 2.48758 12.7039 3.2802L15.7899 6.1799C16.0582 6.43203 16.0713 6.85394 15.8192 7.12226C15.567 7.39058 15.1451 7.4037 14.8768 7.15158L11.7835 4.24491C11.7784 4.24014 11.7734 4.23529 11.7685 4.23036C11.1316 3.59315 10.3437 3.12767 9.47822 2.87734ZM0.180833 8.87589C0.432961 8.60757 0.854867 8.59444 1.12319 8.84657L4.21652 11.7532C4.2216 11.758 4.2266 11.7629 4.23152 11.7678C4.86841 12.405 5.65634 12.8705 6.52178 13.1208C7.38723 13.3711 8.30198 13.3982 9.18069 13.1993C10.0594 13.0005 10.8734 12.5823 11.5468 11.9838C12.2202 11.3853 12.731 10.626 13.0315 9.77668C13.1544 9.42958 13.5353 9.24777 13.8824 9.3706C14.2295 9.49342 14.4113 9.87437 14.2885 10.2215C13.9128 11.2831 13.2743 12.2323 12.4326 12.9804C11.5909 13.7286 10.5733 14.2513 9.47493 14.4998C8.37655 14.7483 7.23311 14.7146 6.1513 14.4016C5.07293 14.0897 4.09085 13.5106 3.29607 12.7179L0.210151 9.81824C-0.0581685 9.56611 -0.0712944 9.14421 0.180833 8.87589Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_rotate_sync">
          <rect width="16"
            height="16"
            fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
