import {add, addMonths, format, formatDistanceToNow, formatISO, isEqual, parseISO, subMonths} from 'date-fns';
import {fr} from 'date-fns/locale';

export const getTempsRestant = (timestamp: string | number) => {
  return formatDistanceToNow(new Date(timestamp));
};

export const dateAvecSlashes = (timestamp: string | number | undefined) => {
  return format(timestamp ? new Date(timestamp) : new Date(), 'dd/MM/yyyy', {locale: fr});
};

export const dateEnTexte = (timestamp: string | number | undefined) => {
  return format(timestamp ? new Date(timestamp) : new Date(), 'do MMMM yyyy', {locale: fr});
};

export const isDateDepassee = (timestampDispo: string | number | undefined, precommande: boolean) => {
  if (!precommande && timestampDispo) {
    const today = new Date();
    const tomorrow = formatISO(add(today, {days: 1}), {representation: 'date'});
    const dateDispo = formatISO(timestampDispo, {representation: 'date'});
    return dateDispo <= tomorrow;
  }
  return false;
};

export const isDateEqual = (d1: Date, d2: Date) => {
  return isEqual(format(d1, 'yyyy-MM-dd'), format(d2, 'yyyy-MM-dd'));
};

export const moisAnneeEnTexte = (dateLivraison: string, monthOffset: number) => {
  const formated = format(addMonths(dateLivraison, monthOffset), 'MMMM yyyy', {locale: fr});
  return `${formated.substring(0, 1).toUpperCase()}${formated.substring(1)}`;
};

export const jourMoisEnTexte = (dateLivraison: string) => {
  return format(dateLivraison, 'do MMMM', {locale: fr});
};

/**
 * Retourne un mapping des noms d'affichage pour les 12 derniers mois
 * @returns Un objet associant les IDs de mois (1-12) aux infos d'affichage
 */
export const getDerniersDouzesMois = () => {
  const today = new Date();
  const mapping: Record<string, {monthNumber: number; year: number; displayName: string}> = {};
  
  // Générer le mapping pour les 12 derniers mois
  for (let i = 0; i < 12; i++) {
    // Nous commençons par le mois le plus ancien (il y a 12 mois) jusqu'au mois actuel
    const date = subMonths(today, 12 - i);
    const monthNumber = date.getMonth() + 1; // 1-12
    const year = date.getFullYear();
    
    // L'ID de mois correspond à sa position (1-12) dans notre liste de 12 mois
    const monthId = (i + 1).toString(); // 1-12
    
    mapping[monthId] = {
      monthNumber, // Le vrai numéro du mois (1-12)
      year,
      displayName: format(date, 'MMMM yyyy', {locale: fr})
    };
  }
  
  return mapping;
};

/**
 * Formate une date pour l'affichage dans les composants
 * @param date Date à formater ou string ISO
 * @param dateFormat Format de la date (par défaut: 'dd MMM yyyy')
 * @param fallback Valeur de remplacement si la date est nulle
 * @returns Date formatée en texte
 */
export const formatDateForDisplay = (date: Date | string | null, dateFormat = 'dd MMM yyyy', fallback = ''): string => {
  if (!date) return fallback;
  
  const dateObj = typeof date === 'string' ? parseISO(date) : date;
  return format(dateObj, dateFormat);
};

/**
 * Calcule le décalage de mois entre deux dates
 * @param date1 Première date (format ISO string ou Date)
 * @param date2 Deuxième date (par défaut: date actuelle)
 * @returns Nombre de mois de différence
 */
export const calculateMonthDifference = (
  date1?: string | Date | null,
  date2: Date = new Date()
): number => {
  if (!date1) return 0;
  
  const d1 = typeof date1 === 'string' ? parseISO(date1) : date1;
  
  return (
    (d1.getFullYear() - date2.getFullYear()) * 12 + 
    (d1.getMonth() - date2.getMonth())
  );
};

/**
 * Calcule le décalage de mois pour afficher une date spécifique ou par défaut le même mois de l'année dernière
 * Utilisé dans la modale plage de dates des listes saisonnières
 * @param targetDate Date cible (optionnel)
 * @returns Décalage de mois pour le calendrier
 */
export const getDefaultMonthOffset = (targetDate?: string | Date | null): number => {
  if (!targetDate) {
    // Afficher le même mois de l'année dernière par défaut
    return -12;
  }
  
  return calculateMonthDifference(targetDate);
};
