import {Box, HStack, Stack, VStack} from '@chakra-ui/layout';
import {CadencierHeader} from './components/CadencierHeader';
import {HaliRenderOne, useHalState, useHalStates} from '@synako/haligator';
import {useCadencier, useClient} from '@/haligator-factory';
import {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  routes,
  useLivraisonsPossiblesContext
} from 'ff-common';

import {ArrowForwardIcon} from '@chakra-ui/icons';
import {useTranslation} from 'react-i18next';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {usePanier} from 'ff-common';
import {BlueButton} from '@/components/Button/BlueButton';
import {Skeleton} from '@chakra-ui/react';
import {CadencierSection} from './controllers/CadencierSection';
import {CadencierTitreNombreDeProduits} from './components/CadencierTitreNombreDeProduits';
import {TemperatureDeLivraison, TriSelection} from 'api-types/ffconnect';
import React from 'react';
import {BreakPointContext} from '@/chakra/BreakpointProvider';
import {FrozenToggle} from './components/FrozenToggle';
import {useCapture} from '@/posthog/PosthogSetup';
import {State} from '@synako/halitrek';
import {ArticlesCadencierSuggestionsAjoutesEventDesc, SuggestionsStateDesc} from 'api-types/ffconnect-hal';
import {CadencierArticle, SortType, TriArticlesCadencier} from '../ligneProduit/TriArticlesCadencier';
import {TriSectionsCadencier} from '../ligneProduit/TriSectionsCadencier';
import {SuggestionActions} from '../ligneProduit/SuggestionActions';

export const MonCadencier: React.FC = () => {
  const cadencierNav = useCadencier();
  const [editMode, setEditMode] = useState<boolean>(false);
  //Lors d'une tournée négative ou positive, affiche ou non les articles livrables
  const [filtreTemperature, setFiltreTemperature] = useState<TemperatureDeLivraison>('les_deux');

  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const tourneeHook = useHalState(livraisonSelectionnee.follow('tournee'));
  const temperature_de_livraison = tourneeHook.data?.tolede.temperature_de_livraison ?? 'les_deux';

  const temperature_de_livraison_selection = filtreTemperature !== 'les_deux';

  // Pour gérer le tri des articles dans chaque section
  const clientNav = useClient();
  const {state: suggestionsState} = useHalState(clientNav.follow('suggestions'));

  // Event concernant l'ajout au panier des articles ayant une suggestion de quantité
  // Permet de remonter l'event pour l'affichage du picto
  const [lastSuggestionEvent, setLastSuggestionEvent] = useState<State<ArticlesCadencierSuggestionsAjoutesEventDesc> | undefined>();
  const handleSuggestionSuccess = (event: State<ArticlesCadencierSuggestionsAjoutesEventDesc>) => {
    setLastSuggestionEvent(event);
  };

  useEffect(() => {
    //Valeur par défaut
    setFiltreTemperature(temperature_de_livraison);
  }, [temperature_de_livraison]);

  // Etat du type de tri des articles
  // Persistance dans le localStorage
  const [sortType, setSortType] = useState<SortType>(() => {
    const saved = localStorage.getItem('cadencier-sort-type');
    return saved ? (saved as SortType) : 'alphabetic';
  });

  useEffect(() => {
    localStorage.setItem('cadencier-sort-type', sortType);
  }, [sortType]);

  return (
    <HaliRenderOne nav={cadencierNav} 
      skeleton={()=><Skeleton width='100%'
        height='10'
        m='5'/>}
      render={({data: cadencier}) => {
        const nombre_articles_filtres = cadencier.tri_selection.reduce((acc, {articles}) => {
          if (filtreTemperature === 'les_deux') return acc + articles.length; 
          return acc + articles.filter((a)=>a.temperature_livraison===filtreTemperature).length;
        }, 0); 
        return (
          <Box bg='gray_bg'
            py={{base:'0', lg:'3'}}
            px={{base:'0', md: '2', xl: '20'}}>
            <VStack bg={{lg:'white'}}
              p={{base:'0', md:'4'}}
              pt={{base:'0', md:'2'}}
              gap={{base:'0',lg:'2'}}
              alignItems='start'
              position='relative'>
              <Stack gap='0'
                flexDirection='column'
                justify='start'
                width='100%'
                alignItems='baseline'
                bg='white'
                display={{base:'block', md:'none'}}
                position='sticky'
                top='114px'
                zIndex='sticky'>
                <CadencierTitreNombreDeProduits nombreDeProduits={nombre_articles_filtres} />
              </Stack>
              <Box id='cadencier_header_container'
                width="100%"
                zIndex='dropdown'
                position={{md:'sticky'}}
                top={{md:'120px'}}
                bg='white'>
                <CadencierHeader
                  nombreDeProduits={
                    nombre_articles_filtres
                  }
                  editMode={editMode}
                  setEditMode={setEditMode}
                  categories={cadencier.tri_selection}
                />
                {
                  temperature_de_livraison!=='les_deux' && <FrozenToggle 
                    temperature_de_livraison={temperature_de_livraison} 
                    isChecked={temperature_de_livraison_selection}
                    onChange={()=>{
                      setFiltreTemperature(temperature_de_livraison_selection ? 'les_deux' : temperature_de_livraison);
                    }}/>
                }
              </Box>
              {/* Bouton pour ajouter au panier tous les articles avec suggestions de quantité */}
              <HStack w='100%'>
                <SuggestionActions onSuggestionSuccess={handleSuggestionSuccess}/>
              </HStack>
              {/* composant pour sélectionner le type de tri des articles par fréquence d’achat ou ordre alphabétique */}
              <HStack w='100%'
                justifyContent='end'
                alignItems='end'>
                <TriSectionsCadencier
                  value={sortType}
                  onChange={setSortType}
                />
              </HStack>
              <Box id='cadencier_body_container'
                width="100%">
                <CadencierBody
                  tri_selection={cadencier.tri_selection}
                  editMode={editMode}
                  filtreTemperature={filtreTemperature}
                  // gestion des events concernant l'ajout des articles avec suggestions de quantité au panier
                  lastSuggestionEvent={lastSuggestionEvent}
                  onSuggestionSuccess={handleSuggestionSuccess}
                  // pour tri des articles dans chaque section
                  sortType={sortType}
                  suggestionsState={suggestionsState}
                />
              </Box>
              <ActiveGoToPanier />
            </VStack>
          </Box>
        );
      }} />
  );
};

const ActiveGoToPanier = () => {
  const {t} = useTranslation('common');
  const capture = useCapture();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const {state: panier} = useHalState(usePanier());
  return <Box m='5'
    ml='auto'>
    {panier && 
      <Link 
        onClick={()=>{capture({name: 'panier:go', props: {depuis: 'cadencier'}});}}
        to={buildAssistanceRoute(routes.client.panier, {date_panier_actif: panier.data.livraison_tolede.date_de_livraison})}>
        <BlueButton texte={t('cadencier.cta_panier')}
          rightIcon={<ArrowForwardIcon/>}
          p='7'
          m='2'/>
      </Link>
    }
  </Box>;
};

const CadencierBody:React.FC<{
  tri_selection:TriSelection,
  editMode:boolean,
  filtreTemperature: TemperatureDeLivraison,
  lastSuggestionEvent?: State<ArticlesCadencierSuggestionsAjoutesEventDesc>,
  onSuggestionSuccess?: (event: State<ArticlesCadencierSuggestionsAjoutesEventDesc>) => void,
  sortType: SortType,
  suggestionsState?: State<SuggestionsStateDesc>,
}> = ({tri_selection, editMode, filtreTemperature, lastSuggestionEvent, sortType, suggestionsState}) => {
  const lignesHook = useHalStates(usePanier().followAll('lignes_de_panier'));

  const view = useContext(BreakPointContext);

  return tri_selection.map(({categorie_id, libelle_web, articles}) => {
    // 1. Filtrage des articles selon la température de livraison
    // Si filtreTemperature est 'les_deux': on garde tous les articles
    // Sinon: on ne garde que les articles ayant la température de livraison correspondante
    const filteredArticles = (filtreTemperature === 'les_deux'
      ? articles
      : articles.filter(({temperature_livraison}) =>
        temperature_livraison === filtreTemperature)) as CadencierArticle[];

    // 2. Ensuite tri des articles filtrés selon le critère choisi (alphabétique ou fréquence)
    // En prenant en compte le type de tri (sortType: 'alphabetic' ou 'frequency')
    const sortedArticles = TriArticlesCadencier(
      filteredArticles,
      sortType,
      suggestionsState
    );

    return (
      <CadencierSection
        id={categorie_id}
        key={categorie_id}
        title={libelle_web}
        // articles triés selon le critère choisi
        articles={sortedArticles}
        editMode={editMode}
        lignesDePanier={lignesHook.states ?? []}
        isMobile={!!view?.isMobile}
        lastSuggestionEvent={lastSuggestionEvent}
      />
    );
  });
};
