import {Box, Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {ListesContenu} from './components/ListesContenu';
import {ProduitsParSaison} from './components/ProduitsParSaison';

// Page principale des listes avec système d'onglets
export const MesListes: React.FC = () => {
  const {t} = useTranslation();

  return (
    <Box 
      bg='gray.bg'
      py={{base:'0', lg:'10'}}
      px={{base:'0', md: '2', xl: '20'}}
    >
      <Box 
        bg={{lg:'white'}}
        p={{base:'0', md:'4'}}
        maxW="1400px"
        mx="auto"
      >
        <Tabs colorScheme='blue'>
          <TabList borderBottomWidth="2px">
            <Tab 
              fontWeight="600"
              _selected={{
                color: 'blue.main',
                borderBottomWidth: '4px',
                borderColor: 'blue.main'
              }}
            >
              {t('liste.mes_listes')}
            </Tab>
            <Tab 
              fontWeight="600"
              _selected={{
                color: 'blue.main',
                borderBottomWidth: '4px',
                borderColor: 'blue.main'
              }}
            >
              {t('liste.produits_par_saison')}
            </Tab>
            {/* <Tab 
              fontWeight="600"
              isDisabled
              _disabled={{
                opacity: 0.4,
                cursor: 'not-allowed'
              }}
            >
              {t('liste.offre_commercial')}
            </Tab> */}
          </TabList>

          <TabPanels>
            <TabPanel px={0}>
              <ListesContenu />
            </TabPanel>
            <TabPanel px={0}>
              <ProduitsParSaison />
            </TabPanel>
            <TabPanel px={0}>
              {/* L'onglet pour l'offre commerciale sera implémenté plus tard */}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};