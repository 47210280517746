import {RefObject, useCallback, useEffect, useState} from 'react';

/**
 * Hook pour gérer le défilement horizontal d'un élément avec des contrôles de navigation
 * @param scrollRef Référence vers l'élément scrollable
 * @returns État du scroll et fonctions de contrôle
 */
export const useHorizontalScroll = (scrollRef: RefObject<HTMLElement>) => {
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

  // Vérifier les possibilités de scroll horizontal
  const checkScrollPossibilities = useCallback(() => {
    if (scrollRef.current) {
      const {scrollLeft, scrollWidth, clientWidth} = scrollRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 1); // -1 pour éviter les problèmes de précision
    }
  }, [scrollRef]);

  // Gestionnaire d'événement de défilement
  const handleScroll = useCallback(() => {
    checkScrollPossibilities();
  }, [checkScrollPossibilities]);

  // Défilement vers la gauche
  const scrollToStart = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({left: 0, behavior: 'smooth'});
    }
  }, [scrollRef]);

  // Défilement vers la droite
  const scrollToEnd = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollWidth,
        behavior: 'smooth'
      });
    }
  }, [scrollRef]);

  // Initialisation et nettoyage des vérifications de défilement
  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      // Vérification initiale
      checkScrollPossibilities();
      
      // Ajouter un gestionnaire d'événement de défilement
      scrollContainer.addEventListener('scroll', handleScroll);
      
      // Observer les changements de taille pour recalculer
      const resizeObserver = new ResizeObserver(checkScrollPossibilities);
      resizeObserver.observe(scrollContainer);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
        resizeObserver.disconnect();
      };
    }
    return undefined;
  }, [checkScrollPossibilities, handleScroll, scrollRef]);

  return {
    canScrollLeft,
    canScrollRight,
    scrollToStart,
    scrollToEnd
  };
};
