import {Icon} from '@chakra-ui/react';

export const EtoilesIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='#1A051D', width='20px', height='20px'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 20 20" 
      fill={color}
    >

      <path xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.65306 3.87097C8.09228 3.87097 8.48221 4.16713 8.62111 4.60621L10.1179 9.3381L14.6084 10.9154C15.0251 11.0618 15.3061 11.4727 15.3061 11.9355C15.3061 12.3983 15.0251 12.8092 14.6084 12.9556L10.1179 14.5329L8.62111 19.2648C8.48221 19.7038 8.09228 20 7.65306 20C7.21385 20 6.82391 19.7038 6.68502 19.2648L5.1882 14.5329L0.697727 12.9556C0.281051 12.8092 0 12.3983 0 11.9355C0 11.4727 0.281051 11.0618 0.697727 10.9154L5.1882 9.3381L6.68502 4.60621C6.82391 4.16713 7.21385 3.87097 7.65306 3.87097ZM7.65306 8.34653L6.96294 10.5282C6.86138 10.8493 6.62228 11.1012 6.31758 11.2083L4.24722 11.9355L6.31758 12.6627C6.62228 12.7697 6.86138 13.0217 6.96294 13.3428L7.65306 15.5244L8.34318 13.3428C8.44475 13.0217 8.68385 12.7697 8.98854 12.6627L11.0589 11.9355L8.98854 11.2083C8.68385 11.1012 8.44475 10.8493 8.34318 10.5282L7.65306 8.34653Z"
      />
      <path xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8163 0C16.0799 0 16.3138 0.177697 16.3972 0.441143L17.1932 2.9577L19.5814 3.79655C19.8314 3.88436 20 4.1309 20 4.4086C20 4.6863 19.8314 4.93284 19.5814 5.02066L17.1932 5.85951L16.3972 8.37606C16.3138 8.63951 16.0799 8.8172 15.8163 8.8172C15.5528 8.8172 15.3188 8.63951 15.2355 8.37606L14.4394 5.85951L12.0513 5.02066C11.8013 4.93284 11.6327 4.6863 11.6327 4.4086C11.6327 4.1309 11.8013 3.88436 12.0513 3.79655L14.4394 2.9577L15.2355 0.441143C15.3188 0.177697 15.5528 0 15.8163 0Z"
      />
    </Icon>
  );
};