import {useClient, useNomenclature, useRepresentants, useSocieteCommerciale} from '@/haligator-factory';
import {routes} from 'ff-common';
import {EmailIcon, PhoneIcon} from '@chakra-ui/icons';
import {Avatar, Box, Button, Divider, Grid, HStack, Link, Text, VStack} from '@chakra-ui/react';
import {HaliRenderOne, useHalCollection, useHalState} from '@synako/haligator';
import {Link as ReactRouterLink} from 'react-router-dom';
import {CheckLoaded} from '@/containers/CheckLoaded';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {CatalogueModale} from '@/domains/article/NomenclatureModale/CatalogueModale';
import NiceModal from '@ebay/nice-modal-react';
import {useTranslation} from 'react-i18next';
import {useCategories} from 'ff-common';
import {Flex} from '@chakra-ui/layout';

export const Footer: React.FC<{nomenclature_client?:boolean}> = ({nomenclature_client=true}) => {
  const {t} = useTranslation('common');
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const representantsHook = useHalCollection(useRepresentants());
  const representantCommercial = representantsHook.items?.find(state => state.data.tolede.en_tant_que == 'commercial');
  const societeCommercialeHook = useHalState(useSocieteCommerciale());
  const categories_client = useCategories();
  const nomenclatureHook = useHalState(useNomenclature());
  const categorieGlobale = nomenclatureHook.data?.web.categories.filter(categorie => !categorie.id_parent);

  const premierNiveauCategories = nomenclature_client ? categories_client.premierNiveauCategories : categorieGlobale;

  const handleNosCategoriesClick = () => {
    NiceModal.show(CatalogueModale);
  };

  const prenomNomCommmercial = `${representantCommercial?.data.tolede.individu.prenom ?? '-'} ${representantCommercial?.data.tolede.individu.nom ?? '-'}`;
  const email = representantCommercial?.data.tolede.individu.email;
  const telephone = representantCommercial?.data.tolede.individu.telephone;
  const emailTelephoneBlock = <EmailTelephone email={email}
    telephone={telephone}/>;

  return (
    <CheckLoaded hook={societeCommercialeHook}
      render={({data})=>{
        return (
          <>
            {/* Version desktop */}
            <Box display={{base:'none', md:'block'}}
              bg="black.ff"
              color="white"
              py={8}
              px={20}
              w='100%'
              zIndex='banner'>
              <HStack justify="space-between"
                align="start"
                mb='10px'>
                <VStack align="start"
                  spacing={4}
                  flex={1}>
                  <Text fontSize="md"
                    fontWeight="bold">
                    {t('footer.conseiller_ecoute')}
                  </Text>
                  <HStack spacing={4}>
                    <Avatar name={prenomNomCommmercial}
                      src='https://bit.ly/broken-link' />
                    <Text fontSize="sm">{prenomNomCommmercial}</Text>
                  </HStack>
                  {emailTelephoneBlock}
                </VStack>
                <VStack align="start"
                  spacing={2}
                  flex={2}>
                  <Text fontSize="sm"
                    fontWeight='700'
                    mb='8px'
                    cursor='pointer'
                    onClick={handleNosCategoriesClick}>{t('footer.nos_rayons')}</Text>
                  <Grid templateColumns="repeat(2, 1fr)"
                    gap={2}
                    gridColumnGap={6}>
                    {premierNiveauCategories?.map((categorie, index) => (
                      <ReactRouterLink key={index}
                        to={buildAssistanceRoute(routes.client.catalogue, {}, {cat_web: categorie.id})}
                        style={{textDecoration: 'none'}}>
                        <Text fontSize="xs"
                          cursor="pointer">
                          {categorie.libelle}
                        </Text>
                      </ReactRouterLink>                    
                    ))}
                  </Grid>
                </VStack>
                {data.liens_footer && <VStack align="start"
                  spacing={2}
                  flex={1}>
                  <Text fontSize="sm"
                    fontWeight='700'
                    mb='8px'>{t('footer.a_propos')}</Text>
                  {data.liens_footer.map(lien => (
                    <Link key={lien.label}
                      fontSize="xs"
                      href={lien.url}
                      isExternal>
                      {lien.label}
                    </Link>
                  ))}
                </VStack>}
                <VStack align="start"
                  spacing={2}
                  flex={1}>
                  <Text fontSize="sm"
                    fontWeight='700'
                    mb='8px'>{t('footer.contactez_nous')}</Text>
                  <Text fontSize="xs">{data.tolede.nom_commercial} - {data.adresse}</Text>
                  <Text fontSize="xs">{t('footer.tel')} <Text as='span'
                    textDecoration='underline'
                    textUnderlineOffset='2px'>{data.telephone}</Text></Text>
                  <Text fontSize="xs">{t('footer.e_mail')} <Text as='span'
                    textDecoration='underline'
                    textUnderlineOffset='2px'><Link href={`mailto:${data.email}`}
                      fontSize="xs">
                      {data.email}
                    </Link></Text></Text>
                </VStack>
              </HStack>
              <Divider my='8' />
              <HStack>
                <BoutonsCopyrightMentionLegales/>
              </HStack>
            </Box>
            {/* Version mobile */}
            <Box display={{base:'block', md:'none'}}
              bg="black.ff"
              color="white"
              py={8}
              px={20}
              w='100%'
              mb='60px'>
              <VStack justify="space-between"
                align="start"
                mb='10px'>
                <VStack align="start"
                  spacing={4}
                  flex={1}
                  mb='10px'>
                  <HStack spacing={4}>
                    <Text fontSize="sm"
                      fontWeight="bold">
                      {t('footer.votre_conseiller')}
                    </Text>
                    <Text fontSize="sm">{prenomNomCommmercial}</Text>
                  </HStack>
                  {emailTelephoneBlock}
                </VStack>
                <VStack align="start"
                  spacing={2}
                  flex={1}>
                  <Text fontSize="sm"
                    fontWeight='700'
                    cursor='pointer'
                    onClick={handleNosCategoriesClick}>
                    {t('footer.nos_rayons')}
                  </Text>
                </VStack>
                <VStack align="start"
                  spacing={2}
                  flex={1}>
                  {data.liens_footer?.map(lien => (
                    <Link key={lien.label}
                      fontSize="sm"
                      fontWeight='700'
                      href={lien.url}
                      isExternal>
                      {/* Pas de traduction, on laisse les sociétés commerciales définir leurs propres labels */}
                      {lien.label}
                    </Link>
                  ))}
                  <Text fontSize="sm"
                    fontWeight='700'>
                    {t('footer.contactez_nous')}
                  </Text>
                  <Text fontSize="xs">{data.tolede.nom_commercial} - {data.adresse}</Text>
                  <Text fontSize="xs">{t('footer.tel')} <Text as='span'
                    textDecoration='underline'
                    textUnderlineOffset='2px'>{data.telephone}</Text></Text>
                  <Text fontSize="xs">{t('footer.e_mail')} <Text as='span'
                    textDecoration='underline'
                    textUnderlineOffset='2px'><Link href="mailto:contact@teamouestdistralis.fr"
                      fontSize="xs">
                      {data.email}
                    </Link></Text></Text>
                </VStack>
              </VStack>
              <Divider />
              <VStack pt='30px'
                alignItems='start'>
                <BoutonsCopyrightMentionLegales/>
              </VStack>
            </Box>
          </>
        );
      }}
    />
  );
};

const BoutonsCopyrightMentionLegales:React.FC = ()=>{
  const {t} = useTranslation('common');
  const societeCommercialeNav = useClient().follow('societe_commerciale');
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const hideMobile = {display:{base:'none', md:'block'}};

  return <>
    <Text
      fontSize="2xs"
      color='gray.light'
      // eslint-disable-next-line i18next/no-literal-string
    >
      Copyright© France Frais 2024
    </Text>
    <Text {...hideMobile}>•</Text>
    <Link as={ReactRouterLink}
      to={buildAssistanceRoute(routes.client.mentions)}
      fontSize="2xs"
      color='gray.light'>
      {t('footer.mentions_legales')}
    </Link>
    <Text {...hideMobile}>•</Text>
    <Link fontSize="2xs"
      color='gray.light'
      href='https://www.francefrais.fr/nos-valeurs-demarche-rse'
      isExternal>
      {t('footer.engagements')}
    </Link>
    <Text {...hideMobile}>•</Text>
    <Link fontSize="2xs"
      color='gray.light'
      href='https://storage.googleapis.com/ffconnect-medias/cgu.pdf'
      isExternal>
      {t('footer.cgu')}
    </Link>
    <Text {...hideMobile}>•</Text>
    <Link fontSize="2xs"
      color='gray.light'
      href='https://storage.googleapis.com/ffconnect-medias/confidentialite.pdf'
      isExternal>
      {t('footer.politique_confidentialite')}
    </Link>
    <Text {...hideMobile}>•</Text>
    <Link fontSize="2xs"
      color='gray.light'
      href='https://storage.googleapis.com/ffconnect-medias/cookies.pdf'
      isExternal>
      {t('footer.gestion_cookies')}
    </Link>
    <Text {...hideMobile}>•</Text>
    <HaliRenderOne nav={societeCommercialeNav}
      render={({data}) => (
        <>
          {data.url_cgv && (
            <Link fontSize="2xs"
              color='gray.light'
              href={data.url_cgv}
              isExternal>
              {t('footer.cgv')}
            </Link>
          )}
        </>
      )} />
  </>;
};

const EmailTelephone:React.FC<{email: string | undefined, telephone: string | undefined}> = ({email, telephone}) => {
  const {t} = useTranslation('common');
  return(
    <Flex direction={{base:'column', lg:'row'}}
      gap="10px"
      w='100%'>
      <Button leftIcon={<EmailIcon />}
        as='a'
        href={email ? `mailto:${email}` : undefined}
        bg='gray.light'
        variant='solid'
        fontSize='xs'
        height={{base: '25px', md:'30px'}}
        isDisabled={!email}>
        {t('footer.mail')}
      </Button>
      <Button leftIcon={<PhoneIcon />}
        as='a'
        href={telephone ? `tel:${telephone}` : undefined}
        bg='gray.light'
        variant='solid'
        fontSize='xs'
        height={{base: '25px', md:'30px'}}
        isDisabled={!telephone}>
        {t('footer.telephone')}
      </Button>
    </Flex>
  );
};
